import React from "react";
import { Row, Col, Divider } from "antd";
import BrandColorsSelector from "./BrandColorsSelector";
import BrandTags from "./BrandTags";

const CreateBrandKit = ({ setAudience, setBrandTags, setColors }) => {
  return (
    <div className="brandKitContainer">
      <h2>BrandKit:</h2>
      <Row gutter={16}>
        <Col span={24}>
          <h3>Brand Colors:</h3>
          <BrandColorsSelector setColors={setColors} />
        </Col>
        <Col span={24}>
          <h3>Atributos: </h3>
          <small>Solo puedes Escoger 10</small>
          <BrandTags setBrandTags={setBrandTags} />
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={24}>
          <h3>Audiencia: </h3>
          <small>Solo puedes escoger 10</small>
          <BrandTags setBrandTags={setAudience} />
        </Col>
      </Row>
    </div>
  );
};

export default CreateBrandKit;
