import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { db } from "../../firebase.config";
import {
  Icon,
  Typography,
  Row,
  Col,
  Tag,
  Switch,
  Divider,
  message,
  Modal
} from "antd";
import KeepmePointsElementGallery from "./KeepmePointsElementGallery";
import UploadKeepmePointsElementImage from "./UploadKeepmePointsElementImage";
import DeleteKeepmePointsElementImage from "./DeleteKeepmePointsElementImage";
import EditKeepmePointsElementForm from "./EditKeepmePointsElementForm";
import DeleteKeepmePointsElement from "./DeleteKeepmePointsElement";
const { Title } = Typography;

const KeepmePointsElementProfile = ({ match }) => {
  const [currentElement, setCurrentElement] = useState({});
  const [loading, setLoading] = useState(true);
  const [isPublicLoading, setIsPublicLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleSwitchChange = async (checked, field) => {
    let setCurrentLoadingState = null;
    if (field === "isPublic")
      setCurrentLoadingState = value => setIsPublicLoading(value);
    setCurrentLoadingState(true);
    const elementRef = db.collection("pointsCatalog").doc(currentElement.id);
    try {
      await elementRef.update({ [field]: checked });
      setCurrentLoadingState(false);
    } catch (e) {
      message.error("No pudimos actualizar el estado del elemento");
      setCurrentLoadingState(false);
    }
  };

  useEffect(() => {
    const elementRef = db
      .collection("pointsCatalog")
      .doc(match.params.elementId);
    const unsubscribeToElement = elementRef.onSnapshot(doc => {
      setLoading(true);
      setCurrentElement({
        id: doc.id,
        ...doc.data()
      });
      setLoading(false);
    });
    return () => unsubscribeToElement();
  }, [match.params.elementId]);

  return (
    <div className='pageContainer'>
      {loading ? (
        <div style={{ display: "flex" }}>
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        </div>
      ) : (
        <>
          <div className='keepmepointsProfileContainer'>
            <Row gutter={16}>
              <Col span={10}>
                <Icon
                  type='edit'
                  style={{ fontSize: 14 }}
                  onClick={() => setEditMode(true)}
                />
                <Title>{currentElement.name}</Title>
                <div>
                  Publicado:{" "}
                  <Switch
                    loading={isPublicLoading}
                    checked={currentElement.isPublic}
                    onChange={checked =>
                      handleSwitchChange(checked, "isPublic")
                    }
                  />
                </div>
                <Divider />
                <Row>
                  <Col span={12}>
                    <div>
                      <h2>Caracteristicas</h2>
                      <p>
                        <strong>Puntos: </strong>
                        {currentElement && currentElement.points}
                      </p>
                      <p>
                        <strong>Unidades disponibles: </strong>
                        {currentElement.units}
                      </p>
                      <strong>Categoria: </strong>{" "}
                      <span>
                        {<Tag color='#222'>{currentElement.category}</Tag>}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={12}>
                    <h2>Descripcion</h2>
                    <p>{currentElement.description}</p>
                  </Col>
                </Row>
              </Col>
              <Col span={7}>
                <div>
                  <h2>Imagen Principal: </h2>
                  <div
                    className='elementPreviewImage'
                    style={{
                      backgroundImage: `url(${currentElement.mainImage ||
                        null})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center"
                    }}
                  >
                    {currentElement && !currentElement.mainImage && (
                      <Icon
                        type='picture'
                        style={{ margin: "auto", fontSize: 20 }}
                      />
                    )}
                  </div>
                  <small>Recomenacion, imagenes cuadradas</small>
                  <div style={{ display: "flex" }}>
                    <div style={{ margin: 5 }}>
                      <UploadKeepmePointsElementImage
                        title='Subir imagen'
                        id={currentElement.id}
                        fileName='mainImage'
                      />
                    </div>
                    <div style={{ margin: 5 }}>
                      <DeleteKeepmePointsElementImage
                        id={currentElement.id}
                        path={`pointsCatalog/${currentElement.id}/mainImage`}
                        disabled={!currentElement.mainImage}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={7}>
                <KeepmePointsElementGallery element={currentElement} />
              </Col>
            </Row>
          </div>
          <DeleteKeepmePointsElement element={currentElement} />
        </>
      )}
      <Modal
        title='Editar informacion general del elemento de Keepme Points'
        visible={editMode}
        onCancel={() => setEditMode(false)}
        footer={null}
        centered
        destroyOnClose
        width='800px'
      >
        <div>
          <EditKeepmePointsElementForm
            element={currentElement}
            setEditMode={setEditMode}
          />
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(KeepmePointsElementProfile);
