import React, { useState } from 'react';
import { Icon, Tag, message } from 'antd';
import { useStoreActions } from 'easy-peasy';
import formatTimestamp from '../../utils/formatTimestamp';
import { db, fieldValue } from '../../firebase.config';

const CategoryCard = ({ category }) => {
  const [loading, setLoading] = useState(false);
  const { fetchCategories } = useStoreActions(actions => actions.complements);

  const handlecategoryDelete = async () => {
    setLoading(true);
    try {
      const categoryRef = db.collection('categories').doc('complements');
      await categoryRef.update({
        categories: fieldValue.arrayRemove({
          id: category.id,
          name: category.name,
          createdAt: category.createdAt
        }),
        names: fieldValue.arrayRemove(category.name),
        [category.id]: fieldValue.delete(),
        [`public-${category.id}`]: fieldValue.delete()
      });
      fetchCategories();
    } catch (e) {
      console.log(e.message);
      message.error('No pudimos eliminar la categoria');
      setLoading(false);
    }
  };
  return (
    <div className='categoryCard'>
      <div style={{ display: 'flex' }}>
        {(!category.count || category.count.length === 0) && (
          <Icon
            type={loading ? 'loading' : 'close'}
            shape='circle'
            style={{ fontSize: 12 }}
            onClick={() => handlecategoryDelete()}
          />
        )}

        {/* <Icon
          type='edit'
          shape='circle'
          style={{ fontSize: 14, marginLeft: "auto" }}
          // onClick={() => setEditMode(true)}
        /> */}
      </div>

      <h2>{category.name}</h2>
      <div>
        <Tag color='#222'>{category.count}</Tag>
      </div>
      <small>creada en: {formatTimestamp(category.createdAt)}</small>
    </div>
  );
};

export default CategoryCard;
