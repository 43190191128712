import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { db } from "../../firebase.config";
import {
  Icon,
  Typography,
  Row,
  Col,
  Tag,
  Switch,
  Divider,
  message,
  Modal,
  Button
} from "antd";
import InventoryElementGallery from "./InventoryElementGallery";
import UploadInventoryElementImage from "./UploadInventoryElementImage";
import DeleteInventoryElementImage from "./DeleteInventoryElementImage";
import EditInventoryElementForm from "./EditInventoryElementForm";
import DeleteInventoryElement from "./DeleteInventoryElement";
import InventoryElementLogs from "./InventoryElementLogs";
import formatTimestamp from "../../utils/formatTimestamp";
const { Title } = Typography;

const InventoryElementProfile = ({ match }) => {
  const [currentElement, setCurrentElement] = useState({});
  const [loading, setLoading] = useState(true);
  const [isPublicLoading, setIsPublicLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleSwitchChange = async checked => {
    setIsPublicLoading(true);
    const elementRef = db
      .collection("brands")
      .doc(match.params.brandId)
      .collection("inventory")
      .doc(match.params.elementId);
    try {
      await elementRef.update({ isPublic: checked });
      setIsPublicLoading(false);
    } catch (e) {
      message.error("No pudimos actualizar el estado del elemento");
      setIsPublicLoading(false);
    }
  };

  useEffect(() => {
    if (match.params.brandId && match.params.elementId) {
      const elementRef = db
        .collection("brands")
        .doc(match.params.brandId)
        .collection("inventory")
        .doc(match.params.elementId);
      const unsubscribeToElement = elementRef.onSnapshot(doc => {
        setLoading(true);
        setCurrentElement({
          id: doc.id,
          ...doc.data()
        });
        setLoading(false);
      });
      return () => unsubscribeToElement();
    }
  }, [match.params]);

  return (
    <div className="pageContainer">
      {loading ? (
        <div style={{ display: "flex" }}>
          <Icon style={{ margin: "auto", fontSize: 80 }} type="loading" />
        </div>
      ) : (
        <>
          <div className="complmenetProfileContainer">
            <Row gutter={16}>
              <Col span={10}>
                <Icon
                  type="edit"
                  style={{ fontSize: 14 }}
                  onClick={() => setEditMode(true)}
                />
                <Title>{currentElement.name}</Title>
                <div>
                  Publicado:{" "}
                  <Switch
                    loading={isPublicLoading}
                    checked={currentElement.isPublic}
                    onChange={checked =>
                      handleSwitchChange(checked, "isPublic")
                    }
                  />
                </div>
                <div>
                  <small>
                    Creado en: {formatTimestamp(currentElement.createdAt)}
                  </small>
                </div>
                <Divider />
                <Row>
                  <Col span={12}>
                    <div>
                      <h2>Caracteristicas</h2>
                      <span>
                        <strong>Marca: </strong>
                      </span>
                      <Tag color={currentElement.brandColor}>
                        {currentElement.brandName}
                      </Tag>
                      <p>
                        <strong>Unidades disponibles: </strong>
                        {currentElement.units} {currentElement.unitsType}
                      </p>
                      <p>
                        {currentElement.hasMinUnits && (
                          <>
                            <strong>Unidades minimas: </strong>
                            {currentElement.minUnits} {currentElement.unitsType}
                          </>
                        )}
                      </p>
                      <p>
                        {currentElement.hasExpirationDate && (
                          <>
                            <strong>Fecha de vencimiento: </strong>
                            {formatTimestamp(currentElement.expirationDate)}
                          </>
                        )}
                      </p>
                      <div>
                        <span>
                          <strong>Categoria: </strong>
                          <Tag color="#222">{currentElement.category}</Tag>
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      {currentElement.isPhysical && (
                        <>
                          <h2>Medidas</h2>
                          <p>
                            {currentElement.height !== 0 && (
                              <>
                                <strong>Alto: </strong>
                                {currentElement.height}
                                {currentElement.measureUnits}
                              </>
                            )}
                          </p>
                          <p>
                            {currentElement.width !== 0 && (
                              <>
                                <strong>Ancho: </strong>
                                {currentElement.width}
                                {currentElement.measureUnits}
                              </>
                            )}
                          </p>
                          <p>
                            {currentElement.long !== 0 && (
                              <>
                                <strong>Largo: </strong>
                                {currentElement.long}
                                {currentElement.measureUnits}
                              </>
                            )}
                          </p>

                          <p>
                            {currentElement.diameter !== 0 && (
                              <>
                                <strong>Diametro: </strong>
                                {currentElement.diameter}
                                {currentElement.measureUnits}
                              </>
                            )}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={12}>
                    <h2>Observaciones:</h2>
                    <p>{currentElement.observations}</p>
                  </Col>
                </Row>
              </Col>
              <Col span={7}>
                <div>
                  <h2>Imagen Principal: </h2>
                  <div
                    className="elementPreviewImage"
                    style={{
                      backgroundImage: `url(${currentElement.mainImage ||
                        null})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center"
                    }}
                  >
                    {currentElement && !currentElement.mainImage && (
                      <Icon
                        type="picture"
                        style={{ margin: "auto", fontSize: 20 }}
                      />
                    )}
                  </div>
                  <small>Recomenacion, imagenes de 300x300</small>
                  <div style={{ display: "flex" }}>
                    <div style={{ margin: 5 }}>
                      <UploadInventoryElementImage
                        title="Subir imagen"
                        element={currentElement}
                        fileName="mainImage"
                      />
                    </div>
                    <div style={{ margin: 5 }}>
                      <DeleteInventoryElementImage
                        element={currentElement}
                        path={`inventory/${currentElement.brandId}/${currentElement.id}/mainImage`}
                        disabled={!currentElement.mainImage}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={7}>
                <InventoryElementGallery element={currentElement} />
              </Col>
            </Row>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Link to={`/inventario/${currentElement.brandId}`}>
                <Button type="primary"> Regresar al inventario</Button>
              </Link>
            </div>
            <div>
              <DeleteInventoryElement element={currentElement} />
            </div>
          </div>
          <InventoryElementLogs element={currentElement} />
        </>
      )}
      <Modal
        title={`Editar informacion general del elemento ${currentElement.name}`}
        visible={editMode}
        onCancel={() => setEditMode(false)}
        footer={null}
        centered
        destroyOnClose
        width={800}
      >
        <div>
          <EditInventoryElementForm
            element={currentElement}
            setEditMode={setEditMode}
          />
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(InventoryElementProfile);
