import React, { useState } from "react";
import { Icon, Button, Tooltip } from "antd";
import { useStoreActions } from "easy-peasy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CreateUserSuccess = ({ email, password }) => {
  const [copied, setCopied] = useState(false);
  const setCreateUserState = useStoreActions(
    actions => actions.users.setCreateUserState
  );

  const resetUserState = () => {
    setCreateUserState({
      loading: false,
      error: "",
      success: false,
      newUser: {
        email: "",
        password: ""
      }
    });
  };
  return (
    <div style={{ display: "flex" }}>
      <div style={{ margin: "auto", textAlign: "center" }}>
        <Icon
          style={{ fontSize: 60 }}
          theme="twoTone"
          twoToneColor="#52c41a"
          type="smile"
        />
        <h3 style={{ marginTop: 10 }}>
          ¡Bien hecho! Se ha creado el usuario <strong>{email}</strong>
        </h3>
        <p>Copia y guarda muy bien la siguiente contraseña temporal:</p>
        <p>
          <Tooltip title={copied ? "¡Copiado!" : "Copiar"} placement="left">
            <CopyToClipboard onCopy={() => setCopied(true)} text={password}>
              <strong style={{ cursor: "pointer" }}>{password}</strong>
            </CopyToClipboard>
          </Tooltip>
        </p>

        <Button
          disabled={!copied}
          type="primary"
          onClick={() => resetUserState()}
        >
          {copied ? (
            "crear otro usuario"
          ) : (
            <small>copia la contraseña para crear otro usuario</small>
          )}
        </Button>
      </div>
    </div>
  );
};

export default CreateUserSuccess;
