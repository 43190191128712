import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { db, fieldValue } from '../../firebase.config';
import {
  Icon,
  Typography,
  Row,
  Col,
  Tag,
  Switch,
  Divider,
  message,
  Modal,
  Button
} from 'antd';
import ComplementGallery from './ComplementGallery';
import UploadComplementImage from './UploadComplementImage';
import DeleteComplementImage from './DeleteComplementImage';
import EditComplementForm from './EditComplementForm';
import './complements.css';
import DeleteComplement from './DeleteComplement';

const { Title } = Typography;

const ComplementProfile = ({ match }) => {
  const [currentComplement, setCurrentComplement] = useState({});
  const [loading, setLoading] = useState(true);
  const [isPublicLoading, setIsPublicLoading] = useState(false);
  const [isNewLoading, setIsNewLoading] = useState(false);
  const [isFeaturedLoading, setIsFeaturedLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleSwitchChange = async (checked, field) => {
    let setCurrentLoadingState = null;
    if (field === 'isPublic')
      setCurrentLoadingState = value => setIsPublicLoading(value);
    if (field === 'isNew')
      setCurrentLoadingState = value => setIsNewLoading(value);
    if (field === 'isFeatured')
      setCurrentLoadingState = value => setIsFeaturedLoading(value);

    setCurrentLoadingState(true);
    const complementRef = db
      .collection('complements')
      .doc(currentComplement.id);

    try {
      if (field === 'isPublic') {
        const batch = db.batch();
        const categoryRef = db.collection('categories').doc('complements');

        if (checked) {
          batch.update(categoryRef, {
            [`public-${currentComplement.categoryId}`]: fieldValue.increment(1)
          });
        } else {
          batch.update(categoryRef, {
            [`public-${currentComplement.categoryId}`]: fieldValue.increment(-1)
          });
        }
        batch.update(complementRef, { [field]: checked });
        await batch.commit();
      } else {
        await complementRef.update({ [field]: checked });
      }
      setCurrentLoadingState(false);
    } catch (e) {
      message.error('No pudimos actualizar el estado del elemento');
      setCurrentLoadingState(false);
    }
  };

  useEffect(() => {
    const complementRef = db
      .collection('complements')
      .doc(match.params.elementId);
    const unsubscribeToComplement = complementRef.onSnapshot(doc => {
      setLoading(true);
      setCurrentComplement({
        id: doc.id,
        ...doc.data()
      });
      setLoading(false);
    });
    return () => unsubscribeToComplement();
  }, [match.params.elementId]);

  return (
    <div className='pageContainer'>
      {loading ? (
        <div style={{ display: 'flex' }}>
          <Icon style={{ margin: 'auto', fontSize: 80 }} type='loading' />
        </div>
      ) : (
        <>
          <div className='complmenetProfileContainer'>
            <Row gutter={16}>
              <Col span={10}>
                <Icon
                  type='edit'
                  style={{ fontSize: 14 }}
                  onClick={() => setEditMode(true)}
                />
                <Title>{currentComplement.name}</Title>
                <div>
                  Publicado:{' '}
                  <Switch
                    loading={isPublicLoading}
                    checked={currentComplement.isPublic}
                    onChange={checked =>
                      handleSwitchChange(checked, 'isPublic')
                    }
                  />{' '}
                  Es nuevo:{' '}
                  <Switch
                    loading={isNewLoading}
                    checked={currentComplement.isNew}
                    onChange={checked => handleSwitchChange(checked, 'isNew')}
                  />{' '}
                  Es destacado:{' '}
                  <Switch
                    loading={isFeaturedLoading}
                    checked={currentComplement.isFeatured}
                    onChange={checked =>
                      handleSwitchChange(checked, 'isFeatured')
                    }
                  />
                </div>
                <Divider />
                <Row>
                  <Col span={12}>
                    <div>
                      <h2>Caracteristicas</h2>
                      <p>
                        <strong>Precio: </strong>
                        {`$ ${currentComplement &&
                          currentComplement.price}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ','
                        )}
                      </p>
                      <p>
                        <strong>Unidades disponibles: </strong>
                        {currentComplement.units}
                      </p>
                      <strong>Categoria: </strong>{' '}
                      <span>
                        {<Tag color='#222'>{currentComplement.category}</Tag>}
                      </span>
                      <p>
                        <strong>Referencia: </strong>
                        {currentComplement.reference}
                      </p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      {currentComplement.isPhysical && (
                        <>
                          <h2>Medidas</h2>
                          <p>
                            {currentComplement.height !== 0 && (
                              <>
                                <strong>Alto: </strong>
                                {currentComplement.height}
                                {currentComplement.measureUnits}
                              </>
                            )}
                          </p>
                          <p>
                            {currentComplement.width !== 0 && (
                              <>
                                <strong>Ancho: </strong>
                                {currentComplement.width}
                                {currentComplement.measureUnits}
                              </>
                            )}
                          </p>
                          <p>
                            {currentComplement.long !== 0 && (
                              <>
                                <strong>Largo: </strong>
                                {currentComplement.long}
                                {currentComplement.measureUnits}
                              </>
                            )}
                          </p>

                          <p>
                            {currentComplement.diameter !== 0 && (
                              <>
                                <strong>Diametro: </strong>
                                {currentComplement.diameter}
                                {currentComplement.measureUnits}
                              </>
                            )}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={12}>
                    <h2>Descripcion</h2>
                    <p>{currentComplement.description}</p>
                  </Col>
                  <Col span={12}>
                    <h2>Recomendado para: </h2>
                    {currentComplement.forType &&
                      currentComplement.forType.map(type => (
                        <Tag key={type}>{type}</Tag>
                      ))}
                  </Col>
                </Row>
              </Col>
              <Col span={7}>
                <div>
                  <h2>Imagen Principal: </h2>
                  <div
                    className='elementPreviewImage'
                    style={{
                      backgroundImage: `url(${currentComplement.mainImage ||
                        null})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center'
                    }}>
                    {currentComplement && !currentComplement.mainImage && (
                      <Icon
                        type='picture'
                        style={{ margin: 'auto', fontSize: 20 }}
                      />
                    )}
                  </div>
                  <small>Recomenacion, imagenes cuadradas</small>
                  <div style={{ display: 'flex' }}>
                    <div style={{ margin: 5 }}>
                      <UploadComplementImage
                        title='Subir imagen'
                        id={currentComplement.id}
                        fileName='mainImage'
                      />
                    </div>
                    <div style={{ margin: 5 }}>
                      <DeleteComplementImage
                        id={currentComplement.id}
                        path={`complements/${currentComplement.id}/mainImage`}
                        disabled={!currentComplement.mainImage}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={7}>
                <ComplementGallery complement={currentComplement} />
              </Col>
            </Row>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Link to={`/complementos/elementos`}>
                <Button type='primary'> Regresar a complementos</Button>
              </Link>
            </div>
            <div>
              <DeleteComplement complement={currentComplement} />
            </div>
          </div>
        </>
      )}
      <Modal
        title='Editar informacion general el elemento Plus'
        visible={editMode}
        onCancel={() => setEditMode(false)}
        footer={null}
        centered
        destroyOnClose
        width='90%'>
        <div>
          <EditComplementForm
            complement={currentComplement}
            setEditMode={setEditMode}
          />
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(ComplementProfile);
