import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  Typography,
  message
} from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import { db, storage } from "../../firebase.config";
import { withRouter } from "react-router-dom";
import UploadSerieImage from "./UploadSerieImage";

const { Title } = Typography;
const { Option } = Select;
const CreateSerie = ({ history }) => {
  const [name, setName] = useState("Nombre de la serie");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [fileList, setFileList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [progressVisible, setProgressVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { fetchBrandsList } = useStoreActions(actions => actions.brands);
  const { loadingBrands, brands } = useStoreState(state => state.brands);

  const handleCreateSerie = async e => {
    e.preventDefault();
    if (!name) return message.error("El nombre es obligatorio");
    if (!selectedBrand) return message.error("La marca es obligatoria");
    if (fileList.length === 0)
      return message.error("La imagen principal es obligatoria");

    setLoading(true);
    const brand = brands.filter(brand => brand.name === selectedBrand)[0];

    const serieRef = db
      .collection("brands")
      .doc(brand.id)
      .collection("series");
    try {
      const serieDoc = await serieRef.add({
        name,
        brandId: brand.id,
        brandName: brand.name,
        brandColor: brand.mainColor,
        elementsCount: 0,
        createdAt: Date.now(),
        mainImage: ""
      });
      try {
        await handleUpload(fileList[0], serieDoc.id, brand.id);
      } catch (e) {
        console.log(e.message);
        setLoading(false);
        return message.error(
          "No pudimos cargar la imagen, pero se creo la serie"
        );
      }
    } catch (e) {
      console.log(e.message);
      setLoading(false);
      return message.error("No pudimos agregar la serie");
    }
    console.log("Nombre", name);
    console.log("Marca", selectedBrand);
    console.log("File list", fileList);
  };

  useEffect(() => {
    fetchBrandsList();
  }, [fetchBrandsList]);

  const handleUpload = async (file, id, brandId) => {
    setProgressVisible(true);
    const storageRef = storage.ref();
    const fileRef = storageRef.child(`series/${id}/mainImage`);
    const uploadTask = fileRef.put(file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(Math.trunc(progress));
        console.log("Upload is " + progress + "% done");
      },
      error => {
        message.error(error, 3);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(async downloadUrl => {
          if (downloadUrl) {
            const elementRef = db
              .collection("brands")
              .doc(brandId)
              .collection("series")
              .doc(id);
            await elementRef.update({ mainImage: downloadUrl });
          }
          history.push("/series");
        });
      }
    );
  };
  return (
    <div className='pageContainer'>
      <h1>Crear Serie</h1>
      <div>
        <Form onSubmit={handleCreateSerie}>
          <Row gutter={16}>
            <Col span={8}>
              <Title>{name}</Title>
              <Form.Item label='Escoge un nombre para la serie'>
                <Input
                  onChange={e => setName(e.target.value)}
                  placeholder='Nombre de la serie'
                />
              </Form.Item>
              <Form.Item label='Marca *'>
                <Select
                  showArrow
                  showSearch
                  disabled={brands.length === 0}
                  loading={loadingBrands}
                  placeholder='Escoge la marca'
                  optionFilterProp='children'
                  onChange={value => setSelectedBrand(value)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {brands.map(brand => (
                    <Option key={brand.id} value={brand.name}>
                      {brand.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <UploadSerieImage
                title='Escoger imagen'
                fileList={fileList}
                setFileList={setFileList}
                uploadProgress={uploadProgress}
                progressVisible={progressVisible}
              />
            </Col>
          </Row>
          <Form.Item>
            <Button htmlType='submit' type='primary' loading={loading}>
              Crear serie y agregar imagen
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default withRouter(CreateSerie);
