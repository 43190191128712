import React, { useEffect, useState } from "react";
import {
  Table,
  Avatar,
  Icon,
  Divider,
  InputNumber,
  Button,
  message,
  Popconfirm
} from "antd";
import { db, fieldValue } from "../../firebase.config";
const formatPrice = value => {
  return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const OutputProfileElements = ({ output }) => {
  const [items, setItems] = useState([]);
  const [editItemId, setEditItemId] = useState("");
  const [currentItemPrice, setCurrentItemPrice] = useState(0);
  const [currentItemSelectedUnits, setCurrentItemSelectedUnits] = useState(0);
  const [loadingElement, setLoadingElement] = useState("");

  const editMode = (record, mode) => {
    if (mode) {
      setEditItemId(record.id);
      setCurrentItemPrice(record.selectedPrice);
      setCurrentItemSelectedUnits(record.selectedUnits);
    } else {
      setEditItemId("");
      setCurrentItemPrice(0);
      setCurrentItemSelectedUnits(0);
    }
  };

  const removeElement = async record => {
    setLoadingElement(record.id);
    try {
      let itemRef;
      let item;
      let outputItem;
      let itemsName;
      let outputItemsName;
      if (record.storeType === "elements") {
        itemRef = db
          .collection("brands")
          .doc(record.brandId)
          .collection("series")
          .doc(record.serieId)
          .collection("elements")
          .doc(record.id);
        const itemIndex = output.elements.findIndex(
          element => element.id === record.id
        );
        const outputItemIndex = output.outputElements.findIndex(
          element => element.id === record.id
        );
        item = output.elements[itemIndex];
        outputItem = output.outputElements[outputItemIndex];
        itemsName = "elements";
        outputItemsName = "outputElements";
      }
      if (record.storeType === "inventory") {
        itemRef = db
          .collection("brands")
          .doc(record.brandId)
          .collection("inventory")
          .doc(record.id);
        const itemIndex = output.inventoryElements.findIndex(
          element => element.id === record.id
        );
        const outputItemIndex = output.outputInventoryElements.findIndex(
          element => element.id === record.id
        );
        item = output.inventoryElements[itemIndex];
        outputItem = output.outputInventoryElements[outputItemIndex];
        itemsName = "inventory";
        outputItemsName = "outputInventoryElements";
      }
      if (record.storeType === "complements") {
        itemRef = db.collection("complements").doc(record.id);

        const itemIndex = output.complements.findIndex(
          complement => complement.id === record.id
        );
        const outputItemIndex = output.outputComplements.findIndex(
          complement => complement.id === record.id
        );
        item = output.complements[itemIndex];
        outputItem = output.outputComplements[outputItemIndex];
        itemsName = "complements";
        outputItemsName = "outputComplements";
      }

      const outputRef = db
        .collection("brands")
        .doc(output.brandId)
        .collection("outputs")
        .doc(output.id);

      const batch = db.batch();

      batch.update(outputRef, {
        [itemsName]: fieldValue.arrayRemove(item),
        [outputItemsName]: fieldValue.arrayRemove(outputItem)
      });

      batch.update(itemRef, {
        reservedDates: fieldValue.arrayRemove({
          finishDate: output.finishDate,
          outputId: output.id,
          selectedUnits: record.selectedUnits,
          startDate: output.startDate
        })
      });

      await batch.commit();
    } catch (e) {
      message.error("No pudimos quitar el elemento de la salida");
      console.log(e.message);
    }
  };

  const saveElement = async record => {
    setLoadingElement(record.id);
    let itemRef;
    if (record.storeType === "elements") {
      itemRef = db
        .collection("brands")
        .doc(record.brandId)
        .collection("series")
        .doc(record.serieId)
        .collection("elements")
        .doc(record.id);
    }
    if (record.storeType === "inventory") {
      itemRef = db
        .collection("brands")
        .doc(record.brandId)
        .collection("inventory")
        .doc(record.id);
    }
    if (record.storeType === "complements") {
      itemRef = db.collection("complements").doc(record.id);
    }
    const outputRef = db
      .collection("brands")
      .doc(output.brandId)
      .collection("outputs")
      .doc(output.id);
    try {
      await db.runTransaction(async transaction => {
        const itemDoc = await transaction.get(itemRef);
        if (!itemDoc.exists) {
          let errorMessage = {
            code: "item-not-exist",
            message: "El elemento no existe"
          };
          throw errorMessage;
        }
        const itemData = itemDoc.data();

        if (currentItemSelectedUnits > itemData.units) {
          let errorMessage = {
            code: "item-max-units-reached",
            message: `No puedes agregar mas de las unidades totales (${itemData.units})`
          };
          throw errorMessage;
        }
        let reservedUnits = 0;
        if (record.storeType === "inventory" && itemData.reservedDates) {
          itemData.reservedDates.forEach(reservation => {
            reservedUnits += reservation.selectedUnits;
          });
        } else if (itemData.reservedDates) {
          itemData.reservedDates.forEach(reservation => {
            if (
              !(
                reservation.startDate >= output.finishDate &&
                reservation.finishDate <= output.startDate
              )
            ) {
              reservedUnits += reservation.selectedUnits;
            }
          });
        }
        const availableUnits =
          itemData.units - reservedUnits + record.selectedUnits;
        console.log(record.selectedUnits);

        if (availableUnits === 0) {
          if (currentItemSelectedUnits > record.selectedUnits) {
            let errorMessage = {
              code: "item-max-units-reached",
              message: `No puedes agregar mas de las unidades disponibles para esa fecha: (${availableUnits})`
            };
            throw errorMessage;
          }
        } else {
          if (currentItemSelectedUnits > availableUnits) {
            let errorMessage = {
              code: "item-max-units-reached",
              message: `No puedes agregar mas de las unidades disponibles para esa fecha: (${availableUnits})`
            };
            throw errorMessage;
          }
        }

        if (record.storeType === "elements") {
          let newItems = [];
          output.elements.forEach(element => {
            if (element.id === record.id) {
              newItems.push({
                ...element,
                selectedUnits: currentItemSelectedUnits
              });
            } else {
              newItems.push(element);
            }
          });
          transaction.update(outputRef, { elements: newItems });
        }
        if (record.storeType === "inventory") {
          let newItems = [];
          output.inventoryElements.forEach(element => {
            if (element.id === record.id) {
              newItems.push({
                ...element,
                selectedUnits: currentItemSelectedUnits
              });
            } else {
              newItems.push(element);
            }
          });
          transaction.update(outputRef, { inventoryElements: newItems });
        }
        if (record.storeType === "complements") {
          let newItems = [];
          let newOutputItems = [];
          output.complements.forEach(complement => {
            if (complement.id === record.id) {
              newItems.push({
                ...complement,
                selectedUnits: currentItemSelectedUnits
              });
            } else {
              newItems.push(complement);
            }
          });
          output.outputComplements.forEach(complement => {
            if (complement.id === record.id) {
              newOutputItems.push({ ...complement, price: currentItemPrice });
            } else {
              newOutputItems.push(complement);
            }
          });
          transaction.update(outputRef, {
            complements: newItems,
            outputComplements: newOutputItems
          });
        }

        const newReservedDates = itemData.reservedDates.map(date => {
          if (date.outputId === output.id) {
            return { ...date, selectedUnits: currentItemSelectedUnits };
          } else {
            return date;
          }
        });
        transaction.update(itemRef, { reservedDates: newReservedDates });
      });
    } catch (e) {
      message.error(e.message || "No pudimos actualzar el elemento");
      console.log(e.message);
    }
    setLoadingElement("");
    editMode(record, false);
  };

  const columns = [
    {
      title: "Imagen",
      dataIndex: "mainImage",
      key: "Imagen"
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "Nombre"
    },
    {
      title: "Descripcion",
      dataIndex: "description",
      key: "Descripcion"
    },
    {
      title: "Precio",
      dataIndex: "selectedPrice",
      key: "Precio",
      render: (text, record) =>
        editItemId === record.id ? (
          <InputNumber
            value={currentItemPrice}
            onChange={value => setCurrentItemPrice(value)}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value => value.replace(/\$\s?|(,*)/g, "")}
            placeholder='$$$$'
            style={{ width: 120 }}
            min={0}
          />
        ) : (
          formatPrice(record.selectedPrice)
        )
    },
    {
      title: "Cantidad elegida",
      dataIndex: "selectedUnits",
      key: "Cantidad",
      render: (text, record) =>
        editItemId === record.id ? (
          <InputNumber
            value={currentItemSelectedUnits}
            onChange={value => setCurrentItemSelectedUnits(value)}
            placeholder='Cantidad'
            style={{ width: 100 }}
            min={0}
          />
        ) : (
          record.selectedUnits
        )
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "Total"
    },
    {
      title: "Tienda",
      dataIndex: "store",
      key: "Tienda"
    },
    {
      title: "Modificar",
      dataIndex: "approved",
      key: "Aprobado",
      render: (text, record) =>
        editItemId === record.id ? (
          <>
            {loadingElement === record.id && (
              <>
                <Icon type='loading' />
                <Divider type='vertical' />
              </>
            )}

            <Icon
              type='check'
              onClick={
                loadingElement !== record.id
                  ? () => saveElement(record)
                  : () => {}
              }
            />
            <Divider type='vertical' />
            <Popconfirm
              title='¿Estas seguro de borrar este elemento？'
              onConfirm={() => removeElement(record)}
              okText='Si'
              cancelText='No'
            >
              <Icon type='close-circle' />
            </Popconfirm>
            <Divider type='vertical' />
            <Button
              style={{ padding: 0 }}
              onClick={
                loadingElement !== record.id
                  ? e => editMode(record, false)
                  : () => {}
              }
              type='link'
            >
              <small>Cancelar</small>
            </Button>
          </>
        ) : (
          <Icon type='edit' onClick={() => editMode(record, true)} />
        )
    }
  ];

  if (output.outputState === "finish" || output.outputState === "cancel")
    columns[columns.length - 1] = {};

  useEffect(() => {
    let newItems = [];
    if (output.outputElements) {
      output.outputElements.forEach(element => {
        const elementIndex = output.elements.findIndex(
          ele => ele.id === element.id
        );
        newItems.push({
          key: element.id,
          id: element.id,
          name: element.name,
          mainImage: (
            <Avatar shape='square' size='large' src={element.mainImage} />
          ),
          description: element.observations,
          selectedUnits: output.elements[elementIndex].selectedUnits,
          selectedPrice: 0,
          total: formatPrice(0),
          store: element.serieName,
          storeType: "elements",
          serieId: element.serieId,
          brandId: element.brandId
        });
      });
    }
    if (output.outputInventoryElements) {
      output.outputInventoryElements.forEach(element => {
        const elementIndex = output.inventoryElements.findIndex(
          ele => ele.id === element.id
        );
        newItems.push({
          key: element.id,
          id: element.id,
          name: element.name,
          mainImage: (
            <Avatar shape='square' size='large' src={element.mainImage} />
          ),
          description: element.observations,
          selectedUnits: output.inventoryElements[elementIndex].selectedUnits,
          selectedPrice: 0,
          total: formatPrice(0),
          store: `Inventario - ${element.brandName}`,
          storeType: "inventory",
          brandId: element.brandId
        });
      });
    }
    if (output.outputComplements) {
      output.outputComplements.forEach(complement => {
        const complementIndex = output.complements.findIndex(
          ele => ele.id === complement.id
        );
        newItems.push({
          key: complement.id,
          id: complement.id,
          name: complement.name,
          mainImage: (
            <Avatar shape='square' size='large' src={complement.mainImage} />
          ),
          description: complement.description,
          selectedUnits: output.complements[complementIndex].selectedUnits,
          selectedPrice: complement.price,
          total: formatPrice(
            complement.price * output.complements[complementIndex].selectedUnits
          ),
          store: "Complementos",
          storeType: "complements"
        });
      });
    }
    setItems(newItems);
  }, [output]);

  return (
    <div className='outputProfileContainer'>
      <h2>Elementos de la salida</h2>
      <Table
        pagination={{
          pageSize: 20
        }}
        size='small'
        columns={columns}
        dataSource={items}
        style={{ backgroundColor: "#fff", borderRadius: 10 }}
      />
    </div>
  );
};

export default OutputProfileElements;
