import React, { useState } from "react";
import { Col, Row, Icon, Form, Button, Input, message } from "antd";
import { db } from "../../firebase.config";

const BrandExecutive = ({ currentBrand }) => {
  const [nameEditMode, setNameEditMode] = useState(false);
  const [emailEditMode, setEmailEditMode] = useState();
  const [executiveName, setExecutiveName] = useState(
    currentBrand.executiveName
  );

  const [executiveEmail, setExecutiveEmail] = useState(
    currentBrand.executiveEmail
  );

  const updateBrandExecutive = async (e, field, value) => {
    e.preventDefault();
    if (!field) return message.error(`El campo es obligatorio`);
    try {
      const brandRef = db.collection("brands").doc(currentBrand.id);
      await brandRef.update({ [field]: value });
      message.success("Ejecutivo comercial actualizado");
      setNameEditMode(false);
      setEmailEditMode(false);
    } catch (e) {
      message.error("No pudimos actualizar el ejecutivo");
    }
  };

  return (
    <div>
      <h2>Ejecutivo comercial:</h2>
      <Row gutter={16}>
        <Col span={12}>
          <h3>
            Nombre:{" "}
            <Icon type='edit' onClick={() => setNameEditMode(!nameEditMode)} />
          </h3>
          {nameEditMode ? (
            <Form
              onSubmit={e =>
                updateBrandExecutive(e, "executiveName", executiveName)
              }
            >
              <Form.Item>
                <Input
                  onChange={e => setExecutiveName(e.target.value)}
                  placeholder='Nombre del ejecutivo comercial'
                  value={executiveName}
                />
                <Button type='primary' htmlType='submit'>
                  Guardar
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <p>
              {" "}
              {executiveName || "No hay nombre de ejecutivo comercial aun"}{" "}
            </p>
          )}
        </Col>

        <Col span={12}>
          <h3>
            Email:{" "}
            <Icon
              type='edit'
              onClick={() => setEmailEditMode(!emailEditMode)}
            />
          </h3>
          {emailEditMode ? (
            <Form
              onSubmit={e =>
                updateBrandExecutive(e, "executiveEmail", executiveEmail)
              }
            >
              <Form.Item>
                <Input
                  onChange={e => setExecutiveEmail(e.target.value)}
                  placeholder='Correo del ejecutivo comercial'
                  value={executiveEmail}
                />
                <Button type='primary' htmlType='submit'>
                  Guardar
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <p>
              {executiveEmail || "No hay correo de ejecutivo comercial aun"}{" "}
            </p>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BrandExecutive;
