import React, { useState, useEffect } from "react";
import { Typography, Icon, Tag, Modal, message } from "antd";
import { useStoreState, useStoreActions } from "easy-peasy";
import BrandProfileGeneralInfoForm from "./BrandProfileGeneralInfoForm";
import formatTimestamp from "../../utils/formatTimestamp";
import dbErrorMessages from "../../utils/dbErrorMessages";
const { Title } = Typography;

const BrandProfileGeneralInfo = ({ currentBrand }) => {
  const [editMode, setEditMode] = useState(false);
  const { error } = useStoreState(
    state => state.brands.updateBrandGeneralInfoState
  );
  const setUpdateBrandGeneralInfoState = useStoreActions(
    actions => actions.brands.setUpdateBrandGeneralInfoState
  );
  //if error show error message
  useEffect(() => {
    if (error) message.error(dbErrorMessages(error), 3);
    return () =>
      setUpdateBrandGeneralInfoState({
        loading: false,
        error: "",
        success: false
      });
  }, [error, setUpdateBrandGeneralInfoState]);

  return (
    <>
      <div
        style={{
          marginBottom: 10
        }}
      >
        <Icon
          type="edit"
          style={{ fontSize: 14 }}
          onClick={() => setEditMode(true)}
        />
        <Title>{currentBrand.name}</Title>
        <div>
          <small>Creada en: {formatTimestamp(currentBrand.createdAt)} </small>
        </div>
        <div>
          <small>
            Actualizada en: {formatTimestamp(currentBrand.updatedAt)}{" "}
          </small>
        </div>
        <div>
          Empresa: <Tag>{currentBrand.company}</Tag>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap"
        }}
      >
        <h2>Color Principal:</h2>
        <div>
          <div
            className="colorCircleShadow"
            style={{
              backgroundColor: currentBrand.mainColor
            }}
          />
          <p>{currentBrand.mainColor}</p>
        </div>
      </div>
      <span>Tipo de marca: </span>
      <Tag color="green">{currentBrand.brandType}</Tag>
      <p>
        <strong>Objetivo Expo: </strong> {currentBrand.brandTargetExpo}{" "}
      </p>
      <p>
        <strong>Objetivo Brandtour: </strong>
        {currentBrand.brandTargetBrandtour}{" "}
      </p>
      <p>
        <strong>Objetivo Corporate: </strong>{" "}
        {currentBrand.brandTargetCorporate}{" "}
      </p>
      <Modal
        title="Editar informacion general de la marca"
        width={800}
        visible={editMode}
        onCancel={() => setEditMode(false)}
        footer={null}
        destroyOnClose
        afterClose={() => {
          setUpdateBrandGeneralInfoState({
            loading: false,
            error: "",
            success: false
          });
        }}
      >
        <BrandProfileGeneralInfoForm
          brand={currentBrand}
          setEditMode={setEditMode}
        />
      </Modal>
    </>
  );
};

export default BrandProfileGeneralInfo;
