import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Input,
  Row,
  Col,
  InputNumber,
  Switch,
  Select,
  message
} from 'antd';
import { db, fieldValue } from '../../firebase.config';
import { useStoreActions, useStoreState } from 'easy-peasy';

const { Option } = Select;
const { TextArea } = Input;

const EditComplementForm = ({ complement, setEditMode }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(complement.name);
  const [units, setUnits] = useState(complement.units);
  const [price, setPrice] = useState(complement.price);
  const [height, setHeight] = useState(complement.height || 0);
  const [width, setWidth] = useState(complement.width || 0);
  const [long, setLong] = useState(complement.long || 0);
  const [diameter, setDiameter] = useState(complement.diameter || 0);
  const [description, setDescription] = useState(complement.description || '');
  const [category, setCategory] = useState(complement.category);
  const [isPhysical, setIsPhysical] = useState(complement.isPhysical);
  const [measureUnits, setMeasureUnits] = useState(complement.measureUnits);
  const [forType, setForType] = useState(complement.forType || []);
  const [types, setTypes] = useState([]);
  const [reference, setReference] = useState(complement.reference || '');
  const { fetchCategories } = useStoreActions(actions => actions.complements);
  const { categories } = useStoreState(state => state.complements);

  useEffect(() => {
    const unsubscribeToTypes = db
      .collection('information')
      .doc('outputTypesAndGoals')
      .onSnapshot(doc => {
        const typesData = doc.data();
        setTypes(typesData.types);
      });
    fetchCategories();
    return () => unsubscribeToTypes();
  }, [fetchCategories]);

  const handleSubmit = async e => {
    e.preventDefault();
    //some validation of fields
    if (!name) return message.error('El elemento debe tener nombre');
    if (!units)
      return message.error('El elemento debe tener cantidades disponibles');
    if (!price) return message.error('El elemento debe tener precio');
    if (!category)
      return message.error('El elemento debe tener asignada una categoria');
    if (!forType)
      return message.error(
        'El elemento debe tener un tipo de evento recomendado'
      );
    if (!reference) return message.error('El elemento debe tener referencia');
    setLoading(true);

    const complementRef = db.collection('complements').doc(complement.id);
    const categoriesRef = db.collection('categories').doc('complements');
    if (complement.category !== category) {
      try {
        await db.runTransaction(async t => {
          const categoriesDoc = await t.get(categoriesRef);
          const categoriesData = categoriesDoc.data();
          const oldCategoryId = complement.categoryId;
          const newCategoryId = categoriesData.categories.filter(
            cat => cat.name === category
          )[0].id;

          let categoryUpdateObject = {
            [oldCategoryId]: fieldValue.increment(-1),
            [newCategoryId]: fieldValue.increment(1)
          };
          if (complement.isPublic) {
            categoryUpdateObject[
              `public-${oldCategoryId}`
            ] = fieldValue.increment(-1);
            categoryUpdateObject[
              `public-${newCategoryId}`
            ] = fieldValue.increment(1);
          }

          t.update(categoriesRef, categoryUpdateObject);

          t.update(complementRef, {
            name,
            units,
            price,
            isPhysical,
            measureUnits,
            width,
            height,
            long,
            diameter,
            category,
            categoryId: newCategoryId,
            description,
            forType,
            reference,
            updatedAt: Date.now()
          });
        });
        setEditMode(false);
      } catch (e) {
        console.log(e.message);
        setLoading(false);
        message.error('No pudimos actualizar el elemento');
      }
    } else {
      try {
        await complementRef.update({
          name,
          units,
          price,
          isPhysical,
          measureUnits,
          width,
          height,
          long,
          diameter,
          description,
          forType,
          reference,
          updatedAt: Date.now()
        });

        setEditMode(false);
      } catch (e) {
        console.log(e.message);
        setLoading(false);
        message.error('No pudimos actualizar el complemento');
      }
    }
  };

  useEffect(() => {
    if (forType.length >= 3) {
      const newForType = forType.slice(0, 2);
      setForType(newForType);
      message.warning('Solo puedes escoger 2 tipos de salida');
    }
  }, [forType]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label='Nombre'>
            <Input
              value={name}
              placeholder='Nombre del elemento'
              onChange={e => setName(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='Cantidades disponibles'>
            <InputNumber
              value={units}
              onChange={value => setUnits(value)}
              placeholder='Unidades'
              style={{ width: '100%' }}
              min={0}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='Precio'>
            <InputNumber
              value={price}
              onChange={value => setPrice(value)}
              formatter={value =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder='$$$$'
              style={{ width: '100%' }}
              min={0}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label='¿Tiene medidas?'>
            <small>¿Agregar alto, ancho y profundo?</small>{' '}
            <Switch
              checked={isPhysical}
              onChange={checked => setIsPhysical(checked)}
            />{' '}
            <small>Unidades: </small>
            <Select
              onChange={value => setMeasureUnits(value)}
              value={measureUnits}
              style={{ width: 120 }}
              disabled={!isPhysical}>
              <Option value='cms'>cms</Option>
              <Option value='mts'>mts</Option>
            </Select>
          </Form.Item>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label='Alto'>
                <InputNumber
                  value={height}
                  onChange={value => setHeight(value)}
                  disabled={!isPhysical}
                  placeholder={measureUnits}
                  style={{ width: '100%' }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Ancho'>
                <InputNumber
                  value={width}
                  onChange={value => setWidth(value)}
                  disabled={!isPhysical}
                  placeholder={measureUnits}
                  style={{ width: '100%' }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Profundo'>
                <InputNumber
                  value={long}
                  onChange={value => setLong(value)}
                  disabled={!isPhysical}
                  placeholder={measureUnits}
                  style={{ width: '100%' }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Diametro'>
                <InputNumber
                  value={diameter}
                  onChange={value => setDiameter(value)}
                  disabled={!isPhysical}
                  placeholder={measureUnits}
                  style={{ width: '100%' }}
                  min={0}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Form.Item label='Descripcion'>
            <TextArea
              value={description}
              onChange={e => setDescription(e.target.value)}
              placeholder='Agrega una breve descripcion de no mas de 140 caracteres, como un Tweet'
              autosize={{ minRows: 4, maxRows: 10 }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='Categoria'>
            <Select value={category} onChange={value => setCategory(value)}>
              {categories &&
                categories.map(category => (
                  <Option key={category.name} value={category.name}>
                    {category.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label='¿Recomendado para cual tipo de salida?'>
            <Select
              mode='multiple'
              placeholder='Puedes escoger hasta 2 tipos de salida'
              value={forType}
              onChange={value => setForType(value)}
              style={{ width: '100%' }}>
              {types.map(type => (
                <Option key={type.type} value={type.type}>
                  {type.type}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <Form.Item label='Referencia'>
            <Input
              value={reference}
              onChange={e => setReference(e.target.value)}
              placeholder='Referencia del elemento'
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type='primary' htmlType='submit' loading={loading}>
          Actualizar elemento
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditComplementForm;
