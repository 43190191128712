import React, { useState, useEffect } from "react";
import { Icon } from "antd";
import { db } from "../../firebase.config";
import UnfinishedOutputCard from "./UnfinishedOutputCard";

const getOutputState = output => {
  if (output.outputState === "approved") {
    if (output.startDate > Date.now()) return "Activa";
    if (output.startDate <= Date.now()) return "En curso";
  } else if (output.outputState === "pending") {
    return "Pendiente";
  } else if (output.outputState === "cancel") {
    return "Cancelada";
  } else if (output.outputState === "finish") return "Efectiva";
  else return output.outputState;
};

const UnfinishedOutputs = () => {
  const [loading, setLoading] = useState(true);
  const [outputsList, setOutputsList] = useState([]);

  useEffect(() => {
    const unsubscribeToOutputs = db
      .collectionGroup("outputs")
      .where("outputState", "==", "approved")
      .where("startDate", "<=", Date.now())
      .onSnapshot(querySnapshot => {
        let outputs = [];
        querySnapshot.forEach(doc => {
          outputs.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setOutputsList(outputs);
        setLoading(false);
      });
    return () => unsubscribeToOutputs();
  }, []);

  return (
    <div className='pageContainer'>
      <h1>Salidas por completar</h1>
      <div className='outputsListContainer'>
        {loading ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        ) : (
          <>
            {outputsList.length === 0 && (
              <div style={{ margin: "auto" }}>
                <h2>
                  No encontramos salidas <Icon type='frown' />
                </h2>
              </div>
            )}

            {outputsList.map(output => (
              <UnfinishedOutputCard
                key={output.id}
                output={output}
                stateText={getOutputState(output)}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default UnfinishedOutputs;
