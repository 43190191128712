import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, message, Icon } from 'antd';
import { db, fieldValue } from '../../firebase.config';
import { useStoreActions, useStoreState } from 'easy-peasy';
import uuid from 'uuid/v4';
import CategoryCard from './CategoryCard';
const ComplementsCategories = () => {
  const [categoryName, setCategoryName] = useState('');
  const [loadingCreateCategory, setLoadingCreateCategory] = useState(false);
  const {
    categories,
    fetchCategoriesState: { loading, error }
  } = useStoreState(state => state.complements);

  const { fetchCategories } = useStoreActions(actions => actions.complements);

  const handleCreateCategory = async e => {
    e.preventDefault();
    if (!categoryName)
      return message.error('El nombre de la categoria es obligatorio');
    setLoadingCreateCategory(true);
    const categoriesRef = db.collection('categories').doc('complements');
    const id = uuid();
    try {
      await db.runTransaction(async t => {
        const categoriesDoc = await t.get(categoriesRef);
        const categories = categoriesDoc.data();
        if (categoriesDoc.exists && categories.names.includes(categoryName)) {
          let errorMessage = {
            code: 'La categoria ya existe',
            message: 'La categoria ya existe'
          };
          throw errorMessage;
        }

        t.set(
          categoriesRef,
          {
            categories: fieldValue.arrayUnion({
              id,
              name: categoryName,
              createdAt: Date.now()
            }),
            names: fieldValue.arrayUnion(categoryName),
            [id]: 0,
            [`public-${id}`]: 0
          },
          { merge: true }
        );
      });

      setCategoryName('');
      fetchCategories();
    } catch (e) {
      console.log(e.message);
      message.error(e.code || 'No pudimos crear la categoria');
    }
    setLoadingCreateCategory(false);
    console.log(categoryName);
  };

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (error) message.error(error.code, 3);
  }, [error]);
  return (
    <div className='pageContainer'>
      <div>
        <h1>Crear categoria de Plus</h1>
        <Form onSubmit={handleCreateCategory}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item>
                <Input
                  placeholder='Nombre de la categoria'
                  value={categoryName}
                  onChange={e => setCategoryName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  htmlType='submit'
                  type='primary'
                  loading={loadingCreateCategory}>
                  Crear categoria
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className='categoriesListContainer'>
        {loading ? (
          <Icon style={{ margin: 'auto', fontSize: 80 }} type='loading' />
        ) : (
          <>
            {categories &&
              categories.map(category => (
                <CategoryCard key={category.name} category={category} />
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ComplementsCategories;
