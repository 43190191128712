import React, { useState } from "react";
import { Divider, Row, Col, Button, message } from "antd";
import { db, fieldValue } from "../../firebase.config";
import formatTimestamp from "../../utils/formatTimestamp";

const getStatusText = status => {
  switch (status) {
    case "pending":
      return "Pendiente por aprobar";
    case "approved":
      return "Aprobado";
    case "cancel":
      return "Cancelado";
    default:
      return status;
  }
};

const RedemptionCard = ({ redemption }) => {
  const [loading, setLoading] = useState(false);
  const [loadingDeny, setLoadingDeny] = useState(false);
  const approveRedemption = async () => {
    setLoading(true);
    try {
      const redemptionRef = db.collection("pointsApprovals").doc(redemption.id);
      await redemptionRef.update({ status: "approved", checked: true });
      message.success("Redencion aprobada");
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos aprobar la redencion");
      setLoading(false);
    }
  };

  const denyRedemption = async () => {
    setLoadingDeny(true);
    try {
      const batch = db.batch();
      const redemptionRef = db.collection("pointsApprovals").doc(redemption.id);
      const pointsRef = db.collection("points").doc(redemption.brandId);
      batch.update(redemptionRef, { status: "cancel", checked: true });
      batch.update(pointsRef, {
        total: fieldValue.increment(redemption.totalPoints)
      });
      await batch.commit();
      message.success("Redencion Cancelada");
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos aprobar la redencion");
      setLoadingDeny(false);
    }
  };
  return (
    <div key={redemption.id}>
      <div style={{ display: "flex" }}>
        <h3>{redemption.element.name}</h3>
        <p style={{ marginLeft: 10, marginTop: 2 }}>
          {formatTimestamp(redemption.createdAt)}
        </p>
      </div>
      <Row>
        <Col span={8}>
          <div>
            <p>
              <strong>Unidades: </strong>
              {redemption.selectedUnits}
            </p>
            <p>
              <strong>Puntos: </strong>
              {redemption.totalPoints}{" "}
            </p>
            <p>
              <strong>Estado: </strong>
              {getStatusText(redemption.status)}{" "}
            </p>
          </div>
        </Col>
        <Col span={8}>
          <div>
            <p>
              <strong>Usuario: </strong>
              {redemption.name}
            </p>
            <p>
              <strong>Email: </strong>
              {redemption.email}{" "}
            </p>
            <p>
              <strong>Marca: </strong>
              {redemption.brandName}
            </p>
          </div>
        </Col>
        <Col span={8}>
          {!redemption.checked && (
            <div style={{ display: "flex" }}>
              <div style={{ margin: 5 }}>
                <Button
                  type='primary'
                  loading={loading}
                  onClick={() => approveRedemption()}
                >
                  Aprobar
                </Button>
              </div>
              <div style={{ margin: 5 }}>
                <Button
                  loading={loadingDeny}
                  onClick={() => denyRedemption()}
                  type='danger'
                >
                  Negar
                </Button>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default RedemptionCard;
