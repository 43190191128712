import React, { useState } from "react";
import { Icon, message } from "antd";
import formatTimestamp from "../../utils/formatTimestamp";
import { db, fieldValue } from "../../firebase.config";

const CategoryCard = ({ category }) => {
  const [loading, setLoading] = useState(false);
  const handlecategoryDelete = async () => {
    setLoading(true);
    try {
      const categoryRef = db.collection("categories").doc("inventory");
      await categoryRef.update({
        categories: fieldValue.arrayRemove({
          id: category.id,
          name: category.name,
          createdAt: category.createdAt
        }),
        names: fieldValue.arrayRemove(category.name)
      });
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos eliminar la categoria");
      setLoading(false);
    }
  };
  return (
    <div className='categoryCard'>
      <div style={{ display: "flex" }}>
        {(!category.count || category.count.length === 0) && (
          <Icon
            type={loading ? "loading" : "close"}
            shape='circle'
            style={{ fontSize: 12 }}
            onClick={() => handlecategoryDelete()}
          />
        )}
      </div>

      <h2>{category.name}</h2>
      <small>creada en: {formatTimestamp(category.createdAt)}</small>
    </div>
  );
};

export default CategoryCard;
