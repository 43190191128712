import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Button,
  message
} from "antd";
import { useStoreState } from "easy-peasy";
import { db, fieldValue } from "../../firebase.config";
import locale from "antd/lib/date-picker/locale/es_ES";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const dateFormat = "DD-MM-YYYY";

function getAbsoulteMonths(momentDate) {
  const months = Number(momentDate.format("MM"));
  const years = Number(momentDate.format("YYYY"));
  return months + years * 12;
}

const EditOutputForm = ({ output, setEditMode }) => {
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [types, setTypes] = useState([]);
  const [targetsList, setTargetList] = useState([]);
  const [outputTarget, setOutputTarget] = useState(output.target || "");
  const [outputName, setOutputName] = useState(output.name || "");
  const [outputType, setOutputType] = useState(output.type || "");
  const [outputCity, setOutputCity] = useState(output.city || "");
  const [outputPlace, setOutputPlace] = useState(output.place || "");
  const [outputObservations, setOutputObservations] = useState(
    output.observations || ""
  );
  const [outputDate, setOutputDate] = useState([
    moment(output.startDate) || moment(),
    moment(output.finishDate) || moment()
  ]);
  const [outputMountingDate, setOutputMountingDate] = useState(
    moment(output.mountingDate)
  );
  const [outputUnmountingDate, setOutputUnmountingDate] = useState(
    moment(output.unmountingDate)
  );
  const { currentUser, documentId } = useStoreState(state => state.user);

  useEffect(() => {
    const getCities = async () => {
      try {
        const citiesRef = db.collection("information").doc("cities");
        const citiesDoc = await citiesRef.get();
        const citiesData = citiesDoc.data();
        setCities(citiesData.cities);
      } catch (e) {
        console.log(e.message);
      }
    };
    const getTypes = async () => {
      try {
        const typesRef = db
          .collection("information")
          .doc("outputTypesAndGoals");
        const typesDoc = await typesRef.get();
        const typesData = typesDoc.data();
        if (typesData.types) setTypes(typesData.types);
      } catch (e) {
        console.log(e.message);
      }
    };
    if (cities.length === 0) getCities();
    if (types.length === 0) getTypes();
  }, [cities, types]);

  useEffect(() => {
    if (types.length > 0 && outputType) {
      const newTargetIndex = types.findIndex(
        target => target.type === outputType
      );
      setTargetList(types[newTargetIndex].goals);
      if (outputType !== output.type) {
        setOutputTarget(undefined);
      }
    } else {
      setTargetList([]);
    }
  }, [types, outputType, output.type]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (!outputName) return message.error("El nombre es obligatorio");
    if (!outputCity) return message.error("La ciudad es obligatoria");
    if (!outputPlace) return message.error("El lugar de salida es obligatorio");
    if (outputDate.length === 0)
      return message.error("La fecha es obligatoria");
    if (!outputMountingDate)
      return message.error("La fecha de montaje es obligatoria");
    if (!outputUnmountingDate)
      return message.error("La fecha de desmontaje es obligatoria");
    if (outputMountingDate.valueOf() > outputUnmountingDate.valueOf())
      return message.error(
        "La fecha de montaje no puede ser mayor que la fecha de desmontaje"
      );
    if (!outputType) return message.error("El tipo de salida es obligatorio");
    setLoading(true);
    const startMonths = getAbsoulteMonths(outputMountingDate);
    const endMonths = getAbsoulteMonths(outputUnmountingDate);
    let months = endMonths - startMonths;
    let count = 0;
    let firstMonth = outputMountingDate.month();
    let year = outputMountingDate.year();
    let monthsList = [];
    for (let i = 0; i <= months; i++) {
      let currentMonth = firstMonth + count + 1;
      if (currentMonth > 12) {
        year += 1;
        count = 0;
        firstMonth = 0;
        currentMonth = 1;
      }
      monthsList.push(`${year}-${currentMonth}`);
      count += 1;
    }
    console.log(monthsList);
    const startDate = outputDate[0].valueOf();
    const finishDate = outputDate[1].valueOf();

    const channelIndex = types.findIndex(t => t.type === outputType);
    const channel = types[channelIndex].channel || "";

    const newOutput = {
      name: outputName,
      city: outputCity,
      place: outputPlace,
      startDate: startDate,
      finishDate: finishDate,
      mountingDate: outputMountingDate.valueOf(),
      unmountingDate: outputUnmountingDate.valueOf(),
      monthsList,
      type: outputType,
      channel,
      observations: outputObservations,
      updatedById: documentId,
      updatedBy: currentUser.name,
      updatedByEmail: currentUser.email,
      target: outputTarget || "",
      updatedAt: Date.now()
    };

    const outputRef = db
      .collection("brands")
      .doc(output.brandId)
      .collection("outputs")
      .doc(output.id);

    if (startDate !== output.startDate || finishDate !== output.finishDate) {
      try {
        const batch = db.batch();
        output.elements.forEach(element => {
          const elementRef = db
            .collection("brands")
            .doc(element.brandId)
            .collection("series")
            .doc(element.serieId)
            .collection("elements")
            .doc(element.id);
          batch.update(elementRef, {
            reservedDates: fieldValue.arrayRemove({
              outputId: output.id,
              finishDate: output.finishDate,
              selectedUnits: element.selectedUnits,
              startDate: output.startDate
            })
          });
          batch.update(elementRef, {
            reservedDates: fieldValue.arrayUnion({
              outputId: output.id,
              finishDate,
              selectedUnits: element.selectedUnits,
              startDate
            })
          });
        });
        output.inventoryElements.forEach(element => {
          const inventoryElementRef = db
            .collection("brands")
            .doc(element.brandId)
            .collection("inventory")
            .doc(element.id);
          batch.update(inventoryElementRef, {
            reservedDates: fieldValue.arrayRemove({
              outputId: output.id,
              finishDate: output.finishDate,
              selectedUnits: element.selectedUnits,
              startDate: output.startDate
            })
          });
          batch.update(inventoryElementRef, {
            reservedDates: fieldValue.arrayUnion({
              outputId: output.id,
              finishDate,
              selectedUnits: element.selectedUnits,
              startDate
            })
          });
        });
        output.complements.forEach(complement => {
          const complementRef = db.collection("complements").doc(complement.id);
          batch.update(complementRef, {
            reservedDates: fieldValue.arrayRemove({
              outputId: output.id,
              finishDate: output.finishDate,
              selectedUnits: complement.selectedUnits,
              startDate: output.startDate
            })
          });
          batch.update(complementRef, {
            reservedDates: fieldValue.arrayUnion({
              outputId: output.id,
              finishDate,
              selectedUnits: complement.selectedUnits,
              startDate
            })
          });
        });

        batch.update(outputRef, newOutput);
        await batch.commit();
        message.success("Hemos actualizado la salida");
        setLoading(false);
        setEditMode(false);
      } catch (e) {
        setLoading(false);
        console.log(e.message);
        message.error("No pudimos actualizar la salida");
      }
    } else {
      try {
        await outputRef.update(newOutput);
        message.success("Hemos actualizado la salida");
        setLoading(false);
        setEditMode(false);
      } catch (e) {
        console.log(e.message);
        setLoading(false);
        message.error("No pudimos actualizar la salida");
      }
    }
  };
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Nombre de la salida">
              <Input
                value={outputName}
                placeholder="Nombre de la salida"
                onChange={e => setOutputName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Tipo de salida">
              <Select
                value={outputType}
                onChange={value => setOutputType(value)}
                style={{ width: "100%" }}
              >
                {types.map(type => (
                  <Option key={type.type} value={type.type}>
                    {type.type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Ciudad">
              <Select
                onChange={value => setOutputCity(value)}
                value={outputCity}
                style={{ width: "100%" }}
              >
                {cities.map(city => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Lugar">
              <Input
                value={outputPlace}
                placeholder="Lugar de la salida"
                onChange={e => setOutputPlace(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Fecha de la salida">
              <RangePicker
                onChange={date => setOutputDate(date)}
                format={dateFormat}
                value={outputDate}
                locale={locale}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Fecha de montaje">
              <DatePicker
                format={dateFormat}
                onChange={date => setOutputMountingDate(date)}
                value={outputMountingDate}
                locale={locale}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Fecha de desmontaje">
              <DatePicker
                format={dateFormat}
                onChange={date => setOutputUnmountingDate(date)}
                value={outputUnmountingDate}
                locale={locale}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Observaciones">
              <TextArea
                placeholder="Observaciones"
                onChange={e => setOutputObservations(e.target.value)}
                value={outputObservations}
                autosize={{ minRows: 1, maxRows: 6 }}
              />
            </Form.Item>
          </Col>
        </Row>
        {output.target && (
          <Row>
            <Col span={6}>
              <Form.Item label="Objetivo de la salida">
                <Select
                  value={outputTarget}
                  style={{ minWidth: 200 }}
                  onChange={value => setOutputTarget(value)}
                  placeholder="Escoje un objetivo"
                >
                  {targetsList.map(target => (
                    <Option key={target} value={target}>
                      {target}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Actualizar salida
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditOutputForm;
