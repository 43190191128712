import React from "react";
import { Tabs } from "antd";
import OutputsListByState from "./OutputsListByState";
import "./outputs.css";
const { TabPane } = Tabs;
const Outputs = () => {
  return (
    <div className='pageContainer'>
      <Tabs defaultActiveKey='pendientes'>
        <TabPane tab='Pendientes' key='pendientes'>
          <OutputsListByState state='pending' />
        </TabPane>
        <TabPane tab='Activas' key='activas'>
          <OutputsListByState state='active' />
        </TabPane>
        <TabPane tab='En curso' key='curso'>
          <OutputsListByState state='onGoing' />
        </TabPane>
        <TabPane tab='Efectivas' key='effective'>
          <OutputsListByState state='finish' />
        </TabPane>
        <TabPane tab='Canceladas' key='canceladas'>
          <OutputsListByState state='cancel' />
        </TabPane>
      </Tabs>
    </div>
  );
};
export default Outputs;
