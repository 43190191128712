import React, { useState } from "react";
import { Button, message, Modal } from "antd";
import { withRouter } from "react-router-dom";
import { db, fieldValue } from "../../firebase.config";
const { confirm } = Modal;
const DeleteKeepmePointsElement = ({ element, history }) => {
  const [loading, setLoading] = useState(false);
  const confirmDelete = () => {
    confirm({
      title: "¿Seguro que deseas borrar este elemento?",
      content:
        "Si lo borras ya nadie podra usarlo, aun asi, quedara registrado en las salidas que lo usaron",
      okText: "Si, vamo'a darle",
      okType: "danger",
      cancelText: "No gracias",
      async onOk() {
        await handleDeleteElement();
      },
      onCancel() {}
    });
  };

  const handleDeleteElement = async () => {
    setLoading(true);
    const elementRef = db.collection("pointsCatalog").doc(element.id);
    const categoriesRef = db.collection("categories").doc("keepmepoints");
    const batch = db.batch();
    try {
      batch.delete(elementRef);
      batch.update(categoriesRef, {
        [element.categoryId]: fieldValue.increment(-1)
      });
      await batch.commit();
      history.push("/catalogo-keepmepoints");
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos borrar el elemento");
      setLoading(false);
    }
  };
  return (
    <div>
      <Button type='danger' onClick={confirmDelete} loading={loading}>
        Borrar elemento
      </Button>
    </div>
  );
};

export default withRouter(DeleteKeepmePointsElement);
