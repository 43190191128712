import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Button, message, Tag, Icon } from "antd";
import { db, fieldValue } from "../../firebase.config";
import { useStoreActions, useStoreState } from "easy-peasy";

const CreateBrandTags = () => {
  const [tagName, setTagName] = useState("");
  const [loadingCreateTag, setLoadingCreateTag] = useState(false);
  const { fetchBrandTags } = useStoreActions(actions => actions.brands);
  const {
    brandTags,
    fetchBrandTagsState: { loading, error }
  } = useStoreState(state => state.brands);

  const handleCreateTag = async e => {
    e.preventDefault();
    setLoadingCreateTag(true);
    const tagsDocumentRef = db.collection("atributes").doc("tags");
    try {
      const tagsDocument = await tagsDocumentRef.get();

      if (tagsDocument.exists) {
        const tagsDocumentData = tagsDocument.data();
        if (
          tagsDocumentData &&
          tagsDocumentData.tags &&
          tagsDocumentData.tags.includes(tagName)
        ) {
          setLoadingCreateTag(false);
          return message.error("Ya existe este atributo");
        }
      }
      await tagsDocumentRef.set(
        {
          tags: fieldValue.arrayUnion(tagName)
        },
        { merge: true }
      );
      setTagName("");
      setLoadingCreateTag(false);
      fetchBrandTags();
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos crear el atributo");
    }

    console.log(tagName);
  };

  const handleDeleteTag = async tag => {
    const tagsDocumentRef = db.collection("atributes").doc("tags");
    try {
      await tagsDocumentRef.update({
        tags: fieldValue.arrayRemove(tag)
      });
      console.log("Tag " + tag + " deleted");
    } catch (e) {
      console.log(e.message);
      fetchBrandTags();
      message.error("No pudimos borrar el atributo");
    }
  };
  useEffect(() => {
    fetchBrandTags();
  }, [fetchBrandTags]);

  useEffect(() => {
    if (error) message.error(error);
  }, [error]);
  return (
    <div className="pageContainer">
      <div>
        <h1>Crear atributos o audiencia de marca</h1>
        <Form onSubmit={handleCreateTag}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item>
                <Input
                  placeholder="Nombre del atributo o audiencia"
                  value={tagName}
                  onChange={e => setTagName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loadingCreateTag}
                >
                  Crear atributo
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {loading ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type="loading" />
        ) : (
          <>
            {brandTags.length === 0 && (
              <div style={{ margin: "auto" }}>
                <h2>
                  No encontramos atributos <Icon type="frown" />
                </h2>
              </div>
            )}
            {brandTags.map(tag => (
              <Tag
                style={{ marginTop: 10 }}
                color="#222"
                closable
                onClose={() => {
                  // e.preventDefault();
                  handleDeleteTag(tag);
                }}
                key={tag}
              >
                {tag}
              </Tag>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default CreateBrandTags;
