import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  InputNumber,
  Select,
  Divider,
  message,
  Switch,
  DatePicker
} from "antd";
import { withRouter } from "react-router-dom";
import { db, fieldValue } from "../../firebase.config";
import locale from "antd/lib/date-picker/locale/es_ES";

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = "DD-MM-YYYY";

const CreateInventoryElement = ({ match, history }) => {
  const [categories, setCategories] = useState([]);
  const [currentBrand, setCurrentBrand] = useState({});
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState("Nombre del elemento");
  const [category, setCategory] = useState("");
  const [isPhysical, setIsPhysical] = useState(false);
  const [units, setUnits] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [long, setLong] = useState(0);
  const [diameter, setDiameter] = useState(0);
  const [observations, setObservations] = useState("");
  const [measureUnits, setMeasureUnits] = useState("mts");
  const [unitsType, setUnitsType] = useState("unds");
  const [hasMinUnits, setHasMinUnits] = useState(false);
  const [minUnits, setMinUnits] = useState(0);
  const [hasExpirationDate, setHasExpirationDate] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);

  const handleSubmit = async e => {
    e.preventDefault();
    if (!name) return message.error("El nombre es obligatorio");
    if (!units) return message.error("La cantidad disponible es obligatoria");
    if (!measureUnits) return message.error("El tipo de medida es obligatorio");
    if (!unitsType) return message.error("El tipo de unidades es obligatorio");
    if (minUnits >= units)
      return message.error(
        "Las unidades minimas no pueden ser iguales o mayores a las unidades disponibles"
      );
    if (hasExpirationDate && !expirationDate)
      return message.error(
        "Si tiene fecha de vencimiento, debes ponerla, Dahh!!"
      );
    setLoading(true);
    const inventoryElementRef = db
      .collection("brands")
      .doc(currentBrand.id)
      .collection("inventory")
      .doc();
    const brandRef = db.collection("brands").doc(currentBrand.id);
    const batch = db.batch();
    const currentCategory = categories.filter(cat => cat.name === category)[0];
    try {
      batch.update(brandRef, {
        inventoryCategories: fieldValue.arrayUnion(currentCategory),
        [currentCategory.id]: fieldValue.increment(1)
      });
      batch.set(inventoryElementRef, {
        name,
        units,
        minUnits,
        hasMinUnits,
        unitsType,
        isPhysical,
        height,
        width,
        long,
        diameter,
        measureUnits,
        category,
        categoryId: currentCategory.id,
        observations,
        mainImage: "",
        isPublic: false,
        hasExpirationDate,
        expirationDate: hasExpirationDate
          ? expirationDate.valueOf()
          : Date.now(),
        notified: hasExpirationDate
          ? !(expirationDate.valueOf() > Date.now())
          : false,
        brandName: currentBrand.name,
        brandId: currentBrand.id,
        brandColor: currentBrand.mainColor,
        reservedDates: [],
        createdAt: Date.now()
      });
      await batch.commit();
      history.push(
        `/inventario/elemento/${currentBrand.id}/${inventoryElementRef.id}`
      );
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos crear el elemento en el inventario");
    }
  };

  useEffect(() => {
    const complementRef = db.collection("brands").doc(match.params.brandId);
    const categoriesRef = db.collection("categories").doc("inventory");
    const unsubscribeToBrand = complementRef.onSnapshot(doc => {
      if (doc.exists) setDisabled(false);
      setCurrentBrand({
        id: doc.id,
        ...doc.data()
      });
    });
    const unsubscribeToCategories = categoriesRef.onSnapshot(doc => {
      const categoriesDoc = doc.data();
      setCategories(categoriesDoc.categories);
    });
    return () => {
      unsubscribeToBrand();
      unsubscribeToCategories();
    };
  }, [match.params]);

  useEffect(() => {
    if (!hasMinUnits && minUnits !== 0) setMinUnits(0);
  }, [hasMinUnits, minUnits]);
  return (
    <div className='pageContainer'>
      <div className='serieProfileContainer'>
        <h2>
          Crear elemento para el inventario de la marca{" "}
          <strong>{currentBrand.name}</strong>
        </h2>
      </div>
      <Divider />
      <Title>{name}</Title>
      <Form onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Nombre'>
              <Input
                placeholder='Nombre del elemento'
                onChange={e => setName(e.target.value)}
                disabled={disabled}
              />
            </Form.Item>
            <Form.Item label='Categoria'>
              <Select
                showSearch
                placeholder='Escoge una categoria'
                onChange={value => setCategory(value)}
                style={{ width: "100%" }}
                disabled={disabled || categories.length === 0}
              >
                {categories.map(category => (
                  <Option key={category.id} value={category.name}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Cantidade disponible'>
              <InputNumber
                onChange={value => setUnits(value)}
                placeholder={unitsType}
                style={{ width: 150 }}
                min={0}
                disabled={disabled}
              />
              <span style={{ marginLeft: 5 }}>
                <small>Tipo de unidades: </small>
                <Select
                  onChange={value => setUnitsType(value)}
                  value={unitsType}
                  style={{ width: 100 }}
                  disabled={disabled}
                >
                  <Option value='unds'>unds</Option>
                  <Option value='mts'>mts</Option>
                  <Option value='cajas'>cajas</Option>
                </Select>
              </span>
            </Form.Item>
            <Form.Item label='Cantidad minima de aviso'>
              <small>Activar </small>
              <Switch
                checked={hasMinUnits}
                onChange={checked => setHasMinUnits(checked)}
              />
              <small> Cantidad minima: </small>
              <InputNumber
                onChange={value => setMinUnits(value)}
                placeholder='Unidades minimas'
                style={{ width: 160 }}
                min={0}
                value={minUnits}
                disabled={disabled || !hasMinUnits}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Medidas'>
              <small>¿Agregar dimensiones?</small>{" "}
              <Switch
                checked={isPhysical}
                onChange={checked => setIsPhysical(checked)}
              />{" "}
              <small>Unidades: </small>
              <Select
                onChange={value => setMeasureUnits(value)}
                value={measureUnits}
                style={{ width: 100 }}
                disabled={disabled || !isPhysical}
              >
                <Option value='cms'>cms</Option>
                <Option value='mts'>mts</Option>
              </Select>
            </Form.Item>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label='Alto'>
                  <InputNumber
                    onChange={value => setHeight(value)}
                    placeholder={measureUnits}
                    style={{ width: "100%" }}
                    min={0}
                    disabled={disabled || !isPhysical}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='Ancho'>
                  <InputNumber
                    onChange={value => setWidth(value)}
                    placeholder={measureUnits}
                    style={{ width: "100%" }}
                    min={0}
                    disabled={disabled || !isPhysical}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='Profundo'>
                  <InputNumber
                    onChange={value => setLong(value)}
                    placeholder={measureUnits}
                    style={{ width: "100%" }}
                    min={0}
                    disabled={disabled || !isPhysical}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='Diametro'>
                  <InputNumber
                    onChange={value => setDiameter(value)}
                    placeholder={measureUnits}
                    style={{ width: "100%" }}
                    min={0}
                    disabled={disabled || !isPhysical}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Form.Item label='Fecha de vencimiento'>
              <small>Activar </small>
              <Switch
                checked={hasExpirationDate}
                onChange={checked => setHasExpirationDate(checked)}
              />{" "}
              <DatePicker
                disabled={!hasExpirationDate}
                format={dateFormat}
                onChange={date => setExpirationDate(date)}
                value={expirationDate}
                locale={locale}
              />
            </Form.Item>
            <Form.Item label='Observaciones'>
              <TextArea
                value={observations}
                onChange={e => setObservations(e.target.value)}
                placeholder='Agrega una breve descripcion de no mas de 140 caracteres, como un Tweet'
                autosize={{ minRows: 4, maxRows: 10 }}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            disabled={disabled}
            type='primary'
            htmlType='submit'
            loading={loading}
          >
            Crear elemento
          </Button>
          <div>
            <small>Luego de que lo crees, puedes agregarle imagenes</small>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default withRouter(CreateInventoryElement);
