import React, { useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { db } from "../../firebase.config";
import { Icon } from "antd";
import CompanyCard from "./CompanyCard";

const CompaniesList = () => {
  const { companies, loadingCompanies } = useStoreState(
    state => state.companies
  );
  const { setLoadingCompanies, setCompaniesList } = useStoreActions(
    actions => actions.companies
  );

  useEffect(() => {
    setLoadingCompanies(true);
    const companiesUnsubscribe = db
      .collection("companies")
      .orderBy("createdAt", "desc")
      .limit(50)
      .onSnapshot(querySnapshot => {
        let companiesSnapshot = [];
        querySnapshot.forEach(doc => {
          companiesSnapshot.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setLoadingCompanies(false);
        setCompaniesList(companiesSnapshot);
      });

    return () => companiesUnsubscribe();

    // if (companies.length === 0) fetchCompaniesList();
  }, [setCompaniesList, setLoadingCompanies]);
  return (
    <>
      <div className='companiesListContainer'>
        {loadingCompanies ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        ) : (
          <>
            {companies.map(company => (
              <CompanyCard key={company.id} company={company} />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default CompaniesList;
