import React, { useState, useEffect } from "react";
import { Icon, Button, Tag, message } from "antd";
import { db, fieldValue } from "../../firebase.config";
import { withRouter, Link } from "react-router-dom";
import InventoryElementCard from "./InventoryElementCard";
const Inventory = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [elementsList, setElementsList] = useState([]);
  const [currentBrand, setCurrentBrand] = useState({});
  const [currentQuery, setCurrentQuery] = useState("");

  useEffect(() => {
    const unsuscribeToBrand = db
      .collection("brands")
      .doc(match.params.brandId)
      .onSnapshot(doc => {
        setCurrentBrand({
          id: doc.id,
          ...doc.data()
        });
      });
    const fetchElements = async category => {
      setLoading(true);
      let elementsRef;
      if (category) {
        elementsRef = db
          .collection("brands")
          .doc(match.params.brandId)
          .collection("inventory")
          .where("category", "==", category)
          .limit(50);
      } else {
        elementsRef = db
          .collection("brands")
          .doc(match.params.brandId)
          .collection("inventory")
          .orderBy("createdAt", "desc")
          .limit(20);
      }

      try {
        const snapshot = await elementsRef.get();
        const elements = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setLoading(false);
        setElementsList(elements);
      } catch (e) {
        message.error("No pudimos cargar la lista de elementos");
        console.log(e.message);
        setLoading(false);
      }
    };
    fetchElements(currentQuery);
    return () => {
      unsuscribeToBrand();
    };
  }, [currentQuery, match.params]);

  const handleDeleteCategory = async categoryId => {
    const brandRef = db.collection("brands").doc(currentBrand.id);
    const categoryToDelete = currentBrand.inventoryCategories.filter(
      category => category.id === categoryId
    )[0];
    if (currentBrand[categoryId] === 0) {
      try {
        await brandRef.update({
          inventoryCategories: fieldValue.arrayRemove(categoryToDelete),
          [categoryId]: fieldValue.delete()
        });
      } catch (e) {
        console.log(e.message);
        message.error("No pudimos eliminar la categoria");
      }
    }
  };
  return (
    <div className='pageContainer'>
      <div className='serieProfileContainer'>
        <h2>
          Inventario de <strong>{currentBrand.name}</strong>:
        </h2>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Link to={`/inventario/crear-elemento/${currentBrand.id}`}>
            <Button type='primary'> Agregar elemento</Button>
          </Link>
        </div>
        <small>Categorias: </small>{" "}
        <Tag
          onClick={() => setCurrentQuery("")}
          color={currentQuery === "" ? "#222" : null}
        >
          Todas
        </Tag>
        {currentBrand.inventoryCategories &&
          currentBrand.inventoryCategories.map(category => (
            <Tag
              closable={currentBrand[category.id] === 0}
              onClose={() => handleDeleteCategory(category.id)}
              color={currentQuery === category.name ? "#222" : null}
              onClick={() => {
                if (currentBrand[category.id] !== 0)
                  setCurrentQuery(category.name);
              }}
              key={category.id}
            >
              {category.name} ({currentBrand[category.id]})
            </Tag>
          ))}
      </div>
      <div className='inventoriesList'>
        {loading ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        ) : (
          <>
            {elementsList.length === 0 && (
              <div style={{ margin: "auto" }}>
                <h2>
                  No encontramos nada en el inventario <Icon type='frown' />
                </h2>
              </div>
            )}

            {elementsList.map(element => (
              <InventoryElementCard key={element.id} element={element} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(Inventory);
