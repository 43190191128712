import React, { useState } from "react";
import { Upload, Button, Icon, message, Progress } from "antd";
import { storage, db, fieldValue } from "../../firebase.config";

const UploadKeepmePointsElementGallery = ({ title, id }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [progressVisible, setProgressVisible] = useState(false);

  const beforeUpload = file => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Solo puedes subir imagenes en PNG o JPG");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error("La imagen no puede ser mayor a un 1MB");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleUpload = async ({ file, onSuccess }) => {
    setProgressVisible(true);
    const storageRef = storage.ref();
    const fileRef = storageRef.child(
      `pointsCatalog/${id}/gallery/${file.name}`
    );
    const uploadTask = fileRef.put(file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(Math.trunc(progress));
        console.log("Upload is " + progress + "% done");
      },
      error => {
        message.error(error, 3);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(async downloadUrl => {
          if (downloadUrl) {
            const elementRef = db.collection("pointsCatalog").doc(id);
            await elementRef.update({
              gallery: fieldValue.arrayUnion(downloadUrl)
            });
            onSuccess();
          }
        });
      }
    );
  };
  return (
    <>
      <Upload
        beforeUpload={beforeUpload}
        multiple={false}
        customRequest={handleUpload}
        fileList={[]}
        onRemove={e => console.log(e)}
      >
        <Button type='primary' size='small'>
          <Icon type='upload' /> {title}
        </Button>
        {progressVisible && <Progress percent={uploadProgress} size='small' />}
      </Upload>
    </>
  );
};

export default UploadKeepmePointsElementGallery;
