import React, { useState } from "react";
import { Icon, Popover, Button, message, Form } from "antd";
import BrandColor from "./BrandColor";
import BrandProfileFormSuccess from "./BrandProfileFormSuccess";
import { TwitterPicker } from "react-color";
import hexToCMYK from "../../utils/hexToCMYK";
import uuid from "uuid/v4";
import { useStoreActions, useStoreState } from "easy-peasy";

const BrandProfileColorsForm = ({ brand, setEditMode }) => {
  const [pickerColor, setPickerColor] = useState();
  const [brandColors, setBrandColors] = useState([...brand.brandColors]);

  const { updateBrandColors, setUpdateBrandColorsState } = useStoreActions(
    actions => actions.brands
  );
  const { loading, success } = useStoreState(
    state => state.brands.updateBrandColorsState
  );

  const handleAddBrandColor = color => {
    if (!pickerColor) {
      return message.error("Escoge un color porfa", 3);
    }
    const id = uuid();
    setBrandColors([
      ...brandColors,
      {
        id,
        name: "Nombre del color",
        hex: color.hex,
        rgb: color.rgb,
        cmyk: hexToCMYK(color.hex),
        pantone: ""
      }
    ]);
  };

  const handleUpdateBrandColor = color => {
    const indexOfColor = brandColors.findIndex(c => c.id === color.id);
    const newBrandColors = [...brandColors];
    newBrandColors[indexOfColor] = color;
    setBrandColors(newBrandColors);
  };

  const handleRemoveBrandColor = id => {
    const newBrandColors = brandColors.filter(
      brandColor => brandColor.id !== id
    );
    setBrandColors(newBrandColors);
  };

  const handleUpdateBrandColors = e => {
    e.preventDefault();
    updateBrandColors({ id: brand.id, brandColors });
    console.log(brandColors);
  };
  return (
    <>
      {success ? (
        <BrandProfileFormSuccess
          setEditMode={setEditMode}
          brandId={brand.id}
          resetState={setUpdateBrandColorsState}
        />
      ) : (
        <Form onSubmit={handleUpdateBrandColors}>
          <div>
            <Popover
              trigger='click'
              placement='right'
              content={
                <>
                  <TwitterPicker
                    triangle='hide'
                    color={pickerColor}
                    onChangeComplete={color => setPickerColor(color)}
                  />
                  <Button
                    type='primary'
                    block
                    onClick={() => handleAddBrandColor(pickerColor)}
                  >
                    Agregar color
                  </Button>
                </>
              }
            >
              <div className='colorCircle'>
                <Icon type='plus' style={{ margin: "auto" }} />
              </div>
            </Popover>
            <div className='brandColorsList'>
              {brandColors.map(color => (
                <BrandColor
                  key={color.id}
                  color={color}
                  id={color.id}
                  removeColor={handleRemoveBrandColor}
                  updateColor={handleUpdateBrandColor}
                />
              ))}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item style={{ margin: "auto" }}>
              <Button type='primary' htmlType='submit' loading={loading}>
                Actualizar Brand Colors
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
    </>
  );
};

export default BrandProfileColorsForm;
