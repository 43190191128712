import React, { useEffect } from "react";
import BrandCard from "./BrandCard";
import { db } from "../../firebase.config";
import { Icon } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";

const AllBrands = () => {
  const { brands, loadingBrands } = useStoreState(state => state.brands);
  const { setBrandsList, setLoadingBrands } = useStoreActions(
    actions => actions.brands
  );
  useEffect(() => {
    setLoadingBrands(true);
    const unsuscribeToBrands = db
      .collection("brands")
      .limit(50)
      .onSnapshot(querySnapshot => {
        let brandsFromSnapshot = [];
        querySnapshot.forEach(doc => {
          brandsFromSnapshot.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setLoadingBrands(false);
        setBrandsList(brandsFromSnapshot);
      });
    return () => unsuscribeToBrands();
  }, [setBrandsList, setLoadingBrands]);
  return (
    <div className='pageContainer'>
      <h3>Todas las marcas</h3>
      <p>
        Aqui veras el consolidado de todas las marcas con sus respectivos
        perfiles.
      </p>
      <div className='companiesListContainer'>
        {loadingBrands ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        ) : (
          <>
            {brands.map(brand => (
              <BrandCard key={brand.id} brand={brand} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default AllBrands;
