import React from "react";
import { Button, Icon } from "antd";
import { Link } from "react-router-dom";

const SerieElementCard = ({ element }) => {
  return (
    <div className='serieCard'>
      <div style={{ height: 300, display: "flex" }}>
        <div
          className='elementCardImage'
          style={{
            backgroundImage: `url(${element.mainImage || null})`,
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}
        >
          {element && !element.mainImage && (
            <Icon type='picture' style={{ margin: "auto", fontSize: 20 }} />
          )}
        </div>
      </div>
      <div style={{ padding: 20 }}>
        <h2>
          {element.name} {!element.isPublic && <Icon type='eye-invisible' />}
        </h2>

        <div style={{ marginBottom: 20 }}>
          <div>
            <p>
              {" "}
              <strong>Cantidad disponible: </strong>
              {element.units}
            </p>
          </div>
        </div>
        <Link
          to={`/serie/elemento/${element.brandId}/${element.serieId}/${element.id}`}
        >
          <Button type='primary' block>
            Ver elemento de la serie
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default SerieElementCard;
