import React, { useState } from "react";
import {
  Form,
  Button,
  Input,
  Row,
  Col,
  InputNumber,
  Select,
  message
} from "antd";
import { db } from "../../firebase.config";
const { Option } = Select;
const { TextArea } = Input;
const EditSerieElementForm = ({ element, setEditMode }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(element.name);
  const [units, setUnits] = useState(element.units);
  const [height, setHeight] = useState(element.height || 0);
  const [width, setWidth] = useState(element.width || 0);
  const [long, setLong] = useState(element.long || 0);
  const [diameter, setDiameter] = useState(element.diameter || 0);
  const [observations, setObservations] = useState(element.observations || "");
  const [measureUnits, setMeasureUnits] = useState(element.measureUnits);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    //some validation of fields
    if (!name) return message.error("El elemento debe tener nombre");
    if (!units)
      return message.error("El elemento debe tener cantidades disponibles");
    if (!measureUnits)
      return message.error("El elemento debe tener unidad de medida");
    const elementRef = db
      .collection("brands")
      .doc(element.brandId)
      .collection("series")
      .doc(element.serieId)
      .collection("elements")
      .doc(element.id);
    try {
      await elementRef.update({
        name,
        units,
        measureUnits,
        width,
        height,
        long,
        diameter,
        observations,
        updatedAt: Date.now()
      });

      setEditMode(false);
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos actualizar el complemento");
      setLoading(false);
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label='Nombre'>
            <Input
              value={name}
              placeholder='Nombre del elemento'
              onChange={e => setName(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Cantidades disponibles'>
            <InputNumber
              value={units}
              onChange={value => setUnits(value)}
              placeholder='Unidades'
              style={{ width: "100%" }}
              min={0}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label='Medidas'>
            <small>Unidades: </small>
            <Select
              onChange={value => setMeasureUnits(value)}
              value={measureUnits}
              style={{ width: 100 }}
            >
              <Option value='cms'>cms</Option>
              <Option value='mts'>mts</Option>
            </Select>
          </Form.Item>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label='Alto'>
                <InputNumber
                  value={height}
                  onChange={value => setHeight(value)}
                  placeholder={measureUnits}
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Ancho'>
                <InputNumber
                  value={width}
                  onChange={value => setWidth(value)}
                  placeholder={measureUnits}
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Profundo'>
                <InputNumber
                  value={long}
                  onChange={value => setLong(value)}
                  placeholder={measureUnits}
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Diametro'>
                <InputNumber
                  value={diameter}
                  onChange={value => setDiameter(value)}
                  placeholder={measureUnits}
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Form.Item label='Observaciones'>
            <TextArea
              value={observations}
              onChange={e => setObservations(e.target.value)}
              placeholder='Agrega una breve descripcion de no mas de 140 caracteres, como un Tweet'
              autosize={{ minRows: 4, maxRows: 10 }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type='primary' htmlType='submit' loading={loading}>
          Actualizar elemento
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditSerieElementForm;
