import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Form, Select, Row, Col, Button, Icon, Switch } from "antd";
import UserCard from "./UserCard";
import "./users.css";
const { Option } = Select;

const extractBrands = companiesArray => {
  let newBrands = [];
  companiesArray.forEach(company => {
    if (company.hasOwnProperty("brands")) {
      const companyBrands = company.brands.map(brand => brand.name);
      newBrands = [...newBrands, ...companyBrands];
    }
  });
  return newBrands;
};
const AllUsers = () => {
  const [company, setCompany] = useState("all");
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("all");
  const [lastLoginFilter, setLastLoginFilter] = useState("createdAt");
  const { users, loadingUsers } = useStoreState(state => state.users);

  const { fetchUsers } = useStoreActions(actions => actions.users);

  const { companies, loadingCompanies } = useStoreState(
    state => state.companies
  );
  const fetchCompaniesList = useStoreActions(
    actions => actions.companies.fetchCompaniesList
  );

  useEffect(() => {
    fetchUsers({ orderBy: "createdAt" });
  }, [fetchUsers]);

  // get companies from database and store it in global store
  useEffect(() => {
    if (companies.length === 0) fetchCompaniesList();
  }, [fetchCompaniesList, companies]);

  useEffect(() => {
    const newBrands = extractBrands(companies);
    setBrands(newBrands);
  }, [companies]);

  const handleCompanyChange = value => {
    setCompany(value);
    if (value === "all") {
      const newBrands = extractBrands(companies);
      setBrands(newBrands);
    } else {
      const companyBrands = companies.filter(company => company.name === value);
      console.log(companyBrands);
      if (companyBrands[0].hasOwnProperty("brands")) {
        const newBrands = companyBrands[0].brands.map(brand => brand.name);
        setBrands(newBrands);
      } else {
        setBrands([]);
      }
    }
    setSelectedBrand("all");
  };

  const handleFilter = e => {
    e.preventDefault();
    fetchUsers({ orderBy: lastLoginFilter, company, brand: selectedBrand });
  };

  const handleLastSessionFilter = checked => {
    const islastLoginCheck = checked ? "lastSession" : "createdAt";
    setLastLoginFilter(islastLoginCheck);
    fetchUsers({ orderBy: islastLoginCheck, company, brand: selectedBrand });
  };

  return (
    <div className='pageContainer'>
      <Row gutter={16}>
        <Form onSubmit={handleFilter}>
          <Col span={8}>
            <Form.Item label='Filtrar por Empresa'>
              <Select
                showSearch
                value={company}
                disabled={loadingCompanies}
                loading={loadingCompanies}
                placeholder='Escoge una empresa'
                optionFilterProp='children'
                onChange={value => handleCompanyChange(value)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value='all'>Todas</Option>
                {companies.map(company => (
                  <Option key={company.id} value={company.name}>
                    {company.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Filtrar por Marca'>
              <Select
                value={selectedBrand}
                showSearch
                disabled={loadingCompanies}
                loading={loadingCompanies}
                placeholder='Escoge una marca'
                optionFilterProp='children'
                onChange={value => setSelectedBrand(value)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value='all'>Todas</Option>
                {brands.map(brand => (
                  <Option key={brand} value={brand}>
                    {brand}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Filtrar'>
              <Button type='primary' htmlType='submit'>
                filtrar
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Row>
      <Row>
        <Col>
          <small>Ordenar por ultimo inicio de sesion:</small>{" "}
          <Switch onChange={checked => handleLastSessionFilter(checked)} />
        </Col>
      </Row>
      <div className='usersCardsContainer'>
        {loadingUsers ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        ) : (
          <>
            {users.length === 0 && (
              <div style={{ margin: "auto" }}>
                <h2>
                  No encontramos usuarios <Icon type='frown' />
                </h2>
              </div>
            )}
            {users.map(user => (
              <UserCard
                key={user.id}
                user={user}
                lastQuery={{
                  brand: selectedBrand,
                  company,
                  orderBy: lastLoginFilter
                }}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default AllUsers;
