import React, { useState, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Form, Input, Select, Button, Checkbox, message } from "antd";
import EditUserSuccess from "./EditUserSuccess";
import userErrorMessage from "../../utils/userErrorMessages";
const { Option } = Select;

const EditUserForm = ({ user, setEditMode, lastQuery }) => {
  const [company, setCompany] = useState(user.company);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [brands, setBrands] = useState([]);
  const [email, setEmail] = useState(user.email);
  const [name, setName] = useState(user.name || "");
  const [phone, setPhone] = useState(user.phone || "");
  const [address, setAddress] = useState(user.address || "");
  const [editEmail, setEditEmail] = useState(false);
  const { companies, loadingCompanies } = useStoreState(
    state => state.companies
  );
  const { loading, error, success } = useStoreState(
    state => state.users.updateUserState
  );
  const {
    updateUser,
    updateUserWithEmail,
    setUpdateUserState
  } = useStoreActions(actions => actions.users);
  //if error show error message
  useEffect(() => {
    if (error)
      message.error(userErrorMessage(error), 3, () =>
        setUpdateUserState({
          loading: false,
          error: "",
          success: false
        })
      );
  }, [error, setUpdateUserState]);

  //set the list of brands for each single company change
  useEffect(() => {
    const currentCompany = companies.filter(comp => comp.name === company);
    if (currentCompany.length > 0) {
      const brands = currentCompany[0].brands;
      if (brands && brands.length > 0 && brands[0].name) {
        setBrands(brands);
        setSelectedBrands([]);
      } else {
        setBrands([]);
        setSelectedBrands([]);
      }
    }
  }, [company, companies]);

  useEffect(() => {
    setSelectedBrands(user.brands);
  }, [user.brands]);

  const handleCompanyChange = value => {
    setCompany(value);
    console.log(value);
  };

  //change brands list from string to object
  const handleSelectBrands = values => {
    const newSelectedBrands = values.map(value => JSON.parse(value));
    setSelectedBrands(newSelectedBrands);
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (editEmail && user.email !== email) {
      let newUserFields = {};
      if (user.isAdmin) {
        newUserFields = {
          name,
          email,
          updatedAt: Date.now()
        };
      } else {
        const brandsNames = selectedBrands.map(brand => brand.name);
        const brandsIds = selectedBrands.map(brand => brand.id);
        const companyId = companies.filter(comp => comp.name === company)[0].id;
        newUserFields = {
          name,
          email,
          companyId,
          company,
          phone,
          address,
          brands: selectedBrands,
          brandsNames,
          brandsIds,
          updatedAt: Date.now()
        };
      }
      updateUserWithEmail({
        oldEmail: user.email,
        userFieldsToUpdate: newUserFields
      });
    } else {
      let newUserFields = {};
      if (user.isAdmin) {
        newUserFields = {
          name,
          updatedAt: Date.now()
        };
      } else {
        const brandsNames = selectedBrands.map(brand => brand.name);
        const brandsIds = selectedBrands.map(brand => brand.id);
        const companyId = companies.filter(comp => comp.name === company)[0].id;
        newUserFields = {
          name,
          phone,
          address,
          companyId,
          company,
          brands: selectedBrands,
          brandsNames,
          brandsIds,
          updatedAt: Date.now()
        };
      }
      updateUser({ docId: user.id, userFieldsToUpdate: newUserFields });
    }
  };
  return (
    <div className='editUserFormContainer'>
      {success ? (
        <EditUserSuccess setEditMode={setEditMode} lastQuery={lastQuery} />
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Item label='Nombre'>
            <Input value={name} onChange={e => setName(e.target.value)} />
          </Form.Item>
          <Form.Item label='Email'>
            <Input
              value={email}
              onChange={e => setEmail(e.target.value)}
              disabled={!editEmail}
            />
            <small>¿Editar email?</small>{" "}
            <Checkbox onChange={e => setEditEmail(e.target.checked)} />
          </Form.Item>
          {!user.isAdmin && (
            <>
              <Form.Item label='Telefono'>
                <Input
                  placeholder='Telefono del usuario'
                  type='text'
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </Form.Item>
              <Form.Item label='Direccion'>
                <Input
                  placeholder='Dirección de correspondencia'
                  type='text'
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                />
              </Form.Item>
              <Form.Item label='Empresa'>
                <Select
                  showSearch
                  value={company}
                  disabled={loadingCompanies}
                  loading={loadingCompanies}
                  placeholder='Escoge una empresa'
                  optionFilterProp='children'
                  onChange={value => handleCompanyChange(value)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {companies.map(company => (
                    <Option key={company.id} value={company.name}>
                      {company.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Marcas'>
                <Select
                  mode='multiple'
                  // defaultValue={user.brands.map(brand => JSON.stringify(brand))}
                  value={selectedBrands.map(brand => JSON.stringify(brand))}
                  showArrow
                  placeholder='Escoge las marcas'
                  optionFilterProp='children'
                  onChange={values => handleSelectBrands(values)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {brands.map(brand => (
                    <Option key={brand.id} value={JSON.stringify(brand)}>
                      {brand.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          <div style={{ marginTop: 20 }}>
            <Form.Item>
              <Button type='primary' htmlType='submit' loading={loading}>
                Guardar
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  );
};

export default EditUserForm;
