import React, { useState, useEffect } from "react";
import KeepmePointsProductCard from "./KeepmePointsProductCard";
import uuid from "uuid/v4";
import { db, fieldValue } from "../../firebase.config";
import { Icon, message, Form, Row, Col, Button, Input, Divider } from "antd";
import KeepmePointsCategoryCard from "./KeepmePointsCategoryCard";
import "./keepmepoints.css";

const KeepmePointsCatalog = () => {
  const [elementsList, setElementsList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [loadingCreateCategory, setLoadingCreateCategory] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchElements = async () => {
    const elementsRef = db.collection("pointsCatalog");
    try {
      const elementsDocs = await elementsRef.get();
      if (elementsDocs.docs.length > 0) {
        const elementsData = elementsDocs.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setElementsList(elementsData);
      }
    } catch (e) {
      message.error("No pudimos cargar la lista de KeepmePoints");
    }
    setLoading(false);
  };

  const handleCreateCategory = async e => {
    e.preventDefault();
    if (!categoryName)
      return message.error("El nombre de la categoria es obligatorio");
    setLoadingCreateCategory(true);
    const categoriesRef = db.collection("categories").doc("keepmepoints");
    const id = uuid();
    const categoryNameTrimed = categoryName.trim();
    try {
      await db.runTransaction(async t => {
        const categoriesDoc = await t.get(categoriesRef);
        const categories = categoriesDoc.data();
        if (
          categoriesDoc.exists &&
          categories.names.includes(categoryNameTrimed)
        ) {
          let errorMessage = {
            code: "La categoria ya existe",
            message: "La categoria ya existe"
          };
          throw errorMessage;
        }

        t.set(
          categoriesRef,
          {
            categories: fieldValue.arrayUnion({
              id,
              name: categoryNameTrimed,
              createdAt: Date.now()
            }),
            names: fieldValue.arrayUnion(categoryNameTrimed),
            [id]: 0
          },
          { merge: true }
        );
      });

      setCategoryName("");
    } catch (e) {
      console.log(e.message);
      message.error(e.code || "No pudimos crear la categoria");
    }
    setLoadingCreateCategory(false);
  };

  useEffect(() => {
    const unsubscribeToCategories = db
      .collection("categories")
      .doc("keepmepoints")
      .onSnapshot(doc => {
        const categoriesData = doc.data();
        if (categoriesData.categories && categoriesData.categories.length > 0) {
          const categoriesWithCount = categoriesData.categories.map(
            category => ({ ...category, count: categoriesData[category.id] })
          );
          setCategories(categoriesWithCount);
        }
      });
    fetchElements();

    return () => unsubscribeToCategories();
  }, []);

  return (
    <div className='pageContainer'>
      <h3>Categorias KeepmePoints</h3>
      <Form onSubmit={handleCreateCategory}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item>
              <Input
                placeholder='Nombre de la categoria'
                value={categoryName}
                onChange={e => setCategoryName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button
                htmlType='submit'
                type='primary'
                loading={loadingCreateCategory}
              >
                Crear categoria
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className='categoriesListContainer'>
        {loading ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        ) : (
          <>
            {categories &&
              categories.map(category => (
                <KeepmePointsCategoryCard
                  key={category.name}
                  category={category}
                />
              ))}
          </>
        )}
      </div>
      <Divider />
      <h3>Elementos KeepmePoints</h3>
      <div className='keepmepointsList'>
        {loading ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        ) : (
          <>
            {elementsList.length === 0 && (
              <div style={{ margin: "auto" }}>
                <h2>
                  No encontramos elementos <Icon type='frown' />
                </h2>
              </div>
            )}
            {elementsList.map(element => (
              <KeepmePointsProductCard key={element.id} element={element} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default KeepmePointsCatalog;
