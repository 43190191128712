import { action, thunk } from "easy-peasy";

export default {
  users: [],
  emptyUsers: false,
  loadingUsers: true,
  createUserState: {
    loading: false,
    error: "",
    success: false,
    newUser: {
      email: "",
      password: ""
    }
  },
  updateUserState: {
    loading: false,
    error: "",
    success: false
  },

  deleteUserState: {
    loading: false,
    error: "",
    success: false
  },

  //thunks
  createUser: thunk(async (actions, payload, { injections }) => {
    actions.setCreateUserState({
      loading: true,
      error: "",
      success: false
    });
    const { functions } = injections;
    const createUser = functions.httpsCallable("createUser");
    const randomPassword = Math.random()
      .toString(36)
      .slice(-8);
    try {
      const response = await createUser({
        email: payload.email,
        password: randomPassword,
        isAdmin: payload.isAdmin,
        phone: payload.phone,
        address: payload.address,
        name: payload.name,
        brands: payload.brands,
        brandsNames: payload.brandsNames,
        company: payload.company,
        companyId: payload.companyId,
        companyCreatedAt: payload.companyCreatedAt
      });
      if (response.data.status === "error") {
        actions.setCreateUserState({
          loading: false,
          error: response.data.code || "Error inesperado",
          success: false,
          newUser: {
            email: "",
            password: ""
          }
        });
      } else if (response.data.status === "success") {
        actions.setCreateUserState({
          loading: false,
          error: "",
          success: true,
          newUser: {
            email: payload.email,
            password: randomPassword
          }
        });
      }
      console.log(response);
    } catch (e) {
      actions.setCreateUserState({
        loading: false,
        error: e.code,
        success: false,
        newUser: {
          email: "",
          password: ""
        }
      });
    }
  }),

  fetchUsers: thunk(async (actions, payload, { injections }) => {
    actions.setLoadingUsers(true);
    const { db } = injections;
    const orderBy = payload.orderBy || "createdAt";
    const brand = payload.brand || "all";
    const company = payload.company || "all";
    let queryRef = null;
    if (brand !== "all") {
      queryRef = db
        .collection("users")
        .where("brandsNames", "array-contains", brand)
        .orderBy(orderBy || "createdAt", "desc")
        .limit(50);
    } else if (company !== "all") {
      queryRef = db
        .collection("users")
        .where("company", "==", company)
        .orderBy(orderBy || "createdAt", "desc")
        .limit(20);
    } else {
      queryRef = db
        .collection("users")
        .orderBy(orderBy, "desc")
        .limit(20);
    }
    try {
      const snapshot = await queryRef.get();
      const users = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      actions.setUsersList(users);
    } catch (e) {
      console.log(e.message);
    }
    actions.setLoadingUsers(false);
  }),

  updateUser: thunk(async (actions, payload, { injections }) => {
    actions.setUpdateUserState({
      loading: true,
      error: "",
      success: false
    });
    const { db } = injections;
    try {
      await db
        .collection("users")
        .doc(payload.docId)
        .update(payload.userFieldsToUpdate);
      actions.setUpdateUserState({
        loading: false,
        error: "",
        success: true
      });
    } catch (e) {
      console.log(e.message);
      actions.setUpdateUserState({
        loading: false,
        error: e.code,
        success: false
      });
    }
  }),
  updateUserWithEmail: thunk(async (actions, payload, { injections }) => {
    actions.setUpdateUserState({
      loading: true,
      error: "",
      success: false
    });
    const { functions } = injections;
    const updateUserWithEmail = functions.httpsCallable("updateUserWithEmail");
    try {
      const response = await updateUserWithEmail(payload);
      console.log(response);
      if (response.data.status === "error") {
        actions.setUpdateUserState({
          loading: false,
          error: response.data.code,
          success: false
        });
      } else {
        actions.setUpdateUserState({
          loading: false,
          error: "",
          success: true
        });
      }
    } catch (e) {
      actions.setUpdateUserState({
        loading: false,
        error: e.code,
        success: false
      });
    }
  }),

  deleteUser: thunk(async (actions, payload, { injections }) => {
    actions.setDeleteUserState({
      loading: true,
      error: "",
      success: false
    });
    const { functions } = injections;
    const deleteUser = functions.httpsCallable("deleteUser");
    try {
      const response = await deleteUser({
        email: payload.email,
        documentId: payload.id
      });
      if (response.data.status === "error") {
        actions.setDeleteUserState({
          loading: false,
          error: response.data.code,
          success: false
        });
      } else {
        actions.setDeleteUserState({
          loading: false,
          error: "",
          success: true
        });
        await actions.fetchUsersList({ orderBy: "createdAt" });
      }
      console.log(response);
    } catch (e) {
      actions.setDeleteUserState({
        loading: false,
        error: e.code,
        success: false
      });
      console.log(e);
    }
  }),
  //actions
  setUsersList: action((state, payload) => {
    state.users = payload;
  }),
  setCreateUserState: action((state, payload) => {
    state.createUserState = payload;
  }),
  setUpdateUserState: action((state, payload) => {
    state.updateUserState = payload;
  }),
  setDeleteUserState: action((state, payload) => {
    state.deleteUserState = payload;
  }),
  setLoadingUsers: action((state, payload) => {
    state.loadingUsers = payload;
  }),
  setEmptyUsers: action((state, payload) => {
    state.emptyUsers = payload;
  })
};
