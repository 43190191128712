import React from "react";
import { Icon, Divider, Button } from "antd";
const FinishOutputExtraElementCard = ({ element, removeElement }) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ margin: 10 }}>
          <div className='finishOutputElementImage'>
            {!element.mainImage && (
              <Icon type='pushpin' style={{ margin: "auto", fontSize: 40 }} />
            )}
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <h2>{element.name}</h2>
          <p>
            <strong>Unidades sacadas: </strong>
            {element.units}
          </p>
          <p>
            <strong>Precio unitario: </strong>
            {`$ ${element.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
          <p>
            <strong>Tienda: </strong>
            Brandex
          </p>

          <Divider />
          <p>
            <strong>
              Total:{" "}
              {`$ ${element.price * element.units}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}
            </strong>
          </p>
        </div>
        <div style={{ margin: 10 }}>
          <Button onClick={() => removeElement(element)}>Quitar</Button>
        </div>
      </div>
      <Divider />
    </>
  );
};

export default FinishOutputExtraElementCard;
