import React, { useEffect, useState } from "react";
import { Button, Icon } from "antd";
import { Link } from "react-router-dom";
import { db } from "../../firebase.config";
import SerieElementCard from "./SerieElementCard";

const SerieProfileElements = ({ serie }) => {
  const [elements, setElements] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (serie.brandId && serie.id) {
      const elementsRef = db
        .collection("brands")
        .doc(serie.brandId)
        .collection("series")
        .doc(serie.id)
        .collection("elements");
      const unsubscribeToElements = elementsRef.onSnapshot(querySnapshot => {
        let newElements = [];
        querySnapshot.forEach(doc => {
          newElements.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setElements(newElements);
      });
      setLoading(false);
      return () => unsubscribeToElements();
    } else {
      setLoading(false);
    }
  }, [serie]);
  return (
    <>
      <div className='serieProfileContainer'>
        <div style={{ display: "flex" }}>
          <div style={{ margin: 5 }}>
            <h2>Elementos:</h2>
          </div>
          <div style={{ margin: 5 }}>
            <Link to={`/series/crear-elemento/${serie.brandId}/${serie.id}`}>
              <Button type='primary'> Agregar elemento</Button>
            </Link>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {loading ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        ) : (
          <>
            {elements.length === 0 && (
              <div style={{ margin: "auto" }}>
                <h2>
                  No encontramos elementos para esta serie <Icon type='frown' />
                </h2>
              </div>
            )}
            {elements.map(element => (
              <SerieElementCard key={element.id} element={element} />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default SerieProfileElements;
