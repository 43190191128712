import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { AuthenticatedRoute } from "./AuthenticatedRoute";
import DashboardLayout from "./DashboardLayout";
import CreateUser from "../users/CreateUser";
import AllUsers from "../users/AllUsers";
import CreateBrand from "../brands/CreateBrand";
import BrandProfile from "../brands/BrandProfile";
import AllBrands from "../brands/AllBrands";
import Notifications from "../notifications/Notifications";
import Companies from "../companies/Companies";
import Login from "../login/Login";
import ComplementsCategories from "../complements/ComplementsCategories";
import ComplementsElements from "../complements/ComplementsElements";
import CreateComplementElement from "../complements/CreateComplementElement";
import ComplementProfile from "../complements/ComplementProfile";
import CreateBrandTags from "../brands/CreateBrandTags";
import AllSeries from "../series/AllSeries";
import CreateSerie from "../series/CreateSerie";
import CreateSerieElement from "../series/CreateSerieElement";
import SerieProfile from "../series/SerieProfile";
import SerieElementProfile from "../series/SerieElementProfile";
import Inventories from "../inventories/Inventories";
import Inventory from "../inventories/Inventory";
import CreateInventoryElement from "../inventories/CreateInventoryElement";
import InventoryElementProfile from "../inventories/InventoryElementProfile";
import InventoryCategories from "../inventories/InventoryCategories";
import Outputs from "../outputs/Outputs";
import OutputProfile from "../outputs/OutputProfile";
import UnfinishedOutputs from "../outputs/UnfinishedOutputs";
import FinishOutput from "../outputs/FinishOutput";
import AditionalServices from "../outputs/AditionalServices";
import CreateEvent from "../events/CreateEvent";
import Events from "../events/Events";
import KeepmePointsRedemptions from "../keepmepoints/KeepmePointsRedemptions";
import KeepmePointsCatalog from "../keepmepoints/KeepmePointsCatalog";
import KeepmePointsElementProfile from "../keepmepoints/KeepmePointsElementProfile";
import Settings from "../settings/Settings";
import "./app.css";
import CreateKeepmePointsElement from "../keepmepoints/CreateKeepmePointsElement";

const App = ({ admin, auth, documentId }) => {
  const isAuthenticated = useStoreState(state => state.user.isAuthenticated);
  const isAdmin = useStoreState(state => state.user.isAdmin);
  const {
    setIsAdmin,
    setIsAuthenticated,
    setDocumentId,
    fetchCurrentUser
  } = useStoreActions(actions => actions.user);

  useEffect(() => {
    setIsAdmin(admin);
    setIsAuthenticated(auth);
    setDocumentId(documentId);
    fetchCurrentUser(documentId);
    const loader = document.getElementById("ipl-progress-indicator");
    if (loader) loader.outerHTML = "";
  }, [
    admin,
    auth,
    setIsAdmin,
    setIsAuthenticated,
    documentId,
    setDocumentId,
    fetchCurrentUser
  ]);

  return (
    <DashboardLayout isAdmin={admin}>
      <Switch>
        <AuthenticatedRoute
          path="/"
          exact
          component={() => <Notifications />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Home"
        />
        <AuthenticatedRoute
          path="/crear-usuario"
          exact
          component={() => <CreateUser />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Crear Usuario"
        />
        <AuthenticatedRoute
          path="/usuarios"
          exact
          component={() => <AllUsers />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Usuarios"
        />
        <AuthenticatedRoute
          path="/empresas"
          exact
          component={() => <Companies />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Empresas"
        />
        <AuthenticatedRoute
          path="/marcas/crear-marca"
          exact
          component={() => <CreateBrand />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Crear marca"
        />
        <AuthenticatedRoute
          path="/marca/:brandId"
          exact
          component={() => <BrandProfile />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Perfil de marca"
        />
        <AuthenticatedRoute
          path="/marcas"
          exact
          component={() => <AllBrands />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Todas las marcas"
        />
        <AuthenticatedRoute
          path="/marcas/atributos-audiencia"
          exact
          component={() => <CreateBrandTags />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Atributos y audiencia"
        />
        <AuthenticatedRoute
          path="/complementos/categorias"
          exact
          component={() => <ComplementsCategories />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Categorias de complementos"
        />
        <AuthenticatedRoute
          path="/complementos/elementos"
          exact
          component={() => <ComplementsElements />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Complementos"
        />
        <AuthenticatedRoute
          path="/complementos/elementos/:elementId"
          exact
          component={() => <ComplementProfile />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Perfil de complemento"
        />
        <AuthenticatedRoute
          path="/complementos/crear-elemento"
          exact
          component={() => <CreateComplementElement />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Crear complemento"
        />

        <AuthenticatedRoute
          path="/series"
          exact
          component={() => <AllSeries />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Series"
        />
        <AuthenticatedRoute
          path="/series/crear-serie"
          exact
          component={() => <CreateSerie />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Crear serie"
        />
        <AuthenticatedRoute
          path="/series/crear-elemento/:brandId/:serieId"
          exact
          component={() => <CreateSerieElement />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Crear elemento de serie"
        />
        <AuthenticatedRoute
          path="/serie/:brandId/:serieId"
          exact
          component={() => <SerieProfile />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Perfil de serie"
        />
        <AuthenticatedRoute
          path="/serie/elemento/:brandId/:serieId/:elementId"
          exact
          component={() => <SerieElementProfile />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Perfil de elemento de serie"
        />
        <AuthenticatedRoute
          path="/inventarios"
          exact
          component={() => <Inventories />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Inventarios"
        />
        <AuthenticatedRoute
          path="/inventario/:brandId"
          exact
          component={() => <Inventory />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Inventario de marca"
        />
        <AuthenticatedRoute
          path="/inventario/crear-elemento/:brandId"
          exact
          component={() => <CreateInventoryElement />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Crear elemento de inventario"
        />
        <AuthenticatedRoute
          path="/inventario/elemento/:brandId/:elementId"
          exact
          component={() => <InventoryElementProfile />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Perfil de elemento de inventario"
        />
        <AuthenticatedRoute
          path="/inventarios/categorias"
          exact
          component={() => <InventoryCategories />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Categorias de inventario"
        />
        <AuthenticatedRoute
          path="/salidas"
          exact
          component={() => <Outputs />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Salidas"
        />
        <AuthenticatedRoute
          path="/salida/:brandId/:outputId"
          exact
          component={() => <OutputProfile />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Salida"
        />
        <AuthenticatedRoute
          path="/completar-salida"
          exact
          component={() => <UnfinishedOutputs />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Completar salidas"
        />

        <AuthenticatedRoute
          path="/completar-salida/:brandId/:outputId"
          exact
          component={() => <FinishOutput />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Completar salida"
        />
        <AuthenticatedRoute
          path="/servicios-adicionales"
          exact
          component={() => <AditionalServices />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Servicios Adicionales"
        />

        <AuthenticatedRoute
          path="/eventos"
          exact
          component={() => <Events />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Eventos"
        />

        <AuthenticatedRoute
          path="/crear-evento"
          exact
          component={() => <CreateEvent />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Crear Evento"
        />

        <AuthenticatedRoute
          path="/catalogo-keepmepoints"
          exact
          component={() => <KeepmePointsCatalog />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Catalogo KeepmePoints"
        />
        <AuthenticatedRoute
          path="/catalogo-keepmepoints/:elementId"
          exact
          component={() => <KeepmePointsElementProfile />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Catalogo KeepmePoints"
        />

        <AuthenticatedRoute
          path="/redenciones-keepmepoints"
          exact
          component={() => <KeepmePointsRedemptions />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Redenciones KeepmePoints"
        />

        <AuthenticatedRoute
          path="/crear-elemento-keepmepoints"
          exact
          component={() => <CreateKeepmePointsElement />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Crear elemento KeepmePoints"
        />

        <AuthenticatedRoute
          path="/configuraciones"
          exact
          component={() => <Settings />}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          title="Configuraciones"
        />

        <Route
          path="/login"
          exact
          render={props => (
            <Login
              {...props}
              isAuthenticated={isAuthenticated}
              isAdmin={isAdmin}
            />
          )}
        />
      </Switch>
    </DashboardLayout>
  );
};

export default App;
