import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu, Icon, Typography, Badge } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import { db } from "../../firebase.config";
import keepmeLogo from "../../assets/images/logo-keepme-principal.svg";
const { Title } = Typography;
const { Header, Sider } = Layout;
const { SubMenu } = Menu;

const DashboardLayout = ({ children, isAdmin, match }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [count, setCount] = useState(0);
  const pageTitle = useStoreState(state => state.user.pageTitle);
  const logOutUser = useStoreActions(actions => actions.user.logOutUser);
  const { setNotifications } = useStoreActions(
    actions => actions.notifications
  );

  useEffect(() => {
    if (isAdmin) {
      const unsubscribeToNotifications = db
        .collection("notifications")
        .where("completed", "==", false)
        .orderBy("createdAt", "desc")
        .onSnapshot(querySnapshot => {
          let newNotifications = [];
          querySnapshot.forEach(doc => {
            newNotifications.push({ id: doc.id, ...doc.data() });
          });
          setNotifications(newNotifications);
          setCount(newNotifications.length);
          console.log("called fetch notifications");
        });

      return () => unsubscribeToNotifications();
    }
  }, [isAdmin, setNotifications]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0
        // behavior: "smooth"
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [match.params, match.path]);

  if (isAdmin) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={value => setCollapsed(value)}
        >
          <div className="logoContainer">
            <img className="logo" src={keepmeLogo} alt="keepme-logo" />
          </div>
          <Menu theme="dark" selectedKeys={[pageTitle]} mode="inline">
            <Menu.Item key="Home">
              <Link to="/">
                <Icon type="notification" />
                <Badge offset={[15, 2]} count={count}>
                  <span>Notificaciones</span>
                </Badge>
              </Link>
            </Menu.Item>

            <Menu.Item key="Empresas">
              <Link to="/empresas">
                <Icon type="shop" />
                <span>Empresas</span>
              </Link>
            </Menu.Item>
            <SubMenu
              key="sub12"
              title={
                <span>
                  <Icon type="crown" />
                  <span>Marcas</span>
                </span>
              }
            >
              <Menu.Item key="Todas las marcas">
                <Link to="/marcas">Ver todas</Link>
              </Menu.Item>
              <Menu.Item key="Crear marca">
                <Link to="/marcas/crear-marca">Crear marca</Link>
              </Menu.Item>
              <Menu.Item key="Atributos y audiencia">
                <Link to="/marcas/atributos-audiencia">
                  Atributos y audiencia
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="user"
              title={
                <span>
                  <Icon type="user" />
                  <span>Usarios</span>
                </span>
              }
            >
              <Menu.Item key="Usuarios">
                <Link to="/usuarios">Ver todos</Link>
              </Menu.Item>
              <Menu.Item key="Crear Usuario">
                <Link to="/crear-usuario">Crear usuario</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="complementos"
              title={
                <span>
                  <Icon type="rocket" />
                  <span>Complementos</span>
                </span>
              }
            >
              <Menu.Item key="Categorias de complementos">
                <Link to="/complementos/categorias">Categorias</Link>
              </Menu.Item>
              <Menu.Item key="Complementos">
                <Link to="/complementos/elementos">Elementos</Link>
              </Menu.Item>
              <Menu.Item key="Crear complemento">
                <Link to="/complementos/crear-elemento">Crear elemento</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="Series"
              title={
                <span>
                  <Icon type="build" />
                  <span>Series</span>
                </span>
              }
            >
              <Menu.Item key="Series">
                <Link to="/series">Ver series</Link>
              </Menu.Item>
              <Menu.Item key="Crear serie">
                <Link to="/series/crear-serie">Crear serie</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="inventario"
              title={
                <span>
                  <Icon type="appstore" />
                  <span>Inventarios</span>
                </span>
              }
            >
              <Menu.Item key="Inventarios">
                <Link to="/inventarios">Ver inventarios</Link>
              </Menu.Item>
              <Menu.Item key="Categorias de inventario">
                <Link to="/inventarios/categorias">Categorias</Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="salidas"
              title={
                <span>
                  <Icon type="carry-out" />
                  <span>Salidas</span>
                </span>
              }
            >
              <Menu.Item key="Salidas">
                <Link to="/salidas">
                  <span>Ver salidas</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="Completar salidas">
                <Link to="/completar-salida">
                  <span>Completar salida</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="Servicios Adicionales">
                <Link to="/servicios-adicionales">
                  <span>Servicios adicionales</span>
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="eventos"
              title={
                <span>
                  <Icon type="calendar" />
                  <span>Eventos</span>
                </span>
              }
            >
              <Menu.Item key="Eventos">
                <Link to="/eventos">
                  <span>Ver eventos</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="Crear Evento">
                <Link to="/crear-evento">
                  <span>Crear evento</span>
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="keepmepoints"
              title={
                <span>
                  <Icon type="star" />
                  <span>KeepmePoints</span>
                </span>
              }
            >
              <Menu.Item key="Redenciones KeepmePoints">
                <Link to="/redenciones-keepmepoints">
                  <span>Redenciones</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="Catalogo KeepmePoints">
                <Link to="/catalogo-keepmepoints">
                  <span>Catalogo</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="Crear elemento KeepmePoints">
                <Link to="/crear-elemento-keepmepoints">
                  <span>Crear elemento</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="Configuraciones">
              <Link to="/configuraciones">
                <Icon type="setting" />
                <span>Configuraciones</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="logout" onClick={() => logOutUser()}>
              <Icon type="logout" />
              <span>Salir</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header
            style={{
              background: "#000",
              padding: 5,
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
            }}
          >
            <Title level={2} style={{ color: "#f3f2f1" }}>
              {pageTitle}
            </Title>
          </Header>
          {children}
        </Layout>
      </Layout>
    );
  } else {
    return <>{children}</>;
  }
};

export default withRouter(DashboardLayout);
