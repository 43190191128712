export default error => {
  switch (error) {
    case "cancelled":
      return "Operacion cancelada";
    case "unknown":
      return "¡Upss! ocurrio un error inesperado. Refresca la pagina y vuelve a intentar";
    case "invalid-argument":
      return "Uno o mas de los campos provistos estan mal formados";
    case "deadline-exceeded":
      return "Tiempo de respuesta excedido. Actualiza y verifica que todo esta correcto";
    case "not-found":
      return "Recurso no encontrado";
    case "already-exists":
      return "El recurso ya existe";
    case "permission-denied":
      return "No tienes permiso para realizar esta operacion";
    case "resource-exhausted":
      return "Se cumplió la cuota del servidor";
    case "failed-precondition":
      return "Falló la solicitud, intenta de nuevo";
    case "aborted":
      return "Se abortó la solicitud";
    case "out-of-range":
      return "Operacion por fuera del rango permitido";
    case "unimplemented":
      return "Operacion no activada o soportada";
    case "internal":
      return "Upps error desconcido, intenta de nuevo";
    case "unavailable":
      return "Servicio temporalmente no disponible";
    case "data-loss":
      return "La informacion se ha perdido";
    case "unauthenticated":
      return "No estas autenticado";
    case "brands-still-in-company":
      return "Esta empresa todavia tiene marcas asignadas";
    case "users-still-in-company":
      return "Esta empresa todavia tiene usuarios asignados";
    default:
      return error;
  }
};
