import React from "react";
import { useStoreActions } from "easy-peasy";
import { Icon, Button } from "antd";

const EditUserSuccess = ({ setEditMode, lastQuery }) => {
  const { setUpdateUserState, fetchUsers } = useStoreActions(
    actions => actions.users
  );

  const resetUserState = () => {
    setUpdateUserState({
      loading: false,
      error: "",
      success: false
    });
    fetchUsers({
      orderBy: lastQuery.orderBy,
      brand: lastQuery.brand,
      company: lastQuery.company
    });
    setEditMode(false);
  };
  return (
    <div style={{ display: "flex" }}>
      <div style={{ margin: "auto", textAlign: "center" }}>
        <Icon
          style={{ fontSize: 60 }}
          theme='twoTone'
          twoToneColor='#52c41a'
          type='smile'
        />
        <h3 style={{ marginTop: 10 }}>¡Bien hecho! Has editado el usuario</h3>
        <p>
          Haz click en el siguiente boton para actualizar la lista de usuarios
        </p>
        <Button type='primary' onClick={() => resetUserState()}>
          Gracias, soy lo mejor
        </Button>
      </div>
    </div>
  );
};

export default EditUserSuccess;
