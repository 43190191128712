import React from "react";
import { Icon, Button, Tag } from "antd";
import { Link } from "react-router-dom";
const SerieCard = ({ serie }) => {
  return (
    <div className='serieCard'>
      {/* <div style={{ height: 300 }}>
        <Img
          src={serie.mainImage}
          loader={
            <div style={{ display: "flex", height: 300 }}>
              <Icon style={{ margin: "auto" }} type='loading' />
            </div>
          }
          unloader={<Icon type='picture' />}
          alt='Serie'
          className='serieCardImage'
        />
      </div> */}
      <div style={{ height: 300, display: "flex" }}>
        <div
          className='elementCardImage'
          style={{
            backgroundImage: `url(${serie.mainImage || null})`,
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}
        >
          {serie && !serie.mainImage && (
            <Icon type='picture' style={{ margin: "auto", fontSize: 20 }} />
          )}
        </div>
      </div>
      <div style={{ padding: 15 }}>
        <h2>{serie.name}</h2>
        <div style={{ marginBottom: 10 }}>
          <Tag color={serie.brandColor}>{serie.brandName}</Tag>
        </div>
        <Link to={`/serie/${serie.brandId}/${serie.id}`}>
          <Button type='primary' block>
            Ver serie
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default SerieCard;
