import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Input,
  Row,
  Col,
  InputNumber,
  Typography,
  Select,
  message
} from "antd";
import { withRouter } from "react-router-dom";
import { db, fieldValue } from "../../firebase.config";
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const CreateKeepmePointsElement = ({ history }) => {
  const [name, setName] = useState("Nuevo elemento KeepmePoints");
  const [units, setUnits] = useState(0);
  const [points, setPoints] = useState(undefined);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribeToCategories = db
      .collection("categories")
      .doc("keepmepoints")
      .onSnapshot(doc => {
        const categoriesData = doc.data();
        setCategories(categoriesData.categories);
      });
    return () => unsubscribeToCategories();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    //some validation of fields
    if (!name) return message.error("El elemento debe tener nombre");
    if (!units)
      return message.error("El elemento debe tener cantidades disponibles");
    if (!points) return message.error("El elemento debe tener puntos");
    if (!category)
      return message.error("El elemento debe tener asignada una categoria");
    if (!description)
      return message.error("El elemento debe tener una descripcion");
    const categoryId = categories.filter(cat => cat.name === category)[0].id;
    const batch = db.batch();
    setLoading(true);
    try {
      const keepmePointsRef = db.collection("pointsCatalog").doc();
      const categoriesRef = db.collection("categories").doc("keepmepoints");
      batch.set(keepmePointsRef, {
        name,
        units,
        points,
        description,
        category,
        categoryId,
        isPublic: false,
        createdAt: Date.now()
      });
      batch.update(categoriesRef, {
        [categoryId]: fieldValue.increment(1)
      });
      await batch.commit();
      history.push(`/catalogo-keepmepoints/${keepmePointsRef.id}`);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
      message.error("No pudimos crear el elemento de Keepme Points");
    }
  };
  return (
    <div className='pageContainer'>
      <h1>Crear elemento KeepmePoints</h1>
      <Title>{name}</Title>
      <Form onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Nombre'>
              <Input
                placeholder='Nombre del elemento'
                onChange={e => setName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Cantidades disponibles'>
              <InputNumber
                onChange={value => setUnits(value)}
                placeholder='Unidades'
                style={{ width: "100%" }}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Descripcion'>
              <TextArea
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder='Agrega una breve descripcion de no mas de 140 caracteres, como un Tweet'
                autosize={{ minRows: 4, maxRows: 10 }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Categoria'>
              <Select
                placeholder='Escoge una categoria'
                onChange={value => setCategory(value)}
              >
                {categories &&
                  categories.map(category => (
                    <Option key={category.id} value={category.name}>
                      {category.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label='Puntos'>
              <InputNumber
                onChange={value => setPoints(value)}
                placeholder='¿Cuantos puntos vale?'
                value={points}
                style={{ width: "100%" }}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={loading}>
            Crear elemento
          </Button>
          <div>
            <small>Luego de que lo crees, puedes agregarle imagenes</small>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default withRouter(CreateKeepmePointsElement);
