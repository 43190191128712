import { action } from "easy-peasy";

export default {
  notifications: [],

  //thunks
  // listenToNotifications: thunk((actions, payload, { injections }) => {
  //   const { db } = injections;

  //   const unsubscribeToNotifications = db
  //     .collection("notifications")
  //     .where("completed", "==", false)
  //     .orderBy("createdAt", "desc")
  //     .onSnapshot(querySnapshot => {
  //       let newNotifications = [];
  //       querySnapshot.forEach(doc => {
  //         newNotifications.push({ id: doc.id, ...doc.data() });
  //       });
  //       actions.setNotifications(newNotifications);
  //       actions.setLoadingNotifications(false);
  //     });
  //   console.log("called notifications");

  //   if (payload.unsubscribe === true) unsubscribeToNotifications();
  // }),
  //Actions
  setNotifications: action((state, payload) => {
    state.notifications = payload;
  })
};
