import React from 'react';
import { Icon } from 'antd';
import Img from 'react-image';

const BrandFontPreview = ({ imageUrl }) => {
  return (
    // <div style={{ margin: 10 }}>
    //   <div
    //     className='brandFontPreviewImage'
    //     style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: "cover" }}
    //   />
    // </div>
    <>
      <div
        style={{
          width: '100%',
          maxWidth: 450,
          height: 200,
          display: 'flex',
          backgroundColor: '#f4f4f4',
          border: '1px dashed #222',
          borderRadius: 15
        }}>
        <Img
          src={imageUrl}
          loader={
            <div
              style={{
                minWidth: '100%',
                minHeight: '100%',
                display: 'flex'
              }}>
              <Icon style={{ margin: 'auto' }} type='loading' />
            </div>
          }
          unloader={
            <div
              style={{
                minWidth: '100%',
                minHeight: '100%',
                display: 'flex'
              }}>
              <Icon style={{ margin: 'auto' }} type='picture' />
            </div>
          }
          alt='logo-preview'
          style={{ margin: 'auto', height: '100%' }}
        />
      </div>
      <small>Sugerencia 450px x 200px</small>
    </>
  );
};

export default BrandFontPreview;
