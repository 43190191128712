import React from "react";
import { Row, Col, List, Avatar, ConfigProvider, Empty } from "antd";
import formatMoney from "../../utils/formatMoney";

const priceTypesNames = {
  days: "por dia",
  event: "por evento"
};

const getPriceByCity = (service, city) => {
  if (service && city) {
    const priceIndex = service.prices.findIndex(price => price.city === city);
    const price = service.prices[priceIndex].price;
    return {
      text: formatMoney(price),
      value: price
    };
  } else {
    return {
      text: formatMoney(0),
      value: 0
    };
  }
};

const OutputProfileAditionalServices = ({ output }) => {
  return (
    <Row gutter={16}>
      <Col span={12}>
        <div className='outputProfileContainer'>
          <h2>Servicios adicionales:</h2>
          <ConfigProvider
            renderEmpty={() => (
              <Empty
                description='No hay servicios adicionales'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          >
            <List
              itemLayout='horizontal'
              dataSource={output.outputAditionalServices}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        style={{ margin: "auto" }}
                        size={30}
                        icon='star'
                      />
                    }
                    title={item.name}
                    description={
                      <>
                        <p>{item.description}</p>
                        <small>
                          Precio {priceTypesNames[item.priceType]}{" "}
                          {getPriceByCity(item, output.city).text}
                        </small>{" "}
                      </>
                    }
                  />
                  <p>
                    Valor total: <strong>{formatMoney(item.totalPrice)}</strong>{" "}
                  </p>
                </List.Item>
              )}
            />
          </ConfigProvider>
        </div>
      </Col>
      <Col span={12}>
        <div className='outputProfileContainer'>
          <h2>Reparaciones:</h2>
          <ConfigProvider
            renderEmpty={() => (
              <Empty
                description='No hay reparaciones'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          >
            <List
              itemLayout='horizontal'
              dataSource={output.outputFixes}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        style={{ margin: "auto" }}
                        size={30}
                        icon='tool'
                      />
                    }
                    title={item.name}
                    description={item.fixDescription}
                  />
                  <p>
                    Valor total: <strong>{formatMoney(item.fixPrice)}</strong>{" "}
                  </p>
                </List.Item>
              )}
            />
          </ConfigProvider>
        </div>
      </Col>
    </Row>
  );
};

export default OutputProfileAditionalServices;
