import React from "react";
import { Button, Icon } from "antd";
import { useStoreActions } from "easy-peasy";

const BrandProfileFormSuccess = ({ setEditMode, brandId, resetState }) => {
  const { getBrandById } = useStoreActions(actions => actions.brands);

  const resetBrandState = () => {
    setEditMode(false);
    getBrandById({ brandId });
    resetState({
      loading: false,
      error: "",
      success: false
    });
  };
  return (
    <div style={{ display: "flex" }}>
      <div style={{ margin: "auto", textAlign: "center" }}>
        <Icon
          style={{ fontSize: 60 }}
          theme='twoTone'
          twoToneColor='#52c41a'
          type='smile'
        />
        <h3 style={{ marginTop: 10 }}>¡Bien hecho! Has editado la marca</h3>
        <p>Haz click en el siguiente boton para actualizar</p>
        <Button type='primary' onClick={() => resetBrandState()}>
          Absurdo, actualicemos
        </Button>
      </div>
    </div>
  );
};

export default BrandProfileFormSuccess;
