import React from "react";
import { Avatar, Button, Divider, Tag } from "antd";
import { Link } from "react-router-dom";

const getElementsCount = brandObj => {
  if (
    !brandObj.inventoryCategories ||
    brandObj.inventoryCategories.length === 0
  ) {
    return 0;
  } else {
    let count = 0;
    const idsArray = brandObj.inventoryCategories.map(category => category.id);
    idsArray.forEach(id => {
      count += brandObj[id];
    });
    return count;
  }
};

const InventoryCard = ({ brand }) => {
  return (
    <div className='inventoryCard'>
      <Avatar
        style={{ margin: "auto", backgroundColor: brand.mainColor }}
        size={64}
        icon='appstore'
      />
      <div>
        <h1>Inventario</h1>
        <h2>{brand.name}</h2>
      </div>
      <small>elementos: </small>
      <div>
        <Tag color='#222'>{getElementsCount(brand)}</Tag>
      </div>
      <Divider />
      <Link to={`/inventario/${brand.id}`}>
        <Button type='primary' block>
          Ver elementos
        </Button>
      </Link>
    </div>
  );
};

export default InventoryCard;
