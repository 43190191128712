import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Button, message, Tag, Icon } from "antd";
import { db, fieldValue } from "../../firebase.config";

const AditionalServicesCities = ({ setCitiesList }) => {
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [loadingCreateCity, setLoadingCreateCity] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleCreateCity = async e => {
    e.preventDefault();
    setLoadingCreateCity(true);
    const citiesDocumentRef = db.collection("information").doc("cities");
    try {
      const citiesDocument = await citiesDocumentRef.get();

      if (citiesDocument.exists) {
        const citiesDocumentData = citiesDocument.data();
        if (
          citiesDocumentData &&
          citiesDocumentData.cities &&
          citiesDocumentData.cities.includes(city.trim())
        ) {
          setLoadingCreateCity(false);
          return message.error("Ya existe esta ciudad");
        }
      }
      await citiesDocumentRef.set(
        {
          cities: fieldValue.arrayUnion(city.trim())
        },
        { merge: true }
      );
      setCity("");
      setLoadingCreateCity(false);
    } catch (e) {
      console.log(e.message);
      message.error("Couldn't create city");
    }
  };

  const handleDeleteCity = async cityName => {
    const citiesDocumentRef = db.collection("information").doc("cities");
    try {
      await citiesDocumentRef.update({
        cities: fieldValue.arrayRemove(cityName.trim())
      });
      console.log("Ciudad " + cityName + " deleted");
    } catch (e) {
      console.log(e.message);
      message.error("Couldn't delete city");
    }
  };

  useEffect(() => {
    const unsubscribeToCities = db
      .collection("information")
      .doc("cities")
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          setCities(data.cities);
          setCitiesList(data.cities);
        }
        setLoading(false);
      });
    return () => unsubscribeToCities();
  }, [setCitiesList]);

  return (
    <>
      <div>
        <h1>Crear ciudad</h1>
        <Form onSubmit={handleCreateCity}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item>
                <Input
                  placeholder='Nombre de la ciudad'
                  value={city}
                  onChange={e => setCity(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  htmlType='submit'
                  type='primary'
                  loading={loadingCreateCity}
                >
                  Crear ciudad
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {loading ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        ) : (
          <>
            {cities.length === 0 && (
              <div style={{ margin: "auto" }}>
                <h2>
                  No encontramos ciudades <Icon type='frown' />
                </h2>
              </div>
            )}
            {cities.map(city => (
              <Tag
                style={{ marginTop: 10 }}
                color='#222'
                closable
                onClose={() => {
                  // e.preventDefault();
                  handleDeleteCity(city);
                }}
                key={city}
              >
                {city}
              </Tag>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default AditionalServicesCities;
