import React from "react";
import { Avatar, Tag, Button } from "antd";
import { withRouter } from "react-router-dom";
import formatTimestamp from "../../utils/formatTimestamp";

const BrandCard = ({ brand, history }) => {
  return (
    <div className="brandCard">
      <Avatar
        style={{ margin: "auto", backgroundColor: brand.mainColor }}
        size={64}
        icon="crown"
      />
      <h2>{brand.name}</h2>
      <small>creada en: {formatTimestamp(brand.createdAt)}</small>
      <div style={{ marginBottom: 20 }}>
        <Tag>{brand.company}</Tag>
      </div>
      <div style={{ marginBottom: 20 }}>
        Tipo de marca: <Tag color="green">{brand.brandType}</Tag>
      </div>
      <Button
        type="primary"
        block
        onClick={() => history.push(`/marca/${brand.id}`)}
      >
        Ver perfil de marca
      </Button>
    </div>
  );
};

export default withRouter(BrandCard);
