import React, { useEffect, useState } from "react";
import { Input, Icon, Row, Col, message, Form, Button } from "antd";
import { db } from "../../firebase.config";

const Settings = () => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [adminEmail, setAdminEmail] = useState("");
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const unsubscribeToSettings = db
      .collection("settings")
      .doc("general")
      .onSnapshot(doc => {
        setSettings({
          id: doc.id,
          ...doc.data()
        });
        setLoading(false);
      });

    return () => unsubscribeToSettings();
  }, []);

  useEffect(() => {
    if (settings.adminEmail) {
      setAdminEmail(settings.adminEmail);
    }
  }, [settings]);

  const updateAdminEmail = async e => {
    e.preventDefault();
    try {
      const settingsRef = db.collection("settings").doc("general");
      await settingsRef.set({ adminEmail: adminEmail.trim() }, { merge: true });
      setEditMode(false);
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos actualizar el correo");
    }
  };
  return (
    <div className='pageContainer'>
      <Row>
        <Col span={12}>
          {loading ? (
            <>
              <Icon type='loading' />
            </>
          ) : (
            <>
              <h3>
                Correo administrativo de notificaciones:{" "}
                <Icon type='edit' onClick={() => setEditMode(!editMode)} />
              </h3>

              {editMode ? (
                <Form onSubmit={updateAdminEmail}>
                  <Form.Item>
                    <Input
                      onChange={e => setAdminEmail(e.target.value)}
                      placeholder='Correo principal'
                      value={adminEmail}
                    />
                    <Button type='primary' htmlType='submit'>
                      Guardar
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <p> {adminEmail || "No hay correo administrativo aun"} </p>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
