import React, { useState } from "react";
import { Button } from "antd";
import { useStoreActions } from "easy-peasy";
import { storage } from "../../firebase.config";

const DeleteBrandFile = ({ path, id }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { fetchBrandFilesAssets, updateBrandDate } = useStoreActions(
    actions => actions.brands
  );
  const deleteFile = async () => {
    setDeleteLoading(true);
    const storageRef = storage.ref();
    const fileRef = storageRef.child(path);
    try {
      await fileRef.delete();
      setDeleteLoading(false);
      updateBrandDate(id);
      fetchBrandFilesAssets({ id });
    } catch (e) {
      console.log(e.message);
      setDeleteLoading(false);
    }
  };
  return (
    <Button
      loading={deleteLoading}
      type='danger'
      size='small'
      onClick={deleteFile}
    >
      Borrar
    </Button>
  );
};

export default DeleteBrandFile;
