import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Button, Radio, message } from "antd";
import { db } from "../../firebase.config";
import CityPriceInput from "./CityPriceInput";
const { TextArea } = Input;

const CreateAditionalServices = ({
  citiesList,
  currentService,
  setEditMode
}) => {
  const [loading, setLoading] = useState(false);
  const [priceType, setPriceType] = useState("event");
  const [serviceName, setServiceName] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [aditionalServiceCities, setAditionalServiceCities] = useState([]);
  const [
    aditionalServiceCitiesPrices,
    setAditionalServiceCitiesPrices
  ] = useState([]);

  useEffect(() => {
    if (currentService) {
      setServiceName(currentService.name || "");
      setPriceType(currentService.priceType || "event");
      setServiceDescription(currentService.description || "");
      setAditionalServiceCities(currentService.cities || citiesList);
      setAditionalServiceCitiesPrices(currentService.prices || []);
    } else {
      if (citiesList.length > 0) setAditionalServiceCities(citiesList);
    }
  }, [citiesList, currentService]);
  const handleCreateAditionalService = async e => {
    e.preventDefault();
    if (!priceType) return message.error("EL tipo de precio es obligatorio");
    if (!serviceName) return message.error("El nombre es obligatorio");
    if (!serviceDescription)
      return message.error("La descripcion es obligatoria");
    if (aditionalServiceCities.length === 0)
      return message.error("Debes habilitar al menos una ciudad");
    setLoading(true);
    const servicesPrices = aditionalServiceCities.map(city => {
      const cityPriceIndex = aditionalServiceCitiesPrices.findIndex(
        c => c.city === city
      );
      if (cityPriceIndex === -1) {
        return {
          city,
          price: 0
        };
      } else {
        return aditionalServiceCitiesPrices[cityPriceIndex];
      }
    });
    const newService = {
      name: serviceName,
      description: serviceDescription,
      cities: aditionalServiceCities,
      prices: servicesPrices,
      priceType
    };
    try {
      if (currentService) {
        const serviceRef = db
          .collection("aditionalServices")
          .doc(currentService.id);
        await serviceRef.update(newService);
        message.success("Hemos actualizado el servicio");
        setEditMode(false);
      } else {
        const serviceRef = db.collection("aditionalServices");
        await serviceRef.add(newService);
        setServiceName("");
        setPriceType("event");
        setServiceDescription("");
        message.success("Hemos creado el servicio");
      }
    } catch (e) {
      message.error("No pudimos crear el servicio");
      console.log(e.message);
    }
    setLoading(false);
    console.log(newService);
  };
  return (
    <div>
      <Form onSubmit={handleCreateAditionalService}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Nombre'>
              <Input
                value={serviceName}
                onChange={e => setServiceName(e.target.value)}
                placeholder='Nombre del servicio'
              />
            </Form.Item>

            <Form.Item label='Descripcion'>
              <TextArea
                value={serviceDescription}
                onChange={e => setServiceDescription(e.target.value)}
                placeholder='Descripcion del servicio'
              />
            </Form.Item>
            <Form.Item label='Tipo de precio'>
              <Radio.Group
                value={priceType}
                buttonStyle='solid'
                onChange={e => setPriceType(e.target.value)}
              >
                <Radio value='days'>Por dias</Radio>
                <Radio value='event'>Por evento</Radio>
              </Radio.Group>
            </Form.Item>
            <div>
              {priceType === "days" && (
                <small>
                  Por dias quiere decir que se cobrará el valor que pongas en
                  cada ciudad por el numero de dias del evento.
                </small>
              )}
              {priceType === "event" && (
                <small>
                  Por evento quiere decir que se cobrará el valor que pongas en
                  cada ciudad por la totalidad del evento.
                </small>
              )}
            </div>
          </Col>
          <Col span={16}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "colum"
              }}
            >
              {citiesList.map(city => (
                <CityPriceInput
                  setAditionalServiceCities={setAditionalServiceCities}
                  aditionalServiceCities={aditionalServiceCities}
                  setAditionalServiceCitiesPrices={
                    setAditionalServiceCitiesPrices
                  }
                  aditionalServiceCitiesPrices={aditionalServiceCitiesPrices}
                  city={city}
                  key={city}
                />
              ))}
            </div>
          </Col>
        </Row>
        <Button loading={loading} type='primary' htmlType='submit'>
          Guardar servicio adicional
        </Button>
      </Form>
    </div>
  );
};

export default CreateAditionalServices;
