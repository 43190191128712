import React from "react";
import { Icon, Button } from "antd";

const EditCompanySuccess = ({ setEditMode }) => {
  const resetCompanyState = () => {
    setEditMode(false);
  };
  return (
    <div style={{ display: "flex" }}>
      <div style={{ margin: "auto", textAlign: "center" }}>
        <Icon
          style={{ fontSize: 60 }}
          theme='twoTone'
          twoToneColor='#52c41a'
          type='smile'
        />
        <h3 style={{ marginTop: 10 }}>¡Bien hecho! Has editado la empresa</h3>
        <p>
          Haz click en el siguiente boton para actualizar la lista de empresas
        </p>
        <Button type='primary' onClick={() => resetCompanyState()}>
          Gracias, soy lo mejor
        </Button>
      </div>
    </div>
  );
};

export default EditCompanySuccess;
