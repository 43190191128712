import React, { useState } from "react";
import { Form, Row, Col, Input, Button, InputNumber, message } from "antd";
import { db, fieldValue } from "../../firebase.config";
import uuid from "uuid/v4";

const FinishOutputExtraElements = ({ outputId, brandId }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [units, setUnits] = useState(undefined);
  const [price, setPrice] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const hanldeAddExtraElement = async e => {
    e.preventDefault();
    if (!name) return message.error("El nombre es obligatorio");
    if (!description) return message.error("la descripcion es obligatoria");
    if (!units) return message.error("Las unidades son obligatorias");
    if (!price) return message.error("El precio es obligatorio");
    setLoading(true);
    try {
      const outputRef = db
        .collection("brands")
        .doc(brandId)
        .collection("outputs")
        .doc(outputId);
      await outputRef.update({
        outputExtraElements: fieldValue.arrayUnion({
          id: uuid(),
          name,
          description,
          units,
          price,
          createdAt: Date.now()
        })
      });
    } catch (e) {
      console.log(e.message);
      setLoading(false);
      message.error("No pudimos agregar el elemento");
    }
  };
  return (
    <div>
      <p>
        Si esta salida tuvo algun otro elemento que no se agrego inicialmente,
        lo puedes agregar aqui.
      </p>
      <Form onSubmit={hanldeAddExtraElement}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label='Nombre'>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder='Nombre del elemento'
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label='Descripcion elemento'>
              <Input
                name={description}
                onChange={e => setDescription(e.target.value)}
                placeholder='¿Que es el elemento?'
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item label='Precio unitario'>
              <InputNumber
                value={price}
                onChange={value => setPrice(value)}
                formatter={value =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                placeholder='$$$$'
                style={{ width: "100%" }}
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='Unidades'>
              <InputNumber
                onChange={value => setUnits(value)}
                placeholder='Unidades'
                style={{ width: "100%" }}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
        <h3>
          Total:
          {isNaN(price) || isNaN(units)
            ? "$ 0"
            : `$ ${price * units}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </h3>
        <Button loading={loading} htmlType='submit' type='primary'>
          Agregar elemento
        </Button>
      </Form>
    </div>
  );
};

export default FinishOutputExtraElements;
