import React, { useState, useEffect } from "react";
import { Table, Avatar } from "antd";
const formatPrice = value => {
  return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const OutputProfileExtraElements = ({ output }) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (output.outputExtraElements) {
      const newItems = output.outputExtraElements.map(element => ({
        key: element.id,
        id: element.id,
        name: element.name,
        mainImage: <Avatar shape='square' size='large' icon='pushpin' />,
        description: element.description,
        units: element.units,
        price: formatPrice(element.price),
        total: formatPrice(element.price * element.units),
        store: "Brandex",
        brandId: element.brandId
      }));
      setItems(newItems);
    }
  }, [output]);
  const columns = [
    {
      title: "Imagen",
      dataIndex: "mainImage",
      key: "Imagen"
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "Nombre"
    },
    {
      title: "Descripcion",
      dataIndex: "description",
      key: "Descripcion"
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "Precio"
    },
    {
      title: "Cantidad elegida",
      dataIndex: "units",
      key: "Cantidad"
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "Total"
    },
    {
      title: "Tienda",
      dataIndex: "store",
      key: "Tienda"
    }
  ];
  return (
    <div className='outputProfileContainer'>
      <h2>Elementos extra de la salida</h2>
      <Table
        pagination={{
          pageSize: 20
        }}
        size='small'
        columns={columns}
        dataSource={items}
        style={{ backgroundColor: "#fff", borderRadius: 10 }}
      />
    </div>
  );
};

export default OutputProfileExtraElements;
