import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Input,
  Row,
  Col,
  InputNumber,
  Select,
  message
} from "antd";
import { db, fieldValue } from "../../firebase.config";
const { Option } = Select;
const { TextArea } = Input;

const EditKeepmePointsElementForm = ({ element, setEditMode }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(element.name);
  const [units, setUnits] = useState(element.units);
  const [points, setPoints] = useState(element.points);
  const [description, setDescription] = useState(element.description || "");
  const [category, setCategory] = useState(element.category);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const unsubscribeToCategories = db
      .collection("categories")
      .doc("keepmepoints")
      .onSnapshot(doc => {
        const categoriesData = doc.data();
        setCategories(categoriesData.categories);
      });
    return () => unsubscribeToCategories();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    //some validation of fields
    if (!name) return message.error("El elemento debe tener nombre");
    if (!units)
      return message.error("El elemento debe tener cantidades disponibles");
    if (!points) return message.error("El elemento debe tener puntos");
    if (!category)
      return message.error("El elemento debe tener asignada una categoria");

    const elementRef = db.collection("pointsCatalog").doc(element.id);
    const categoriesRef = db.collection("categories").doc("keepmepoints");
    if (element.category !== category) {
      try {
        await db.runTransaction(async t => {
          const categoriesDoc = await t.get(categoriesRef);
          const categoriesData = categoriesDoc.data();
          const oldCategoryId = element.categoryId;
          const newCategoryId = categoriesData.categories.filter(
            cat => cat.name === category
          )[0].id;

          t.update(categoriesRef, {
            [oldCategoryId]: fieldValue.increment(-1),
            [newCategoryId]: fieldValue.increment(1)
          });

          t.update(elementRef, {
            name,
            units,
            points,
            category,
            categoryId: newCategoryId,
            description,
            updatedAt: Date.now()
          });
        });
        setEditMode(false);
      } catch (e) {
        console.log(e.message);
        message.error("No pudimos actualizar el elemento");
      }
    } else {
      try {
        await elementRef.update({
          name,
          units,
          points,
          description,
          updatedAt: Date.now()
        });

        setEditMode(false);
      } catch (e) {
        console.log(e.message);
        message.error("No pudimos actualizar el complemento");
        setLoading(false);
      }
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label='Nombre'>
            <Input
              value={name}
              placeholder='Nombre del elemento'
              onChange={e => setName(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Cantidades disponibles'>
            <InputNumber
              value={units}
              onChange={value => setUnits(value)}
              placeholder='Unidades'
              style={{ width: "100%" }}
              min={0}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label='Descripcion'>
            <TextArea
              value={description}
              onChange={e => setDescription(e.target.value)}
              placeholder='Agrega una breve descripcion de no mas de 140 caracteres, como un Tweet'
              autosize={{ minRows: 4, maxRows: 10 }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Categoria'>
            <Select value={category} onChange={value => setCategory(value)}>
              {categories &&
                categories.map(category => (
                  <Option key={category.name} value={category.name}>
                    {category.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label='Puntos'>
            <InputNumber
              value={points}
              onChange={value => setPoints(value)}
              style={{ width: "100%" }}
              min={0}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type='primary' htmlType='submit' loading={loading}>
          Actualizar elemento
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditKeepmePointsElementForm;
