import React, { useEffect, useState } from "react";
import { Divider, Icon, Button, message } from "antd";
import RedemptionCard from "./RedemptionCard";
import { db } from "../../firebase.config";

const KeepmePointsRedemptions = () => {
  const [pendingRedemptions, setPendingRedemptions] = useState([]);
  const [oldRedemptions, setOldRedemptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingOld, setLoadingOld] = useState(false);
  const [currentCursor, setCurrentCursor] = useState();
  const [loadMore, setLoadMore] = useState(false);
  const fetchOldRedemptions = async () => {
    try {
      setLoadingOld(true);
      let oldRedemptionsRef;
      if (currentCursor) {
        oldRedemptionsRef = db
          .collection("pointsApprovals")
          .where("checked", "==", true)
          .orderBy("createdAt", "desc")
          .startAfter(currentCursor)
          .limit(2);
      } else {
        oldRedemptionsRef = db
          .collection("pointsApprovals")
          .where("checked", "==", true)
          .orderBy("createdAt", "desc")
          .limit(2);
      }

      const oldRedemptionsDocs = await oldRedemptionsRef.get();
      const docCursor =
        oldRedemptionsDocs.docs[oldRedemptionsDocs.docs.length - 1];
      const oldRedemptionsData = oldRedemptionsDocs.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setOldRedemptions([...oldRedemptions, ...oldRedemptionsData]);
      setCurrentCursor(docCursor);
      setLoadMore(!(oldRedemptionsData.length === 0));
      setLoadingOld(false);
    } catch (e) {
      console.log(e.message);
      setLoading(false);
      message.error("no pudimos cargar mas notificaciones");
    }
  };

  const CustomizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
      <Icon type='smile' style={{ fontSize: 30 }} />
      <p>¿Desear cargar redenciones anteriores?</p>
      <Button
        loading={loadingOld}
        onClick={() => fetchOldRedemptions()}
        type='primary'
      >
        Cargar redenciones anteriores
      </Button>
    </div>
  );

  useEffect(() => {
    const unsubscribeToPendingRedemptions = db
      .collection("pointsApprovals")
      .where("status", "==", "pending")
      .orderBy("createdAt", "desc")
      .onSnapshot(query => {
        const newPendingRedemptions = query.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPendingRedemptions(newPendingRedemptions);
        setLoading(false);
      });
    return () => unsubscribeToPendingRedemptions();
  }, []);
  return (
    <div className='pageContainer'>
      <h2>Redenciones pendientes por aprobar:</h2>
      <div className='keepmepointsContainer'>
        {pendingRedemptions.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <Icon type='smile' style={{ fontSize: 30 }} />
            <p>No hay redenciones pendientes por aprobar</p>
          </div>
        )}
        {loading ? (
          <div style={{ display: "flex" }}>
            <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
          </div>
        ) : (
          <>
            {pendingRedemptions.map(redemption => (
              <RedemptionCard key={redemption.id} redemption={redemption} />
            ))}
          </>
        )}
      </div>
      <Divider />
      <h2>Redenciones pasadas:</h2>
      <div className='keepmepointsContainer'>
        {oldRedemptions.length === 0 && <CustomizeRenderEmpty />}

        {oldRedemptions.map(redemption => (
          <RedemptionCard key={redemption.id} redemption={redemption} />
        ))}

        {loadMore && (
          <div style={{ textAlign: "center" }}>
            <Button loading={loadingOld} onClick={() => fetchOldRedemptions()}>
              Cargar mas
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default KeepmePointsRedemptions;
