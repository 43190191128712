import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  message,
  Switch,
  Divider
} from "antd";
import { withRouter } from "react-router-dom";
import locale from "antd/lib/date-picker/locale/es_ES";
import { db, storage } from "../../firebase.config";
import UploadEventImage from "./UploadEventImage";
import BrandTags from "../brands/BrandTags";
import { getDateValues } from "../../utils/formatTimestamp";
import moment from "moment";

const dateFormat = "DD-MM-YYYY";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

const CreateEventForm = ({ history, currentEvent, setEditMode }) => {
  const [date, setDate] = useState([]);
  const [tags, setTags] = useState(() =>
    currentEvent ? currentEvent.tags : []
  );
  const [isFeatured, setIsFeatured] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCities, setLoadingCities] = useState(true);
  const [citiesList, setCitiesList] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [city, setCity] = useState(undefined);
  const [place, setPlace] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [progressVisible, setProgressVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [initialImage, setInitialImage] = useState("");

  useEffect(() => {
    if (currentEvent) {
      setName(currentEvent.name || "");
      setDescription(currentEvent.description || "");
      setCity(currentEvent.city || "");
      setPlace(currentEvent.place || "");
      setInitialImage(currentEvent.mainImage || "");
      setIsFeatured(currentEvent.isFeatured || false);
      setDate([
        moment(currentEvent.startDate || Date.now()),
        moment(currentEvent.finish || Date.now())
      ]);
    }
  }, [currentEvent]);

  useEffect(() => {
    const unsubscribeToCities = db
      .collection("information")
      .doc("cities")
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          setCitiesList(data.cities);
        }
        setLoadingCities(false);
      });
    return () => unsubscribeToCities();
  }, [setCitiesList]);

  const handleCreateEvent = async e => {
    e.preventDefault();
    // console.log(name, description, city, place, tags);
    if (!name) return message.error("El nombre es obligatorio");
    if (!description) return message.error("La descripcion es obligatoria");
    if (!city) return message.error("La ciudad es obligatoria");
    if (!place) return message.error("El lugar es obligatorio");
    if (tags.length === 0)
      return message.error("Los atributos son obligatorios");
    if (date.length === 0) return message.error("Las fechas son obligatorias");

    if (!currentEvent && fileList.length === 0)
      return message.error("La imagen principal es obligatoria");

    setLoading(true);
    const startDate = date[0].valueOf();
    const finishDate = date[1].valueOf();
    let newEvent = {
      name,
      description,
      city,
      place,
      startDate,
      finishDate,
      month: getDateValues(startDate).monthName,
      day: ("0" + getDateValues(startDate).day).slice(-2),
      isFeatured,
      tags
    };
    try {
      if (currentEvent) {
        const eventRef = db.collection("events").doc(currentEvent.id);
        newEvent.updatedAt = Date.now();
        await eventRef.update(newEvent);
        if (fileList.length > 0) {
          try {
            await handleUpload(fileList[0], currentEvent.id);
          } catch (e) {
            console.log(e.message);
            setLoading(false);
            return message.error(
              "No pudimos cargar la imagen, pero se creo el evento"
            );
          }
        }
        setEditMode(false);
      } else {
        const eventRef = db.collection("events");
        newEvent.mainImage = "";
        newEvent.createdAt = Date.now();
        const eventDoc = await eventRef.add(newEvent);
        try {
          await handleUpload(fileList[0], eventDoc.id);
        } catch (e) {
          console.log(e.message);
          setLoading(false);
          return message.error(
            "No pudimos cargar la imagen, pero se creo el evento"
          );
        }
      }
    } catch (e) {
      console.log(e.message);
      setLoading(false);
      return message.error("No pudimos agregar la serie");
    }
  };

  const handleUpload = async (file, eventId) => {
    setProgressVisible(true);
    const storageRef = storage.ref();
    const fileRef = storageRef.child(`events/${eventId}/mainImage`);
    const uploadTask = fileRef.put(file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(Math.trunc(progress));
        console.log("Upload is " + progress + "% done");
      },
      error => {
        message.error(error, 3);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(async downloadUrl => {
          if (downloadUrl) {
            const eventtRef = db.collection("events").doc(eventId);
            await eventtRef.update({ mainImage: downloadUrl });
          }
          history.push("/eventos");
        });
      }
    );
  };
  return (
    <Form onSubmit={handleCreateEvent}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label='Nombre del evento'>
            <Input
              value={name}
              placeholder='Nombre del evento'
              onChange={e => setName(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Fechas del evento'>
            <RangePicker
              value={date}
              format={dateFormat}
              locale={locale}
              onChange={date => setDate(date)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label='Descripcion del evento'>
            <TextArea
              value={description}
              placeholder='¿De que trata el evento? Se breve...'
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label='Ciudad del evento'>
            <Select
              value={city}
              loading={loadingCities}
              showSearch
              onChange={value => setCity(value)}
              placeholder='¿En cual ciudad vas a salir?'
              style={{ width: "80%" }}
            >
              {citiesList.map(city => (
                <Option key={city} value={city}>
                  {city}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label='Lugar del evento'>
            <Input
              value={place}
              placeholder='¿En donde se realizará el evento?'
              onChange={e => setPlace(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='¿Es destacado?'>
            Destacado{" "}
            <Switch
              checked={isFeatured}
              onChange={checked => setIsFeatured(checked)}
            />
            <div>
              <small>Hacer que el evento salga en la pagina de login</small>
            </div>
          </Form.Item>
        </Col>
      </Row>
      {/* <Divider /> */}
      <h3>Atributos del evento</h3>
      <BrandTags setBrandTags={setTags} initialTags={tags} />
      <Divider />
      <UploadEventImage
        title='Escoger imagen'
        fileList={fileList}
        setFileList={setFileList}
        uploadProgress={uploadProgress}
        progressVisible={progressVisible}
        initialImage={initialImage}
      />
      <Divider />

      <Button type='primary' htmlType='submit' loading={loading}>
        Guardar evento
      </Button>
    </Form>
  );
};

export default withRouter(CreateEventForm);
