import { action, thunk } from "easy-peasy";

export default {
  companies: [],
  loadingCompanies: true,
  createCompanyState: {
    loading: false,
    error: "",
    success: false,
    companyId: ""
  },
  updateCompanyState: {
    loading: false,
    error: "",
    success: false
  },

  deleteCompanyState: {
    loading: false,
    error: "",
    success: false
  },

  //thunks
  fetchCompaniesList: thunk(async (actions, payload, { injections }) => {
    actions.setLoadingCompanies(true);
    const { db } = injections;
    try {
      const snapshot = await db.collection("companies").get();
      const companies = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      actions.setCompaniesList(companies);
    } catch (e) {
      console.log(e.message);
    }
    actions.setLoadingCompanies(false);
  }),

  createCompany: thunk(async (actions, payload, { injections }) => {
    actions.setCreateCompanyState({
      loading: true,
      error: "",
      success: false,
      companyId: ""
    });
    console.log(payload.name);
    const { db } = injections;
    try {
      const companiesRef = db
        .collection("companies")
        .where("name", "==", payload.name);
      const companiesWithSameName = await companiesRef.get();
      if (companiesWithSameName.size > 0) {
        return actions.setCreateCompanyState({
          loading: false,
          error: "El nombre de esta empresa ya existe",
          success: false,
          companyId: ""
        });
      }
      const ref = await db.collection("companies").add({
        name: payload.name,
        createdAt: Date.now()
      });
      console.log("Added company with ID: ", ref.id);
      // await actions.fetchCompaniesList();
      actions.setCreateCompanyState({
        loading: false,
        error: "",
        success: true,
        companyId: ref.id
      });
    } catch (e) {
      console.log(e.code);
      actions.setCreateCompanyState({
        loading: false,
        error: e.code,
        success: false,
        companyId: ""
      });
    }
  }),

  updateCompany: thunk(async (actions, payload, { injections }) => {
    actions.setUpdateCompanyState({
      loading: true,
      error: "",
      success: false
    });
    const { db } = injections;
    try {
      const companiesRef = db
        .collection("companies")
        .where("name", "==", payload.name);
      const companiesWithSameName = await companiesRef.get();
      if (companiesWithSameName.size > 0) {
        return actions.setUpdateCompanyState({
          loading: false,
          error: "El nombre de esta empresa ya existe",
          success: false,
          companyId: ""
        });
      }
      await db
        .collection("companies")
        .doc(payload.companyId)
        .update({
          name: payload.name,
          updatedAt: Date.now()
        });
      console.log("updated company with ID");
      actions.setUpdateCompanyState({
        loading: false,
        error: "",
        success: true
      });
    } catch (e) {
      console.log(e.code);
      actions.setUpdateCompanyState({
        loading: false,
        error: e.code,
        success: false
      });
    }
  }),

  deleteCompany: thunk(async (actions, payload, { injections }) => {
    actions.setDeleteCompanyState({
      loading: true,
      error: "",
      success: false,
      companyId: payload.id
    });
    const { functions } = injections;
    const deleteCompany = functions.httpsCallable("deleteCompany");
    try {
      const response = await deleteCompany({
        companyId: payload.id
      });
      if (response.data.status === "error") {
        actions.setDeleteCompanyState({
          loading: false,
          error: response.data.code,
          success: false,
          companyId: payload.id
        });
      } else {
        actions.setDeleteCompanyState({
          loading: false,
          error: "",
          success: true,
          companyId: payload.id
        });
      }
      console.log(response);
    } catch (e) {
      actions.setDeleteCompanyState({
        loading: false,
        error: e.code,
        success: false,
        companyId: payload.id
      });
      console.log(e);
    }
  }),

  //Actions
  setCompaniesList: action((state, payload) => {
    state.companies = payload;
  }),
  setLoadingCompanies: action((state, payload) => {
    state.loadingCompanies = payload;
  }),
  setCreateCompanyState: action((state, payload) => {
    state.createCompanyState = payload;
  }),

  setUpdateCompanyState: action((state, payload) => {
    state.updateCompanyState = payload;
  }),

  setDeleteCompanyState: action((state, payload) => {
    state.deleteCompanyState = payload;
  })
};
