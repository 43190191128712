import React, { useState } from "react";
import {
  List,
  Avatar,
  Button,
  Divider,
  message,
  Icon,
  ConfigProvider
} from "antd";
import "./notifications.css";
import { useStoreState } from "easy-peasy";
import formatTimestamp from "../../utils/formatTimestamp";
import { db } from "../../firebase.config";

const Notifications = () => {
  const { notifications } = useStoreState(state => state.notifications);
  const [oldNotifications, setOldNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentCursor, setCurrentCursor] = useState();
  const [loadMore, setLoadMore] = useState(false);

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
      <Icon type='smile' style={{ fontSize: 30 }} />
      <p>¿Desear cargar notificaciones anteriores?</p>
      <Button
        loading={loading}
        onClick={() => fetchOldNotifications()}
        type='primary'
      >
        Cargar notificaciones anteriores
      </Button>
    </div>
  );

  const checkNotification = async id => {
    try {
      const notificationRef = db.collection("notifications").doc(id);
      await notificationRef.update({ completed: true });
      message.success("¡Notificacion completada!");
    } catch (e) {
      message.error("No pudimos completar la notificacion");
      console.log(e.message);
    }
  };

  const fetchOldNotifications = async () => {
    try {
      setLoading(true);
      let oldNotificationsRef;
      if (currentCursor) {
        oldNotificationsRef = db
          .collection("notifications")
          .where("completed", "==", true)
          .orderBy("createdAt", "desc")
          .startAfter(currentCursor)
          .limit(2);
      } else {
        oldNotificationsRef = db
          .collection("notifications")
          .where("completed", "==", true)
          .orderBy("createdAt", "desc")
          .limit(2);
      }

      const oldNotificationsDocs = await oldNotificationsRef.get();
      const docCursor =
        oldNotificationsDocs.docs[oldNotificationsDocs.docs.length - 1];
      const oldNotificationsData = oldNotificationsDocs.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setOldNotifications([...oldNotifications, ...oldNotificationsData]);
      setCurrentCursor(docCursor);
      setLoadMore(!(oldNotificationsData.length === 0));
      setLoading(false);
    } catch (e) {
      console.log(e.message);
      setLoading(false);
      message.error("no pudimos cargar mas notificaciones");
    }
  };
  return (
    <div className='pageContainer'>
      <h2>Nuevas notificaciones:</h2>
      <div className='notificationsContainer'>
        <List
          loading={false}
          itemLayout='horizontal'
          dataSource={notifications}
          renderItem={item => (
            <List.Item
              actions={[
                <Button
                  type='primary'
                  onClick={() => checkNotification(item.id)}
                >
                  Ok
                </Button>
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{ margin: "auto" }}
                    size={64}
                    icon='carry-out'
                  />
                }
                description={formatTimestamp(item.createdAt)}
              />
              <div style={{ marginLeft: 10 }}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </List.Item>
          )}
        />
      </div>
      <Divider />
      <div>
        <h2>Notificaciones pasadas:</h2>
        <p>
          Las notificaciones que ya se hayan visto pueden ser consultadas aqui,
          en orden cronologico.
        </p>
        <div className='notificationsContainer'>
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <List
              loading={false}
              itemLayout='horizontal'
              dataSource={oldNotifications}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        style={{ margin: "auto" }}
                        size={64}
                        icon='carry-out'
                      />
                    }
                    description={formatTimestamp(item.createdAt)}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </List.Item>
              )}
            />
          </ConfigProvider>
          {loadMore && (
            <div style={{ textAlign: "center" }}>
              <Divider />
              <Button loading={loading} onClick={() => fetchOldNotifications()}>
                Cargar mas
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Notifications;
