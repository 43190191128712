import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col, Icon } from "antd";
import { useStoreState, useStoreActions } from "easy-peasy";
import UploadBrandFiles from "./UploadBrandFiles";
import DeleteBrandFile from "./DeleteBrandFile";
import formatBytes from "../../utils/formatBytes";
import formatTimestamp from "../../utils/formatTimestamp";

const BrandFiles = ({ currentBrand }) => {
  const [files, setFiles] = useState([]);
  const {
    brandFilesAssets,
    fetchBrandFilesAssetsState: { loading }
  } = useStoreState(state => state.brands);
  const { fetchBrandFilesAssets } = useStoreActions(actions => actions.brands);
  useEffect(() => {
    const brandFilesForTable = brandFilesAssets.map(file => {
      return {
        key: file.name,
        nombre: file.name,
        peso: formatBytes(file.size),
        fecha: formatTimestamp(file.creationDate),
        descargar: (
          <Button
            type='primary'
            size='small'
            onClick={() => downloadFile(file.downloadUrl, file.name)}
          >
            Descargar
          </Button>
        ),
        borrar: <DeleteBrandFile path={file.fullPath} id={currentBrand.id} />
      };
    });
    setFiles(brandFilesForTable);
  }, [brandFilesAssets, currentBrand.id]);

  useEffect(() => {
    fetchBrandFilesAssets({ id: currentBrand.id });
  }, [fetchBrandFilesAssets, currentBrand.id]);

  const downloadFile = (url, filename) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = () => {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = filename;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
    };
    xhr.open("GET", url);
    xhr.send();
  };

  return (
    <Row gutter={16}>
      <Col span={16}>
        <h2>Brand Files</h2>
        {loading ? (
          <div style={{ width: "100%", height: 200, display: "flex" }}>
            <Icon type='loading' style={{ margin: "auto", fontSize: 60 }} />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={files}
            size='middle'
            style={{
              backgroundColor: "#fff",
              borderRadius: 10,
              marginTop: 20
            }}
            pagination={{ hideOnSinglePage: true }}
          />
        )}
      </Col>
      <Col span={8}>
        <h2>Subir Brand Files</h2>

        <UploadBrandFiles
          id={currentBrand.id}
          directory='brandFiles'
          files={files}
          setFiles={setFiles}
          downloadFile={downloadFile}
        />
      </Col>
    </Row>
  );
};

const columns = [
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre"
  },
  {
    title: "Peso",
    dataIndex: "peso",
    key: "peso"
  },
  {
    title: "Fecha de creacion",
    dataIndex: "fecha",
    key: "fecha"
  },
  {
    title: "Descargar",
    dataIndex: "descargar",
    key: "descargar"
  },
  {
    title: "Borrar",
    dataIndex: "borrar",
    key: "borrar"
  }
];

export default BrandFiles;
