import React, { useState, useEffect } from "react";
import {
  Divider,
  Input,
  Icon,
  Radio,
  InputNumber,
  Checkbox,
  message
} from "antd";
import { useStoreState } from "easy-peasy";

const { TextArea } = Input;
const FinishOutputElement = ({
  item,
  elementsToUpdate,
  setElementsToUpdate,
  inventoryElementsToUpdate,
  setInventoryElementsToUpdate,
  setComplementsToUpdate,
  complementsToUpdate
}) => {
  const [hasFix, setHasFix] = useState(false);
  const [receivedUnits, setReceivedUnits] = useState(item.selectedUnits);
  const [fixDescription, setFixDescription] = useState("");
  const [fixPrice, setFixPrice] = useState(0);
  const [confirmInfo, setConfirmInfo] = useState(false);
  const { currentUser } = useStoreState(state => state.user);

  useEffect(() => {
    if (!hasFix) {
      setFixDescription("");
      setFixPrice(0);
    }
  }, [hasFix]);

  const handleConfirmInfo = confirm => {
    console.log(confirm, typeof confirm);
    if (confirm) {
      if (hasFix) {
        if (!fixDescription) {
          setConfirmInfo(false);
          return message.error(
            `Si ${item.name} tiene reparacion, la descripcion es obligatoria`
          );
        }
        if (!fixPrice) {
          setConfirmInfo(false);
          return message.error(
            `Si ${item.name} tiene reparacion, el precio es obligatorio`
          );
        }
      }

      if (item.storeType === "elements") {
        const elementIndex = elementsToUpdate.findIndex(
          element => element.id === item.id
        );

        if (elementIndex === -1) {
          setElementsToUpdate([
            ...elementsToUpdate,
            {
              id: item.id,
              brandId: item.brandId,
              serieId: item.serieId,
              receivedUnits,
              hasFix,
              fixDescription,
              fixPrice,
              lastCheckBy: currentUser.name
            }
          ]);
        } else {
          const newElementsToUpdate = elementsToUpdate;
          newElementsToUpdate[elementIndex] = {
            id: item.id,
            brandId: item.brandId,
            serieId: item.serieId,
            receivedUnits,
            hasFix,
            fixDescription,
            fixPrice,
            lastCheckBy: currentUser.name
          };
          setElementsToUpdate(newElementsToUpdate);
        }
      }
      if (item.storeType === "inventory") {
        const elementIndex = inventoryElementsToUpdate.findIndex(
          element => element.id === item.id
        );
        if (elementIndex === -1) {
          setInventoryElementsToUpdate([
            ...inventoryElementsToUpdate,
            {
              id: item.id,
              brandId: item.brandId,
              receivedUnits,
              hasFix,
              fixDescription,
              fixPrice,
              lastCheckBy: currentUser.name
            }
          ]);
        } else {
          const newInventoryElementsToUpdate = inventoryElementsToUpdate;
          newInventoryElementsToUpdate[elementIndex] = {
            id: item.id,
            brandId: item.brandId,
            receivedUnits,
            hasFix,
            fixDescription,
            fixPrice,
            lastCheckBy: currentUser.name
          };
          setInventoryElementsToUpdate(newInventoryElementsToUpdate);
        }
      }
      if (item.storeType === "complements") {
        const complementIndex = complementsToUpdate.findIndex(
          element => element.id === item.id
        );
        if (complementIndex === -1) {
          setComplementsToUpdate([
            ...complementsToUpdate,
            {
              id: item.id,
              receivedUnits,
              hasFix,
              fixDescription,
              fixPrice,
              lastCheckBy: currentUser.name
            }
          ]);
        } else {
          const newComplementsToUpdate = complementsToUpdate;
          newComplementsToUpdate[complementIndex] = {
            id: item.id,
            receivedUnits,
            hasFix,
            fixDescription,
            fixPrice,
            lastCheckBy: currentUser.name
          };
          setComplementsToUpdate(newComplementsToUpdate);
        }
      }
    } else {
      if (item.storeType === "elements") {
        const elementsToUpdatedWithoutItem = elementsToUpdate.filter(
          element => element.id !== item.id
        );
        setElementsToUpdate(elementsToUpdatedWithoutItem);
      }
      if (item.storeType === "inventory") {
        const inventoryElementsToUpdatedWithoutItem = inventoryElementsToUpdate.filter(
          element => element.id !== item.id
        );
        setInventoryElementsToUpdate(inventoryElementsToUpdatedWithoutItem);
      }
      if (item.storeType === "complements") {
        const complementsToUpdatedWithoutItem = complementsToUpdate.filter(
          complement => complement.id !== item.id
        );
        setComplementsToUpdate(complementsToUpdatedWithoutItem);
      }
    }
    setConfirmInfo(confirm);
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ margin: 10 }}>
          <div
            className='finishOutputElementImage'
            style={{
              backgroundImage: `url(${item.mainImage || null})`,
              backgroundSize: "cover",
              backgroundPosition: "center center"
            }}
          >
            {!item.mainImage && (
              <Icon type='picture' style={{ margin: "auto", fontSize: 20 }} />
            )}
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <h2>{item.name}</h2>
          <p>
            <strong>Unidades sacadas: </strong>
            {item.selectedUnits}
          </p>
          <p>
            <strong>Tienda: </strong>
            {item.store}
          </p>
          {item.brandName && (
            <p>
              <strong>Marca: </strong>
              {item.brandName}
            </p>
          )}

          {item.category && (
            <p>
              <strong>Categoria: </strong>
              {item.category}
            </p>
          )}
          <Divider />
          <div>
            <strong>Confirmar info: </strong>
            <Checkbox
              checked={confirmInfo}
              onChange={e => handleConfirmInfo(e.target.checked)}
            />
            <div style={{ height: "1em" }}>
              {confirmInfo && <small>Revisado por {currentUser.name}</small>}
            </div>
          </div>
        </div>

        <div style={{ margin: 10 }}>
          <h4>Unidades recibidas: </h4>
          <InputNumber
            disabled={confirmInfo}
            value={receivedUnits}
            onChange={value => setReceivedUnits(value)}
            placeholder='Cantidad'
            style={{ width: "100%" }}
            min={0}
          />
        </div>
        {item.storeType === "elements" && (
          <>
            <div style={{ margin: 10 }}>
              <h4>¿Necesita reparacion? </h4>
              <Radio.Group
                disabled={confirmInfo}
                value={hasFix}
                onChange={e => setHasFix(e.target.value)}
              >
                <Radio value={true}>Si</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
            <div style={{ margin: 10 }}>
              <h4>Descripcion de la reparacion: </h4>
              <TextArea
                disabled={!hasFix || confirmInfo}
                value={fixDescription}
                onChange={e => setFixDescription(e.target.value)}
                style={{ width: "100%" }}
                autosize={{ minRows: 1, maxRows: 6 }}
                placeholder='¿Que reparacion necesita?'
              />
            </div>
            <div style={{ margin: 10 }}>
              <h4>Costo de la reparacion: </h4>
              <InputNumber
                value={fixPrice}
                onChange={value => setFixPrice(value)}
                disabled={!hasFix || confirmInfo}
                formatter={value =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                placeholder='$$$$'
                style={{ width: "100%" }}
                min={0}
              />
            </div>
          </>
        )}
      </div>
      <Divider />
    </>
  );
};

export default FinishOutputElement;
