import React from "react";
import formatTimestamp from "../../utils/formatTimestamp";
import { Popconfirm, Icon, Divider } from "antd";
const EventCard = ({ event, editEvent, deleteEvent }) => {
  return (
    <div className='eventCard'>
      <div style={{ display: "flex" }}>
        <Popconfirm
          title={`¿Seguro que quieres borrar ${event.name}?`}
          onConfirm={() => deleteEvent(event)}
          okText='Si'
          cancelText='No gracias'
        >
          <Icon type='close' shape='circle' style={{ fontSize: 12 }} />
        </Popconfirm>
        <Icon
          type='edit'
          shape='circle'
          style={{ fontSize: 14, marginLeft: "auto" }}
          onClick={() => editEvent(event)}
        />
      </div>

      <h3>
        {event.isFeatured && <Icon type='star' />} {event.name}
      </h3>
      <p>
        {formatTimestamp(event.startDate)} - {formatTimestamp(event.finishDate)}
      </p>
      <Divider />
      <div>
        <small>{event.description}</small>
      </div>
      <div>
        <Icon type='environment' />{" "}
        <small>
          {event.city} - {event.place}
        </small>
      </div>
    </div>
  );
};

export default EventCard;
