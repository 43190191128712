import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Button, message } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import { TwitterPicker } from "react-color";
import CreateBrandKit from "./CreateBrandKit";
import CreateBrandSuccess from "./CreateBrandSuccess";
import dbErrorMessages from "../../utils/dbErrorMessages";
import { db } from "../../firebase.config";
import "./brands.css";
const { Option } = Select;

const CreateBrand = () => {
  const [company, setCompany] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [brandType, setBrandType] = useState("");
  const [name, setName] = useState("");
  const [mainColor, setMainColor] = useState("#ABB8C3");
  const [executiveName, setExecutiveName] = useState("");
  const [executiveEmail, setExecutiveEmail] = useState("");
  const [brandTags, setBrandTags] = useState([]);
  const [audience, setAudience] = useState([]);
  const [colors, setColors] = useState([]);
  const [brandTargetExpo, setBrandTargetExpo] = useState("");
  const [brandTargetBrandtour, setBrandTargetBrandtour] = useState("");
  const [brandTargetCorporate, setBrandTargetCorporate] = useState("");
  const [expoTargets, setExpoTargets] = useState([]);
  const [brandtourTargets, setBrandtourTargets] = useState([]);
  const [corporateTargets, setCorporateTargets] = useState([]);

  //Info from companies model
  const { companies, loadingCompanies } = useStoreState(
    state => state.companies
  );
  const fetchCompaniesList = useStoreActions(
    actions => actions.companies.fetchCompaniesList
  );
  //info from brands model
  const { loading, error, success, brandId } = useStoreState(
    state => state.brands.createBrandState
  );
  const { createBrand, setCreateBrandState } = useStoreActions(
    actions => actions.brands
  );

  //get outputTypes and goals for brand targets
  useEffect(() => {
    const getTypes = async () => {
      const typesRef = db.collection("information").doc("outputTypesAndGoals");
      try {
        const typesDoc = await typesRef.get();
        console.log("Called fetch types");
        const typesData = typesDoc.data();
        let expo = [];
        let brandtour = [];
        let corporate = [];
        if (typesData.types && typesData.types.length > 0) {
          typesData.types.forEach(type => {
            if (type.channel === "Expo") expo.push(...type.goals);
            if (type.channel === "Corporate") corporate.push(...type.goals);
            if (type.channel === "Brandtour") brandtour.push(...type.goals);
          });
        }
        setExpoTargets([...new Set(expo)]);
        setBrandtourTargets([...new Set(brandtour)]);
        setCorporateTargets([...new Set(corporate)]);
      } catch (e) {
        console.log(e.message);
      }
    };
    getTypes();
  }, []);

  // get companies from database and store it in global store
  useEffect(() => {
    if (companies.length === 0) fetchCompaniesList();
  }, [fetchCompaniesList, companies]);

  //if error show error message
  useEffect(() => {
    if (error)
      message.error(dbErrorMessages(error), 3, () =>
        setCreateBrandState({
          loading: false,
          error: "",
          success: false,
          companyId: ""
        })
      );
  }, [error, setCreateBrandState]);

  useEffect(() => {
    const singleCompany = companies.filter(comp => comp.name === company);
    if (singleCompany.length > 0 && singleCompany[0].id)
      setCompanyId(singleCompany[0].id);
  }, [company, setCompanyId, companies]);

  const handleCreateBrand = e => {
    e.preventDefault();
    if (!name)
      return setCreateBrandState({
        loading: false,
        error: "El nombre de la marca es obligatorio",
        success: false,
        companyId: ""
      });
    if (!company)
      return setCreateBrandState({
        loading: false,
        error: "La empresa es obligatoria",
        success: false,
        companyId: ""
      });

    if (!brandType)
      return setCreateBrandState({
        loading: false,
        error: "El tipo de marca es obligatorio",
        success: false,
        companyId: ""
      });

    if (!mainColor)
      return setCreateBrandState({
        loading: false,
        error: "El color principal es obligatorio",
        success: false,
        companyId: ""
      });

    if (!executiveName)
      return setCreateBrandState({
        loading: false,
        error: "El nombre del ejecutivo es obligatorio",
        success: false,
        companyId: ""
      });

    if (!executiveEmail)
      return setCreateBrandState({
        loading: false,
        error: "El correo del ejecutivo es obligatorio",
        success: false,
        companyId: ""
      });

    if (brandTags.length === 0)
      return setCreateBrandState({
        loading: false,
        error: "Los atributos son obligatorios",
        success: false,
        companyId: ""
      });

    if (audience.length === 0)
      return setCreateBrandState({
        loading: false,
        error: "La autiencia es obligatoria",
        success: false,
        companyId: ""
      });

    if (!brandTargetExpo)
      return setCreateBrandState({
        loading: false,
        error: "El objetivo para Expo es obligatorio",
        success: false,
        companyId: ""
      });

    if (!brandTargetBrandtour)
      return setCreateBrandState({
        loading: false,
        error: "El objetivo para Brandtour es obligatorio",
        success: false,
        companyId: ""
      });

    if (!brandTargetCorporate)
      return setCreateBrandState({
        loading: false,
        error: "El objetivo para Corporate es obligatorio",
        success: false,
        companyId: ""
      });

    createBrand({
      name,
      company,
      companyId,
      executiveName: executiveName.trim(),
      executiveEmail: executiveEmail.trim(),
      brandType,
      brandTargetExpo,
      brandTargetBrandtour,
      brandTargetCorporate,
      mainColor,
      brandTags,
      audience,
      brandColors: colors
    });
  };
  return (
    <div className='pageContainer'>
      {success ? (
        <CreateBrandSuccess brandId={brandId} />
      ) : (
        <>
          <p>Vamos a crear una marca, llena todos los campos solicitados.</p>
          <Form onSubmit={handleCreateBrand}>
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item label='Nombre'>
                  <Input
                    placeholder='Nombre de la marca'
                    onChange={e => setName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Empresa'>
                  <Select
                    showSearch
                    disabled={loadingCompanies}
                    loading={loadingCompanies}
                    placeholder='Escoge una empresa'
                    optionFilterProp='children'
                    onChange={value => setCompany(value)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {companies.map(company => (
                      <Option key={company.id} value={company.name}>
                        {company.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item label='Tipo de marca'>
                  <Select
                    showSearch
                    placeholder='Escoge un tipo de marca'
                    optionFilterProp='children'
                    onChange={value => setBrandType(value)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value='Superbrand'>Superbrand</Option>
                    <Option value='Specialized'>Specialized</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Escoge el color principal de la marca'>
                  <div style={{ display: "flex" }}>
                    <div
                      className='colorCircleShadow'
                      style={{
                        backgroundColor: mainColor,
                        margin: "auto"
                      }}
                    />
                    <TwitterPicker
                      triangle='hide'
                      width='90%'
                      color={mainColor}
                      onChangeComplete={color => setMainColor(color.hex)}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item label='Ejecutivo de cuenta'>
                  <Input
                    placeholder='Nombre del ejecutivo'
                    onChange={e => setExecutiveName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Correo del ejecutivo'>
                  <Input
                    htmltype='email'
                    placeholder='Correo del ejecutivo'
                    onChange={e => setExecutiveEmail(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label='Objetivo de Expo'>
                  <Select
                    disabled={expoTargets.length === 0}
                    onChange={value => setBrandTargetExpo(value)}
                    placeholder='¿Cual objetivo para Expo?'
                  >
                    {expoTargets.map(target => (
                      <Option key={target} value={target}>
                        {target}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Objetivo de Brandtour'>
                  <Select
                    disabled={brandtourTargets.length === 0}
                    onChange={value => setBrandTargetBrandtour(value)}
                    placeholder='¿Cual objetivo para Brandtour?'
                  >
                    {brandtourTargets.map(target => (
                      <Option key={target} value={target}>
                        {target}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Objetivo de Corporate'>
                  <Select
                    disabled={corporateTargets.length === 0}
                    onChange={value => setBrandTargetCorporate(value)}
                    placeholder='¿Cual objetivo para Corporate?'
                  >
                    {corporateTargets.map(target => (
                      <Option key={target} value={target}>
                        {target}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CreateBrandKit
                  setBrandTags={setBrandTags}
                  setAudience={setAudience}
                  setColors={setColors}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type='primary' loading={loading} htmlType='submit'>
                  Crear Marca
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </div>
  );
};

export default CreateBrand;
