import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Button, message, Icon } from "antd";
import { db, fieldValue } from "../../firebase.config";
import uuid from "uuid/v4";
import InventoryCategoryCard from "./InventoryCategoryCard";
const InventoryCategories = () => {
  const [categoryName, setCategoryName] = useState("");
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCreateCategory, setLoadingCreateCategory] = useState(false);

  const handleCreateCategory = async e => {
    e.preventDefault();
    if (!categoryName)
      return message.error("El nombre de la categoria es obligatorio");
    setLoadingCreateCategory(true);
    const categoriesRef = db.collection("categories").doc("inventory");
    const id = uuid();
    try {
      await db.runTransaction(async t => {
        const categoriesDoc = await t.get(categoriesRef);
        const categories = categoriesDoc.data();
        if (categoriesDoc.exists && categories.names.includes(categoryName)) {
          let errorMessage = {
            code: "La categoria ya existe",
            message: "La categoria ya existe"
          };
          throw errorMessage;
        }

        t.set(
          categoriesRef,
          {
            categories: fieldValue.arrayUnion({
              id,
              name: categoryName,
              createdAt: Date.now()
            }),
            names: fieldValue.arrayUnion(categoryName)
          },
          { merge: true }
        );
      });
      setCategoryName("");
    } catch (e) {
      console.log(e.message);
      message.error(e.code || "No pudimos crear la categoria");
    }
    setLoadingCreateCategory(false);
  };

  useEffect(() => {
    const unsubscribeToInventoryCategories = db
      .collection("categories")
      .doc("inventory")
      .onSnapshot(doc => {
        if (doc.exists) {
          const documentData = doc.data();
          setCategories(documentData.categories);
        }
        setLoading(false);
      });
    return () => unsubscribeToInventoryCategories();
  }, []);

  return (
    <div className='pageContainer'>
      <div>
        <h1>Crear categoria del inventario</h1>
        <Form onSubmit={handleCreateCategory}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item>
                <Input
                  placeholder='Nombre de la categoria'
                  value={categoryName}
                  onChange={e => setCategoryName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  htmlType='submit'
                  type='primary'
                  loading={loadingCreateCategory}
                >
                  Crear categoria
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className='categoriesListContainer'>
        {loading ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        ) : (
          <>
            {categories.length === 0 && (
              <div style={{ margin: "auto" }}>
                <h2>
                  No encontramos categorias <Icon type='frown' />
                </h2>
              </div>
            )}
            {categories &&
              categories.map(category => (
                <InventoryCategoryCard
                  key={category.name}
                  category={category}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default InventoryCategories;
