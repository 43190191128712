import React, { useState } from "react";
import { storage } from "../../firebase.config";
import { Button } from "antd";

const DeleteBrandfont = ({
  path,
  title,
  disabled,
  isPreview,
  setDeleteState
}) => {
  const [loading, setLoading] = useState(false);
  const deleteBrandFontZip = async () => {
    setLoading(true);
    const storageRef = storage.ref();
    const fileRef = storageRef.child(path);
    try {
      await fileRef.delete();
      if (isPreview) {
        setDeleteState("");
        setLoading(false);
      } else {
        setDeleteState({
          name: "brandFonts.zip",
          fileName: "No hay",
          size: "No hay",
          timeCreated: "No hay",
          fullPath: "",
          downloadUrl: ""
        });
        setLoading(false);
      }
      console.log("file deleted");
    } catch (e) {
      console.log(e);
    }
    //
  };
  return (
    <Button
      loading={loading}
      type="danger"
      size="small"
      disabled={disabled}
      onClick={() => deleteBrandFontZip()}
    >
      {title}
    </Button>
  );
};

export default DeleteBrandfont;
