import React, { useState, useEffect } from "react";
import { Icon, Popover, Button, message } from "antd";
import BrandColor from "./BrandColor";
import { TwitterPicker } from "react-color";
import hexToCMYK from "../../utils/hexToCMYK";
import uuid from "uuid/v4";
const BrandColorsSelector = ({ setColors }) => {
  const [pickerColor, setPickerColor] = useState();
  const [brandColors, setBrandColors] = useState([]);

  const handleAddBrandColor = color => {
    if (!pickerColor) {
      return message.error("Escoge un color porfa", 3);
    }
    const id = uuid();
    setBrandColors([
      ...brandColors,
      {
        id,
        name: "Nombre del color",
        hex: color.hex,
        rgb: color.rgb,
        cmyk: hexToCMYK(color.hex),
        pantone: ""
      }
    ]);
  };

  const handleUpdateBrandColor = color => {
    const indexOfColor = brandColors.findIndex(c => c.id === color.id);
    const newBrandColors = [...brandColors];
    newBrandColors[indexOfColor] = color;
    setBrandColors(newBrandColors);
  };

  const handleRemoveBrandColor = id => {
    const newBrandColors = brandColors.filter(
      brandColor => brandColor.id !== id
    );
    setBrandColors(newBrandColors);
  };

  useEffect(() => {
    setColors(brandColors);
  }, [brandColors, setColors]);

  return (
    <div style={{ margin: "30px 0px" }}>
      <Popover
        trigger="click"
        placement="right"
        content={
          <>
            <TwitterPicker
              triangle="hide"
              color={pickerColor}
              onChangeComplete={color => setPickerColor(color)}
            />
            <Button
              type="primary"
              block
              onClick={() => handleAddBrandColor(pickerColor)}
            >
              Agregar color
            </Button>
          </>
        }
      >
        <div className="colorCircle">
          <Icon type="plus" style={{ margin: "auto" }} />
        </div>
      </Popover>
      <div className="brandColorsList">
        {brandColors.map(color => (
          <BrandColor
            key={color.id}
            color={color}
            id={color.id}
            removeColor={handleRemoveBrandColor}
            updateColor={handleUpdateBrandColor}
          />
        ))}
      </div>
    </div>
  );
};

export default BrandColorsSelector;
