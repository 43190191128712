import React, { useState } from "react";
import { Button, message, Modal } from "antd";
import { withRouter } from "react-router-dom";
import { db, fieldValue } from "../../firebase.config";
const { confirm } = Modal;
const DeleteComplement = ({ complement, history }) => {
  const [loading, setLoading] = useState(false);
  const confirmDelete = () => {
    confirm({
      title: "¿Seguro que deseas borrar este elemento?",
      content:
        "Si lo borras ya nadie podra usarlo, aun asi, quedara registrado en las salidas que lo usaron",
      okText: "Si, con toda",
      okType: "danger",
      cancelText: "No gracias",
      async onOk() {
        await handleDeleteComplement();
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  const handleDeleteComplement = async () => {
    setLoading(true);
    const complementRef = db.collection("complements").doc(complement.id);
    const categoriesRef = db.collection("categories").doc("complements");

    try {
      await db.runTransaction(async transaction => {
        const complementToDelete = await transaction.get(complementRef);
        const complementToDeleteData = complementToDelete.data();
        if (
          complementToDeleteData.reservedDates &&
          complementToDeleteData.reservedDates.length > 0
        ) {
          let errorMessage = {
            code: "element-in-outputs",
            message: "El elemento esta presente en salidas"
          };
          throw errorMessage;
        }
        transaction.delete(complementRef);
        transaction.update(categoriesRef, {
          [complement.categoryId]: fieldValue.increment(-1)
        });
      });

      history.push("/complementos/elementos");
    } catch (e) {
      console.log(e.message);
      if (e.code === "element-in-outputs") {
        message.error("El complemento esta presente en salidas sin completar");
      } else {
        message.error("No pudimos borrar el complemento");
      }
      setLoading(false);
    }
  };
  return (
    <div>
      <Button ghost type="danger" onClick={confirmDelete} loading={loading}>
        Borrar elemento
      </Button>
    </div>
  );
};

export default withRouter(DeleteComplement);
