import React from "react";
import { Input, InputNumber, Icon } from "antd";

const BrandColor = ({ color, removeColor, updateColor }) => {
  const handleNameChange = newName => {
    updateColor({ ...color, name: newName });
  };
  const handleHexChange = newHex => {
    updateColor({ ...color, hex: newHex });
  };
  const handlePantoneChange = newPantone => {
    updateColor({ ...color, pantone: newPantone });
  };
  const handleRGBAColorChange = letterToChange => {
    updateColor({
      id: color.id,
      name: color.name,
      hex: color.hex,
      rgb: {
        ...color.rgb,
        ...letterToChange
      },
      cmyk: color.cmyk,
      pantone: color.pantone
    });
  };

  const handleCMYKColorChange = letterToChange => {
    updateColor({
      id: color.id,
      name: color.name,
      hex: color.hex,
      cmyk: {
        ...color.cmyk,
        ...letterToChange
      },
      rgb: color.rgb,
      pantone: color.pantone
    });
  };

  return (
    <div
      style={{
        margin: "20px 10px",
        width: 300,
        textAlign: "center",
        border: "1px dashed #222",
        borderRadius: 15,
        padding: 10
      }}
    >
      <div style={{ textAlign: "right" }}>
        <Icon type="close" onClick={() => removeColor(color.id)} />
      </div>
      <div
        className="colorCircle"
        style={{ backgroundColor: color.hex, margin: "auto" }}
      />
      <div className="colorInput">
        <h4>{color.name}</h4>
        <Input
          onChange={e => handleNameChange(e.target.value)}
          placeholder="Nombre del color"
        />
      </div>
      <div style={{ display: "flex" }}>
        <div className="colorInput">
          R
          <InputNumber
            max={255}
            min={0}
            value={color.rgb.r}
            placeholder="R"
            size="small"
            onChange={value => handleRGBAColorChange({ r: value })}
          />
        </div>
        <div className="colorInput">
          G
          <InputNumber
            placeholder="G"
            size="small"
            value={color.rgb.g}
            onChange={value => handleRGBAColorChange({ g: value })}
          />
        </div>
        <div className="colorInput">
          B
          <InputNumber
            placeholder="B"
            size="small"
            value={color.rgb.b}
            onChange={value => handleRGBAColorChange({ b: value })}
          />
        </div>
        <div className="colorInput">
          A
          <InputNumber
            placeholder="A"
            size="small"
            value={color.rgb.a}
            onChange={value => handleRGBAColorChange({ a: value })}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="colorInput">
          C
          <InputNumber
            formatter={value => `${value}%`}
            placeholder="C"
            size="small"
            value={color.cmyk.c}
            onChange={value => handleCMYKColorChange({ c: value })}
          />
        </div>
        <div className="colorInput">
          M
          <InputNumber
            formatter={value => `${value}%`}
            placeholder="M"
            size="small"
            value={color.cmyk.m}
            onChange={value => handleCMYKColorChange({ m: value })}
          />
        </div>
        <div className="colorInput">
          Y
          <InputNumber
            formatter={value => `${value}%`}
            placeholder="Y"
            size="small"
            value={color.cmyk.y}
            onChange={value => handleCMYKColorChange({ y: value })}
          />
        </div>
        <div className="colorInput">
          K
          <InputNumber
            formatter={value => `${value}%`}
            placeholder="K"
            size="small"
            value={color.cmyk.k}
            onChange={value => handleCMYKColorChange({ k: value })}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="colorInput">
          Hexadecimal
          <Input
            value={color.hex}
            onChange={e => handleHexChange(e.target.value)}
            placeholder="Hexadecimal"
            size="small"
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="colorInput">
          Pantone
          <Input
            placeholder="Pantone"
            size="small"
            value={color.pantone}
            onChange={e => handlePantoneChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default BrandColor;
