import React, { useEffect, useState } from "react";
import { db } from "../../firebase.config";
import { Icon, Tag } from "antd";
import SerieCard from "./SerieCard";
import { useStoreActions, useStoreState } from "easy-peasy";
import "./series.css";
const AllSeries = () => {
  const [seriesList, setSeriesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentQuery, setCurrentQuery] = useState("");
  const fetchBrandsList = useStoreActions(
    actions => actions.brands.fetchBrandsList
  );
  const brandsList = useStoreState(state => state.brands.brands);
  const fetchSeries = async brandId => {
    setLoading(true);
    let seriesRef;
    if (brandId) {
      seriesRef = db
        .collection("brands")
        .doc(brandId)
        .collection("series");
    } else {
      seriesRef = db.collectionGroup("series");
    }
    try {
      const seriesDocs = await seriesRef.get();
      const series = seriesDocs.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setSeriesList(series);
      setLoading(false);
    } catch (e) {
      console.log(e.message);
      // message.error("No pudimos cargar las series");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSeries();
    fetchBrandsList();
  }, [fetchBrandsList]);

  useEffect(() => {
    fetchSeries(currentQuery);
  }, [currentQuery]);
  return (
    <div className='pageContainer'>
      <h1>Todas las series</h1>
      <div>
        <small>Marcas: </small>
        <Tag
          onClick={() => setCurrentQuery("")}
          color={currentQuery === "" ? "#222" : null}
        >
          Todas
        </Tag>
        {brandsList.map(brand => (
          <Tag
            onClick={() => setCurrentQuery(brand.id)}
            color={currentQuery === brand.id ? brand.mainColor : null}
            key={brand.id}
          >
            {brand.name}
          </Tag>
        ))}
      </div>
      <div className='seriesList'>
        {loading ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        ) : (
          <>
            {seriesList.length === 0 && (
              <div style={{ margin: "auto" }}>
                <h2>
                  No encontramos series <Icon type='frown' />
                </h2>
              </div>
            )}
            {seriesList.map(serie => (
              <SerieCard serie={serie} key={serie.id} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default AllSeries;
