import React, { useState, useEffect, useCallback } from "react";
import { Form, InputNumber, Checkbox } from "antd";

const CityPriceInput = ({
  city,
  aditionalServiceCities,
  setAditionalServiceCities,
  setAditionalServiceCitiesPrices,
  aditionalServiceCitiesPrices
}) => {
  const [disableCity, setDisableCity] = useState(false);
  const [price, setPrice] = useState(0);
  const handleDisableCity = useCallback(
    disable => {
      if (disable) {
        const newAditionalServiceCities = aditionalServiceCities.filter(
          c => c !== city
        );
        setAditionalServiceCities(newAditionalServiceCities);
      } else {
        setAditionalServiceCities([...aditionalServiceCities, city]);
      }
    },
    [city, aditionalServiceCities, setAditionalServiceCities]
  );

  useEffect(() => {
    const cityIndex = aditionalServiceCities.findIndex(c => c === city);
    if (cityIndex === -1) {
      setDisableCity(true);
    } else {
      setDisableCity(false);
    }
  }, [aditionalServiceCities, city]);

  useEffect(() => {
    const cityIndex = aditionalServiceCitiesPrices.findIndex(
      c => c.city === city
    );
    if (cityIndex !== -1)
      setPrice(aditionalServiceCitiesPrices[cityIndex].price);
  }, [aditionalServiceCitiesPrices, city]);

  const handlePriceChange = value => {
    if (!disableCity) {
      const cityPriceIndex = aditionalServiceCitiesPrices.findIndex(
        c => c.city === city
      );
      if (cityPriceIndex === -1) {
        setAditionalServiceCitiesPrices([
          ...aditionalServiceCitiesPrices,
          { city, price: value }
        ]);
      } else {
        const newAditionalServiceCitiesPrices = aditionalServiceCitiesPrices;
        newAditionalServiceCitiesPrices[cityPriceIndex] = {
          city,
          price: value
        };
        setAditionalServiceCitiesPrices(newAditionalServiceCitiesPrices);
      }
      setPrice(value);
    }
  };
  return (
    <div style={{ margin: "0px 10px" }}>
      <Form.Item label={city}>
        <InputNumber
          value={price}
          disabled={disableCity}
          onChange={value => handlePriceChange(value)}
          formatter={value =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={value => value.replace(/\$\s?|(,*)/g, "")}
          style={{ width: 130 }}
          min={0}
        />
        <div>
          <Checkbox
            checked={!disableCity}
            onChange={e => handleDisableCity(!e.target.checked)}
          />
          <small> Habilitar ciudad</small>
        </div>
      </Form.Item>
    </div>
  );
};

export default CityPriceInput;
