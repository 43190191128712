import React, { useState, useEffect } from "react";
import { Avatar, Tag, Collapse, Icon, Modal, message } from "antd";
import { useStoreState, useStoreActions } from "easy-peasy";
import { functions } from "../../firebase.config";
import formatTimestamp from "../../utils/formatTimestamp";
import EditUserForm from "./EditUserForm";
import userErrorMessage from "../../utils/userErrorMessages";
const { Panel } = Collapse;
const { confirm } = Modal;

const UserCard = ({ user, lastQuery }) => {
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState("");
  const { fetchUsers } = useStoreActions(actions => actions.users);
  const documentId = useStoreState(state => state.user.documentId);
  const deleteUser = async () => {
    const deleteUserFunction = functions.httpsCallable("deleteUser");
    try {
      const response = await deleteUserFunction({
        email: user.email,
        documentId: user.id
      });
      if (response.data.status === "error") {
        setError(response.data.code);
      }
      fetchUsers({
        orderBy: lastQuery.orderBy,
        brand: lastQuery.brand,
        company: lastQuery.company
      });
    } catch (e) {
      setError(e.code);
      console.log(e);
    }
  };
  const handleDelete = () => {
    confirm({
      title: `¿Estas seguro que quieres borrar a ${user.email}`,
      content:
        "Una vez lo borres, el usuario ya no podra acceder a KeepMe. La informacion de la marca y las salidas permanecerá intacta",
      okText: "Si, sin mente",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await deleteUser(user);
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  //if error show error message
  useEffect(() => {
    if (error) message.error(userErrorMessage(error), 3);
  }, [error]);

  return (
    <div className='userCard'>
      <div style={{ display: "flex" }}>
        {documentId !== user.id && (
          <Icon
            type='close'
            shape='circle'
            style={{ fontSize: 12 }}
            onClick={() => handleDelete()}
          />
        )}

        <Icon
          type='edit'
          shape='circle'
          style={{ fontSize: 14, marginLeft: "auto" }}
          onClick={() => setEditMode(true)}
        />
      </div>

      <div style={{ display: "flex", marginBottom: 10 }}>
        <Avatar style={{ margin: "auto" }} size={64} icon='user' />
      </div>

      <h3 style={{ textAlign: "center" }}>{user.name}</h3>
      <p style={{ textAlign: "center" }}>{user.email}</p>
      <div style={{ marginBottom: 10, textAlign: "center" }}>
        {user.isAdmin && <Tag color='green'>Administrador</Tag>}
        {user.company && <Tag>{user.company}</Tag>}
        <div>
          {user.brands &&
            user.brands.map(brand => (
              <Tag key={brand.id} color={brand.mainColor}>
                {brand.name}
              </Tag>
            ))}
        </div>
      </div>
      <Collapse bordered={false} accordion>
        <Panel header='mas info' key='1'>
          {!user.isAdmin && (
            <>
              <div>
                <small>Telefono: {user.phone}</small>
              </div>
              <div>
                <small>Direccion: {user.address}</small>
              </div>
            </>
          )}

          <div>
            <small>creado en: {formatTimestamp(user.createdAt)}</small>
          </div>
          <div>
            <small>actualizado en: {formatTimestamp(user.updatedAt)}</small>
          </div>
          <div>
            <small>ultimo login: {formatTimestamp(user.lastSession)}</small>
          </div>
        </Panel>
      </Collapse>

      <Modal
        title='Editar Usuario'
        visible={editMode}
        onCancel={() => setEditMode(false)}
        footer={null}
        destroyOnClose
        centered
      >
        <EditUserForm
          user={user}
          setEditMode={setEditMode}
          lastQuery={lastQuery}
        />
      </Modal>
    </div>
  );
};

export default UserCard;
