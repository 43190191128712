import React, { useState } from "react";
import { Upload, Button, Icon, message, Progress } from "antd";
import Compressor from "compressorjs";
import { storage, db, fieldValue } from "../../firebase.config";

const UploadOutputGallery = ({ title, id, brandId }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [progressVisible, setProgressVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const beforeUpload = file => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Solo puedes subir imagenes en PNG o JPG");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("La imagen no puede ser mayor a un 1MB");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleUpload = async ({ file, onSuccess }) => {
    const storageRef = storage.ref();
    const fileRef = storageRef.child(
      `brands/${brandId}/outputs/${id}/gallery/${file.name}`
    );
    setLoading(true);

    //compressor
    new Compressor(file, {
      quality: 0.75,
      maxWidth: 1920,
      convertSize: 1000000,
      success(result) {
        setProgressVisible(true);
        const uploadTask = fileRef.put(result);
        uploadTask.on(
          "state_changed",
          snapshot => {
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(Math.trunc(progress));
            console.log("Upload is " + progress + "% done");
          },
          error => {
            setLoading(false);
            setProgressVisible(false);
            message.error(error, 3);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(async downloadUrl => {
              if (downloadUrl) {
                const outputRef = db
                  .collection("brands")
                  .doc(brandId)
                  .collection("outputs")
                  .doc(id);
                await outputRef.update({
                  gallery: fieldValue.arrayUnion(downloadUrl)
                });
                onSuccess();
              }
            });
          }
        );
      },

      error(err) {
        setProgressVisible(false);
        setLoading(false);
        message.error("No pudimos cargar la imagen");
        console.log(err.message);
      }
    });

    //compressor
  };
  return (
    <>
      <Upload
        beforeUpload={beforeUpload}
        multiple={false}
        customRequest={handleUpload}
        fileList={[]}
        onRemove={e => console.log(e)}
      >
        <Button loading={loading} type='primary' size='small'>
          <Icon type='upload' /> {title}
        </Button>
        {progressVisible && <Progress percent={uploadProgress} size='small' />}
      </Upload>
    </>
  );
};

export default UploadOutputGallery;
