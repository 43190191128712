import React, { useState, useEffect } from "react";
import { Avatar, Icon, Tag, Modal, message } from "antd";
import { useStoreActions } from "easy-peasy";
import { functions } from "../../firebase.config";
import { Link } from "react-router-dom";
import EditCompanyForm from "./EditCompanyForm";
import formatTimestamp from "../../utils/formatTimestamp";
import dbErrorMessages from "../../utils/dbErrorMessages";
const { confirm } = Modal;
const CompanyCard = ({ company }) => {
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState("");
  const { setUpdateCompanyState } = useStoreActions(
    actions => actions.companies
  );
  const handleCompanyDelete = () => {
    confirm({
      title: `¿Estas seguro que quieres borrar la empresa ${company.name}`,
      content:
        "Recuerda que solo puedes borrar empresas si no tienen asignadas marcas o usuarios.",
      okText: "Vamo'a darle",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await deleteCompany();
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };
  const deleteCompany = async () => {
    const deleteCompanyFunction = functions.httpsCallable("deleteCompany");
    try {
      const response = await deleteCompanyFunction({
        companyId: company.id
      });
      console.log(response);
      if (response.data.status === "error") {
        setError(response.data.code);
      }
    } catch (e) {
      setError(e.code);
      console.log(e);
    }
  };

  useEffect(() => {
    if (error) message.error(dbErrorMessages(error), 3);
  }, [error]);
  return (
    <div className='companyCard'>
      <>
        <div style={{ display: "flex" }}>
          {(!company.brands || company.brands.length === 0) && (
            <Icon
              type='close'
              shape='circle'
              style={{ fontSize: 12 }}
              onClick={() => handleCompanyDelete()}
            />
          )}

          <Icon
            type='edit'
            shape='circle'
            style={{ fontSize: 14, marginLeft: "auto" }}
            onClick={() => setEditMode(true)}
          />
        </div>

        <Avatar style={{ margin: "auto" }} size={64} icon='shop' />
        <h2>{company.name}</h2>
        <small>creada en: {formatTimestamp(company.createdAt)}</small>
        <div>
          {company.brands &&
            company.brands.map(brand => (
              <Link key={brand.id} to={`/marca/${brand.id}`}>
                <Tag color={brand.mainColor}>{brand.name}</Tag>
              </Link>
            ))}
        </div>
      </>

      <Modal
        title='Editar Empresa'
        visible={editMode}
        onCancel={() => setEditMode(false)}
        footer={null}
        afterClose={() =>
          setUpdateCompanyState({
            loading: false,
            error: "",
            success: false
          })
        }
        destroyOnClose
      >
        <EditCompanyForm company={company} setEditMode={setEditMode} />
      </Modal>
    </div>
  );
};

export default CompanyCard;
