import React from "react";
import { Link } from "react-router-dom";
import { Icon, Tag, Button } from "antd";

const KeepmePointsProductCard = ({ element }) => {
  return (
    <div className='keepmepointsCard'>
      <div style={{ height: 300, display: "flex" }}>
        <div
          className='elementCardImage'
          style={{
            backgroundImage: `url(${element.mainImage || null})`,
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}
        >
          {element && !element.mainImage && (
            <Icon type='picture' style={{ margin: "auto", fontSize: 20 }} />
          )}
        </div>
      </div>
      <div style={{ padding: 20 }}>
        <h2>
          {element.name} {!element.isPublic && <Icon type='eye-invisible' />}
        </h2>

        <div style={{ marginBottom: 20 }}>
          <Tag color='#222'>{element.category}</Tag>
          <div>
            <p>{element.description}</p>
          </div>
          <div>
            <p>
              {" "}
              <strong>Cantidad disponible: </strong>
              {element.units}
            </p>
          </div>
        </div>
        <Link to={`/catalogo-keepmepoints/${element.id}`}>
          <Button type='primary' block>
            Ver elemento de KeepmePoints
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default KeepmePointsProductCard;
