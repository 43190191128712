import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useStoreActions } from "easy-peasy";
export const AuthenticatedRoute = ({
  isAuthenticated,
  isAdmin,
  component: Component,
  title,
  ...rest
}) => {
  const setPageTitle = useStoreActions(actions => actions.user.setPageTitle);
  useEffect(() => {
    setPageTitle(title || "Titulo");
  }, [title, setPageTitle]);
  if (isAuthenticated && !isAdmin) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )}
      />
    );
  } else if (isAuthenticated && isAdmin) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  } else {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )}
      />
    );
  }
};
