import React, { useState } from "react";
import { Upload, Icon, Button, message } from "antd";
import { storage } from "../../firebase.config";
import { useStoreActions } from "easy-peasy";
import DeleteBrandFile from "./DeleteBrandFile";
import formatBytes from "../../utils/formatBytes";
import formatTimestamp from "../../utils/formatTimestamp";

const { Dragger } = Upload;

const UploadBrandFiles = ({ id, directory, files, setFiles, downloadFile }) => {
  const [fileList, setFileList] = useState([]);
  const updateBrandDate = useStoreActions(
    actions => actions.brands.updateBrandDate
  );
  const handleChange = info => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);
    setFileList(newFileList);
  };

  const handleUpload = async ({ file, onSuccess }) => {
    const storageRef = storage.ref();
    const fileRef = storageRef.child(`${id}/${directory}/${file.name}`);
    try {
      const snapshot = await fileRef.put(file);
      const downloadUrl = await snapshot.ref.getDownloadURL();
      const metadata = snapshot.metadata;

      setFiles([
        {
          key: metadata.name,
          nombre: metadata.name,
          descargas: "5",
          peso: formatBytes(metadata.size),
          fecha: formatTimestamp(Date.parse(metadata.timeCreated)),
          vencimiento: formatTimestamp(Date.parse(metadata.timeCreated)),
          descargar: (
            <Button
              type='primary'
              size='small'
              onClick={() => downloadFile(downloadUrl, metadata.name)}
            >
              Descargar
            </Button>
          ),
          borrar: <DeleteBrandFile path={metadata.fullPath} id={id} />
        },
        ...files
      ]);

      setFileList([]);
      updateBrandDate(id);
      onSuccess();
    } catch (e) {
      message.error(e.message);
      console.log(e.message);
    }
  };
  return (
    <div style={{ marginTop: 20 }}>
      <Dragger
        name='file'
        multiple={false}
        customRequest={handleUpload}
        onChange={handleChange}
        fileList={fileList}
        onRemove={e => console.log(e)}
      >
        <p className='ant-upload-drag-icon'>
          <Icon type='inbox' />
        </p>
        <p className='ant-upload-text'>
          Haz click o arrastra los archivos para subirlos
        </p>
        <p className='ant-upload-hint'>Espera a que carguen completamente.</p>
      </Dragger>
    </div>
  );
};

export default UploadBrandFiles;
