import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Table } from 'antd';
import { storage } from '../../firebase.config';
import BrandFontPreview from './BrandFontPreview';
import UploadBrandFont from './UploadBrandFont';
import DeleteBrandFont from './DeleteBrandFont';
import formatBytes from '../../utils/formatBytes';
import formatTimestamp from '../../utils/formatTimestamp';

const BrandFonts = ({ currentBrand }) => {
  const [brandFontPreviewImg, setBrandFontPreviewImg] = useState('');
  const [brandFontsZip, setBrandFontsZip] = useState({
    name: 'brandFonts.zip',
    size: 'No hay',
    fileName: 'No hay',
    timeCreated: 'No hay',
    fullPath: '',
    downloadUrl: ''
  });

  const data = [
    {
      key: brandFontsZip.name,
      nombre: <strong>{brandFontsZip.name}</strong>,
      nombreArchivo: brandFontsZip.fileName,
      peso: brandFontsZip.size,
      fecha: brandFontsZip.timeCreated,
      subir: (
        <UploadBrandFont
          id={currentBrand.id}
          directory='brandFonts'
          fileName='brandFonts.zip'
          extension='application/zip'
          setUploadState={setBrandFontsZip}
        />
      ),
      descargar: (
        <Button
          icon='download'
          type='primary'
          disabled={!brandFontsZip.downloadUrl}
          size='small'
          onClick={() =>
            downloadFile(brandFontsZip.downloadUrl, brandFontsZip.name)
          }>
          Descargar
        </Button>
      ),
      borrar: (
        <DeleteBrandFont
          path={brandFontsZip.fullPath}
          title='Borrar'
          disabled={!brandFontsZip.downloadUrl}
          setDeleteState={setBrandFontsZip}
          isPreview={false}
        />
      )
    }
  ];

  useEffect(() => {
    const getBrandFonts = async () => {
      const storageRef = storage.ref();
      const brandFontsRef = storageRef.child(`${currentBrand.id}/brandFonts`);
      try {
        const brandFonts = await brandFontsRef.listAll();
        brandFonts.items.forEach(async item => {
          if (item.name === 'brandFontPreview.png') {
            const previewUrl = await item.getDownloadURL();
            setBrandFontPreviewImg(previewUrl);
          }
          if (item.name === 'brandFonts.zip') {
            const zipUrl = await item.getDownloadURL();
            const metadata = await item.getMetadata();
            setBrandFontsZip({
              name: item.name,
              fileName: metadata.customMetadata
                ? metadata.customMetadata.originalFileName
                : 'No Hay',
              size: formatBytes(metadata.size),
              timeCreated: formatTimestamp(Date.parse(metadata.timeCreated)),
              fullPath: metadata.fullPath,
              downloadUrl: zipUrl
            });
          }
        });
      } catch (e) {
        console.log(e.message);
      }
    };
    getBrandFonts();
  }, [currentBrand.id]);

  return (
    <Row>
      <h2>Brand Fonts</h2>
      <Col span={12}>
        <BrandFontPreview imageUrl={brandFontPreviewImg} />
      </Col>
      <Col span={12}>
        <div>
          <h3>Brand Font Preview</h3>
          <UploadBrandFont
            id={currentBrand.id}
            directory='brandFonts'
            fileName='brandFontPreview.png'
            extension='image/png'
            isPreview={true}
            setUploadState={setBrandFontPreviewImg}
          />
          <h4>Descargar Brand Font Preview</h4>
          <Button
            disabled={!brandFontPreviewImg}
            icon='download'
            type='primary'
            size='small'
            onClick={() =>
              downloadFile(brandFontPreviewImg, 'brandFontPreview.png')
            }>
            Descargar Preview
          </Button>
          <h4>Borrar Brand Font Preview</h4>
          <DeleteBrandFont
            title='Borrar Brand Font Preview'
            disabled={!brandFontPreviewImg}
            isPreview={true}
            setDeleteState={setBrandFontPreviewImg}
            path={`${currentBrand.id}/brandFonts/brandFontPreview.png`}
          />
        </div>
      </Col>
      <div>
        <h3> Brand Font Zip</h3>
        <Table
          columns={columns}
          dataSource={data}
          size='middle'
          style={{
            backgroundColor: '#fff',
            borderRadius: 10,
            marginTop: 20
          }}
          pagination={{ hideOnSinglePage: true }}
        />
      </div>
    </Row>
  );
};

const downloadFile = (url, filename) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = () => {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
  };
  xhr.open('GET', url);
  xhr.send();
};

const columns = [
  {
    title: 'Nombre de descarga',
    dataIndex: 'nombre',
    key: 'nombre'
  },
  {
    title: 'Nombre de archivo',
    dataIndex: 'nombreArchivo',
    key: 'nombreArchivo'
  },
  {
    title: 'Peso',
    dataIndex: 'peso',
    key: 'peso'
  },

  {
    title: 'Fecha de creacion',
    dataIndex: 'fecha',
    key: 'fecha'
  },
  {
    title: 'Subir',
    dataIndex: 'subir',
    key: 'subir'
  },
  {
    title: 'Descargar',
    dataIndex: 'descargar',
    key: 'descargar'
  },
  {
    title: 'Borrar',
    dataIndex: 'borrar',
    key: 'borrar'
  }
];

export default BrandFonts;
