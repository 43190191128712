import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Icon,
  Select,
  message,
  Switch
} from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import CreateUserSuccess from "./CreateUserSuccess";
import userErrorMessage from "../../utils/userErrorMessages";
const { Option } = Select;

const CreateUser = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [company, setCompany] = useState("");
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const { createUser, setCreateUserState } = useStoreActions(
    actions => actions.users
  );
  const { loading, error, success, newUser } = useStoreState(
    state => state.users.createUserState
  );
  const { companies, loadingCompanies } = useStoreState(
    state => state.companies
  );
  const fetchCompaniesList = useStoreActions(
    actions => actions.companies.fetchCompaniesList
  );

  // get companies from database and store it in global store
  useEffect(() => {
    if (companies.length === 0) fetchCompaniesList();
  }, [fetchCompaniesList, companies]);

  //set the list of brands for each single company change
  useEffect(() => {
    const currentCompany = companies.filter(comp => comp.name === company);
    if (currentCompany.length > 0) {
      const brands = currentCompany[0].brands;
      if (brands && brands[0].name) {
        setBrands(brands);
        setSelectedBrands([]);
      } else {
        setBrands([]);
        setSelectedBrands([]);
      }
    }
  }, [company, companies]);

  //if error show error message
  useEffect(() => {
    if (error)
      message.error(userErrorMessage(error), 3, () =>
        setCreateUserState({
          loading: false,
          error: "",
          success: false,
          newUser: {
            email: "",
            password: ""
          }
        })
      );
  }, [error, setCreateUserState]);

  //if success reset al fields
  useEffect(() => {
    if (success) {
      setName("");
      setEmail("");
      setCompany("");
      setPhone("");
      setAddress("");
      setSelectedBrands([]);
    }
  }, [success]);

  //handle submit form for creating using, checking if the fields are not empty
  const handleCreateUser = e => {
    console.log(isAdmin);
    e.preventDefault();
    if (isAdmin) {
      if (!email || !name) {
        setCreateUserState({
          loading: false,
          error: "Nombre e Email son obligatorios",
          success: false,
          newUser: {
            email: "",
            password: ""
          }
        });
        return;
      }
    } else {
      if (!email || !name || !company || selectedBrands.length === 0) {
        setCreateUserState({
          loading: false,
          error: "Nombre Email, Empresa y marcas son obligatorios",
          success: false,
          newUser: {
            email: "",
            password: ""
          }
        });
        return;
      }
    }
    const brandsNames = selectedBrands.map(brand => brand.name);
    const currentCompany = companies.filter(comp => comp.name === company)[0];
    createUser({
      email,
      name,
      phone,
      address,
      isAdmin,
      company,
      companyId: currentCompany ? currentCompany.id : "",
      companyCreatedAt: currentCompany ? currentCompany.createdAt : "",
      brands: selectedBrands,
      brandsNames
    });
  };

  //change brands list from string to object
  const handleSelectBrands = values => {
    const newSelectedBrands = values.map(value => JSON.parse(value));
    setSelectedBrands(newSelectedBrands);
  };

  return (
    <div className='pageContainer'>
      {success ? (
        <CreateUserSuccess email={newUser.email} password={newUser.password} />
      ) : (
        <>
          <p>
            Vamos a crear un usuario, primero debes escribir algunos datos
            basicos:
          </p>
          <Form onSubmit={handleCreateUser}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label='Nombre *'>
                  <Input
                    prefix={
                      <Icon
                        type='user-add'
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    placeholder='Nombre y apellidos del usuario'
                    type='text'
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Email *'>
                  <Input
                    prefix={
                      <Icon type='mail' style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder='Correo electronico del usuario'
                    type='email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Tipo de rol'>
                  <small>¿Es administrador?: </small>{" "}
                  <Switch
                    checked={isAdmin}
                    onChange={checked => setIsAdmin(checked)}
                  />
                </Form.Item>
              </Col>
            </Row>
            {!isAdmin && (
              <>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label='Telefono'>
                      <Input
                        prefix={
                          <Icon
                            type='phone'
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder='Telefono del usuario'
                        type='text'
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label='Direccion'>
                      <Input
                        prefix={
                          <Icon
                            type='compass'
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder='Dirección de correspondencia'
                        type='text'
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label='Empresa *'>
                      <Select
                        showSearch
                        disabled={loadingCompanies}
                        loading={loadingCompanies}
                        placeholder='Escoge una empresa'
                        optionFilterProp='children'
                        onChange={value => setCompany(value)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {companies.map(company => (
                          <Option key={company.id} value={company.name}>
                            {company.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label='Marcas *'>
                      <Select
                        mode='multiple'
                        value={selectedBrands.map(brand =>
                          JSON.stringify(brand)
                        )}
                        showArrow
                        disabled={!!!company}
                        loading={loadingCompanies}
                        placeholder='Escoge las marcas'
                        optionFilterProp='children'
                        onChange={values => handleSelectBrands(values)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {brands.map(brand => (
                          <Option key={brand.id} value={JSON.stringify(brand)}>
                            {brand.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col>
                <Button type='primary' loading={loading} htmlType='submit'>
                  Crear usuario
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </div>
  );
};

export default CreateUser;
