import React, { useEffect, useState } from "react";
import { Row, Col, Icon, Table, Button, Switch, message } from "antd";
import { withRouter } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { db } from "../../firebase.config";
import UploadToLogoBrand from "./UploadToLogoBrand";
import DeleteBrandLogo from "./DeleteBrandLogo";
import formatTimestamp from "../../utils/formatTimestamp";
import formatBytes from "../../utils/formatBytes";
import BrandLogoPreview from "./BrandLogoPreview";

const BrandLogos = ({ currentBrand }) => {
  const [logoPoliPreview, setLogoPoliPreview] = useState(null);
  const [logoTintaPreview, setLogoTintaPreview] = useState(null);
  const [logoPoliPNG, setLogoPoliPNG] = useState({
    displayName: "No hay",
    name: "No hay",
    creationDate: "No hay",
    size: "No hay",
    downloadUrl: "",
    fullPath: ""
  });
  const [logoPoliJPG, setLogoPoliJPG] = useState({
    displayName: "No hay",
    name: "No hay",
    creationDate: "No hay",
    size: "No hay",
    downloadUrl: "",
    fullPath: ""
  });
  const [logoPoliAI, setLogoPoliAI] = useState({
    displayName: "No hay",
    name: "No hay",
    creationDate: "No hay",
    size: "No hay",
    downloadUrl: "",
    fullPath: ""
  });
  const [logoTintaPNG, setLogoTintaPNG] = useState({
    displayName: "No hay",
    name: "No hay",
    creationDate: "No hay",
    size: "No hay",
    downloadUrl: "",
    fullPath: ""
  });
  const [logoTintaJPG, setLogoTintaJPG] = useState({
    displayName: "No hay",
    name: "No hay",
    creationDate: "No hay",
    size: "No hay",
    downloadUrl: "",
    fullPath: ""
  });
  const [logoTintaAI, setLogoTintaAI] = useState({
    displayName: "No hay",
    name: "No hay",
    creationDate: "No hay",
    size: "No hay",
    downloadUrl: "",
    fullPath: ""
  });
  const {
    brandLogosAssets,
    fetchBrandLogosAssetsState: { loading }
  } = useStoreState(state => state.brands);
  const { fetchBrandLogosAssets } = useStoreActions(actions => actions.brands);

  useEffect(() => {
    fetchBrandLogosAssets({ id: currentBrand.id });
  }, [currentBrand.id, fetchBrandLogosAssets]);

  useEffect(() => {
    setLogoPoliPreview(currentBrand.logoPoliPreview);
    setLogoTintaPreview(currentBrand.logoTintaPreview);
  }, [currentBrand]);

  useEffect(() => {
    if (brandLogosAssets.length > 0) {
      brandLogosAssets.forEach(logo => {
        if (logo.name === "logo-poli.png") {
          setLogoPoliPNG({
            ...logo,
            creationDate: formatTimestamp(logo.creationDate),
            size: formatBytes(logo.size)
          });
        }
        if (logo.name === "logo-poli.jpg") {
          setLogoPoliJPG({
            ...logo,
            creationDate: formatTimestamp(logo.creationDate),
            size: formatBytes(logo.size)
          });
        }
        if (logo.name === "logo-poli.ai") {
          setLogoPoliAI({
            ...logo,
            creationDate: formatTimestamp(logo.creationDate),
            size: formatBytes(logo.size)
          });
        }
        if (logo.name === "logo-tinta.png") {
          setLogoTintaPNG({
            ...logo,
            creationDate: formatTimestamp(logo.creationDate),
            size: formatBytes(logo.size)
          });
        }
        if (logo.name === "logo-tinta.jpg") {
          setLogoTintaJPG({
            ...logo,
            creationDate: formatTimestamp(logo.creationDate),
            size: formatBytes(logo.size)
          });
        }
        if (logo.name === "logo-tinta.ai") {
          setLogoTintaAI({
            ...logo,
            creationDate: formatTimestamp(logo.creationDate),
            size: formatBytes(logo.size)
          });
        }
      });
    }
  }, [brandLogosAssets]);

  const downloadLogo = (url, filename) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = () => {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = filename;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
    };
    xhr.open("GET", url);
    xhr.send();
  };

  const handleLogoPoliPreviewChange = async url => {
    const previousUrl = logoPoliPreview;
    setLogoPoliPreview(url);
    const brandRef = db.collection("brands").doc(currentBrand.id);
    try {
      await brandRef.update({ logoPoliPreview: url, updatedAt: Date.now() });
      console.log("logo poli preview updated");
    } catch (e) {
      console.log(e.message);
      message.error("No se pudo actualizar el logo principal");
      setLogoPoliPreview(previousUrl);
    }
  };

  const handleLogoTintaPreviewChange = async url => {
    const previousUrl = logoTintaPreview;
    setLogoTintaPreview(url);
    const brandRef = db.collection("brands").doc(currentBrand.id);
    try {
      await brandRef.update({ logoTintaPreview: url, updatedAt: Date.now() });
      console.log("logo tinta preview updated");
    } catch (e) {
      console.log(e.message);
      message.error("No se pudo actualizar el logo principal 1 tinta");
      setLogoTintaPreview(previousUrl);
    }
  };

  const data = [
    {
      key: "logoPoliPNG",
      principal: (
        <Switch
          disabled={!logoPoliPNG.downloadUrl}
          checked={logoPoliPreview === logoPoliPNG.downloadUrl}
          onChange={checked =>
            handleLogoPoliPreviewChange(logoPoliPNG.downloadUrl)
          }
        />
      ),
      tipo: <strong>Logo poli PNG</strong>,
      nombre: logoPoliPNG.displayName,
      creacion: logoPoliPNG.creationDate,
      tamano: logoPoliPNG.size,
      subir: (
        <UploadToLogoBrand
          title='Subir'
          id={currentBrand.id}
          fileName='logo-poli.png'
          directory='brandLogos'
          setUploadState={setLogoPoliPNG}
          extension='image/png'
        />
      ),
      descargar: (
        <Button
          type='primary'
          icon='download'
          size='small'
          onClick={() =>
            downloadLogo(logoPoliPNG.downloadUrl, logoPoliPNG.displayName)
          }
          disabled={!logoPoliPNG.downloadUrl}
        >
          Descargar
        </Button>
      ),
      borrar: (
        <DeleteBrandLogo
          path={logoPoliPNG.fullPath}
          setDeleteState={setLogoPoliPNG}
          isPreview={logoPoliPreview === logoPoliPNG.downloadUrl}
          changePreview={handleLogoPoliPreviewChange}
        />
      )
    },
    {
      key: "logoPoliJPG",
      principal: (
        <Switch
          checked={logoPoliPreview === logoPoliJPG.downloadUrl}
          disabled={!logoPoliJPG.downloadUrl}
          onChange={checked =>
            handleLogoPoliPreviewChange(logoPoliJPG.downloadUrl)
          }
        />
      ),
      tipo: <strong>Logo poli JPG</strong>,
      nombre: logoPoliJPG.displayName,
      creacion: logoPoliJPG.creationDate,
      tamano: logoPoliJPG.size,
      subir: (
        <UploadToLogoBrand
          title='Subir'
          id={currentBrand.id}
          fileName='logo-poli.jpg'
          directory='brandLogos'
          setUploadState={setLogoPoliJPG}
          extension='image/jpeg'
        />
      ),
      descargar: (
        <Button
          type='primary'
          icon='download'
          size='small'
          onClick={() =>
            downloadLogo(logoPoliJPG.downloadUrl, logoPoliJPG.displayName)
          }
          disabled={!logoPoliJPG.downloadUrl}
        >
          Descargar
        </Button>
      ),
      borrar: (
        <DeleteBrandLogo
          path={logoPoliJPG.fullPath}
          setDeleteState={setLogoPoliJPG}
          isPreview={logoPoliPreview === logoPoliJPG.downloadUrl}
          changePreview={handleLogoPoliPreviewChange}
        />
      )
    },
    {
      key: "logoPoliAI",
      tipo: <strong>Logo poli AI</strong>,
      nombre: logoPoliAI.displayName,
      creacion: logoPoliAI.creationDate,
      tamano: logoPoliAI.size,
      subir: (
        <UploadToLogoBrand
          title='Subir'
          id={currentBrand.id}
          fileName='logo-poli.ai'
          directory='brandLogos'
          setUploadState={setLogoPoliAI}
          extension='application/illustrator'
        />
      ),
      descargar: (
        <Button
          type='primary'
          icon='download'
          size='small'
          onClick={() =>
            downloadLogo(logoPoliAI.downloadUrl, logoPoliAI.displayName)
          }
          disabled={!logoPoliAI.downloadUrl}
        >
          Descargar
        </Button>
      ),
      borrar: (
        <DeleteBrandLogo
          path={logoPoliAI.fullPath}
          setDeleteState={setLogoPoliAI}
        />
      )
    },
    {
      key: "logoTintaPNG",
      principal: (
        <Switch
          checked={logoTintaPreview === logoTintaPNG.downloadUrl}
          disabled={!logoTintaPNG.downloadUrl}
          onChange={checked =>
            handleLogoTintaPreviewChange(logoTintaPNG.downloadUrl)
          }
        />
      ),
      tipo: <strong>Logo 1 tinta PNG</strong>,
      nombre: logoTintaPNG.displayName,
      creacion: logoTintaPNG.creationDate,
      tamano: logoTintaPNG.size,
      subir: (
        <UploadToLogoBrand
          title='Subir'
          id={currentBrand.id}
          fileName='logo-tinta.png'
          directory='brandLogos'
          setUploadState={setLogoTintaPNG}
          extension='image/png'
        />
      ),
      descargar: (
        <Button
          type='primary'
          icon='download'
          size='small'
          onClick={() =>
            downloadLogo(logoTintaPNG.downloadUrl, logoTintaPNG.displayName)
          }
          disabled={!logoTintaPNG.downloadUrl}
        >
          Descargar
        </Button>
      ),
      borrar: (
        <DeleteBrandLogo
          path={logoTintaPNG.fullPath}
          setDeleteState={setLogoTintaPNG}
          isPreview={logoTintaPreview === logoTintaPNG.downloadUrl}
          changePreview={handleLogoTintaPreviewChange}
        />
      )
    },
    {
      key: "logoTintaJPG",
      principal: (
        <Switch
          checked={logoTintaPreview === logoTintaJPG.downloadUrl}
          disabled={!logoTintaJPG.downloadUrl}
          onChange={checked =>
            handleLogoTintaPreviewChange(logoTintaJPG.downloadUrl)
          }
        />
      ),
      tipo: <strong>Logo 1 tinta JPG</strong>,
      nombre: logoTintaJPG.displayName,
      creacion: logoTintaJPG.creationDate,
      tamano: logoTintaJPG.size,
      subir: (
        <UploadToLogoBrand
          title='Subir'
          id={currentBrand.id}
          fileName='logo-tinta.jpg'
          directory='brandLogos'
          setUploadState={setLogoTintaJPG}
          extension='image/jpeg'
        />
      ),
      descargar: (
        <Button
          type='primary'
          icon='download'
          size='small'
          onClick={() =>
            downloadLogo(logoTintaJPG.downloadUrl, logoTintaJPG.displayName)
          }
          disabled={!logoTintaJPG.downloadUrl}
        >
          Descargar
        </Button>
      ),
      borrar: (
        <DeleteBrandLogo
          path={logoTintaJPG.fullPath}
          setDeleteState={setLogoTintaJPG}
          isPreview={logoTintaPreview === logoTintaJPG.downloadUrl}
          changePreview={handleLogoTintaPreviewChange}
        />
      )
    },
    {
      key: "logoTintaAI",
      tipo: <strong>Logo 1 tinta AI</strong>,
      nombre: logoTintaAI.displayName,
      creacion: logoTintaAI.creationDate,
      tamano: logoTintaAI.size,
      subir: (
        <UploadToLogoBrand
          title='Subir'
          id={currentBrand.id}
          fileName='logo-tinta.ai'
          directory='brandLogos'
          setUploadState={setLogoTintaAI}
          extension='application/illustrator'
        />
      ),
      descargar: (
        <Button
          type='primary'
          icon='download'
          size='small'
          onClick={() =>
            downloadLogo(logoTintaAI.downloadUrl, logoTintaAI.displayName)
          }
          disabled={!logoTintaAI.downloadUrl}
        >
          Descargar
        </Button>
      ),
      borrar: (
        <DeleteBrandLogo
          path={logoTintaAI.fullPath}
          setDeleteState={setLogoTintaAI}
        />
      )
    }
  ];
  return (
    <>
      <div className='logoPreviewContainer'>
        <Row gutter={16}>
          <Col span={24}>
            <h2>Brand Logos</h2>
            <div style={{ display: "flex" }}>
              <BrandLogoPreview
                title='Logo preview Policromia'
                imageUrl={logoPoliPreview}
              />

              <BrandLogoPreview
                title='Logo preview 1 tinta'
                imageUrl={logoTintaPreview}
              />
            </div>
          </Col>
          <Col span={24}>
            {loading ? (
              <div style={{ width: "100%", height: 300, display: "flex" }}>
                <Icon type='loading' style={{ margin: "auto", fontSize: 60 }} />
              </div>
            ) : (
              <Table
                columns={columns}
                dataSource={data}
                size='middle'
                pagination={false}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 10
                }}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const columns = [
  {
    title: "Hacer principal",
    dataIndex: "principal",
    key: "principal"
  },
  {
    title: "Tipo de logo",
    dataIndex: "tipo",
    key: "tipo"
  },
  {
    title: "Nombre archivo",
    dataIndex: "nombre",
    key: "nombre"
  },
  {
    title: "Fecha creacion",
    dataIndex: "creacion",
    key: "creacion"
  },
  {
    title: "Tamaño",
    dataIndex: "tamano",
    key: "tamano"
  },
  {
    title: "Subir",
    dataIndex: "subir",
    key: "subir"
  },
  {
    title: "Descargar",
    dataIndex: "descargar",
    key: "descargar"
  },
  {
    title: "Borrar",
    dataIndex: "borrar",
    key: "borrar"
  }
];

export default withRouter(BrandLogos);
