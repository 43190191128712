import React, { useEffect, useState } from "react";
import { Icon, Modal, message } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import BrandProfileColorsForm from "./BrandProfileColorsForm";
import dbErrorMessages from "../../utils/dbErrorMessages";

const BrandProfileColors = ({ currentBrand }) => {
  const [editMode, setEditMode] = useState(false);
  const { error } = useStoreState(state => state.brands.updateBrandColorsState);
  const setUpdateBrandColorsState = useStoreActions(
    actions => actions.brands.setUpdateBrandColorsState
  );

  //if error show error message
  useEffect(() => {
    if (error)
      message.error(dbErrorMessages(error), 3, () =>
        setUpdateBrandColorsState({
          loading: false,
          error: "",
          success: false
        })
      );
  }, [error, setUpdateBrandColorsState]);
  return (
    <>
      <div>
        <Icon
          type='edit'
          style={{ fontSize: 14 }}
          onClick={() => setEditMode(true)}
        />
        <h2>Brand Colors</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap"
          }}
        >
          {currentBrand.brandColors &&
            currentBrand.brandColors.map(color => (
              <div key={color.id} className='brandProfileColorsCard'>
                <div
                  className='colorCircleShadow'
                  style={{
                    backgroundColor: color.hex
                  }}
                />
                <div className='brandProfileColorsContent'>
                  <h3>{color.name}</h3>
                  <p>
                    <strong>Hex:</strong> {color.hex}
                  </p>
                  <p>
                    <strong>Pantone:</strong> {color.pantone}
                  </p>
                  <p>
                    <strong>RGB:</strong>{" "}
                    {`R:${color.rgb.r} G:${color.rgb.g} B:${color.rgb.b} A:${
                      color.rgb.a
                    }`}
                  </p>
                  <p>
                    <strong>CMYK:</strong>{" "}
                    {`C:${color.cmyk.c} M:${color.cmyk.m} Y:${color.cmyk.y} K:${
                      color.cmyk.k
                    }`}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Modal
        title='Editar Brand Colors de la marca'
        visible={editMode}
        onCancel={() => setEditMode(false)}
        footer={null}
        destroyOnClose
        width='90%'
        centered
        afterClose={() => {
          setUpdateBrandColorsState({
            loading: false,
            error: "",
            success: false
          });
        }}
      >
        <BrandProfileColorsForm
          brand={currentBrand}
          setEditMode={setEditMode}
        />
      </Modal>
    </>
  );
};

export default BrandProfileColors;
