import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Icon, Button, message } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import dbErrorMessages from "../../utils/dbErrorMessages";
const CreateCompany = () => {
  const [name, setName] = useState("");

  const { loading, error, success } = useStoreState(
    state => state.companies.createCompanyState
  );

  const { createCompany, setCreateCompanyState } = useStoreActions(
    actions => actions.companies
  );

  //if error show error message
  useEffect(() => {
    if (error) message.error(dbErrorMessages(error), 3);
    return () =>
      setCreateCompanyState({
        loading: false,
        error: "",
        success: false,
        companyId: ""
      });
  }, [error, setCreateCompanyState]);

  //if success reset al fields
  useEffect(() => {
    if (success) {
      setName("");
    }
  }, [success]);

  const handleCreateCompany = e => {
    e.preventDefault();
    if (!name)
      return setCreateCompanyState({
        loading: false,
        error: "Llena el campo de nombre",
        success: false,
        companyId: ""
      });
    createCompany({ name });
  };
  return (
    <div>
      <Form onSubmit={handleCreateCompany} layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item>
              <Input
                prefix={
                  <Icon type="shop" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Nombre de la empresa"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" loading={loading} htmlType="submit">
                Crear empresa
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreateCompany;
