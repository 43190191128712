import { action, thunk } from "easy-peasy";

export default {
  complements: [],
  categories: [],
  loadingComplements: true,
  createComplementState: {
    loading: false,
    success: false,
    error: ""
  },
  fetchCategoriesState: {
    loading: true,
    success: false,
    error: ""
  },
  //thunks
  fetchComplementsList: thunk(async (actions, payload, { injections }) => {
    const { db } = injections;
    const snapshot = await db.collection("complements").get();
    const brands = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    actions.setComplementsList(brands);
    actions.setLoadingComplements(false);
  }),
  createComplement: thunk(async (actions, payload, { injections }) => {
    actions.setCreateComplementState({
      loading: true,
      error: "",
      success: false
    });
    const { db, fieldValue } = injections;
    const categoriesRef = db.collection("categories").doc("complements");
    const complementsRef = db.collection("complements").doc();
    const batch = db.batch();
    try {
      batch.set(complementsRef, {
        ...payload
      });
      batch.update(categoriesRef, {
        [payload.categoryId]: fieldValue.increment(1)
      });
      await batch.commit();
      actions.setCreateComplementState({
        loading: false,
        error: "",
        success: true,
        newComplementId: complementsRef.id
      });
    } catch (e) {
      console.log(e.message);
      actions.setCreateComplementState({
        loading: false,
        error: e.code || "Tuvimos un error inesperado",
        success: false
      });
    }
  }),
  fetchCategories: thunk(async (actions, payload, { injections }) => {
    actions.setFetchCategoriesState({
      loading: true,
      error: "",
      success: false
    });
    const { db } = injections;
    const categoriesRef = db.collection("categories").doc("complements");
    try {
      const categoriesDoc = await categoriesRef.get();
      const categoriesData = categoriesDoc.data();
      if (categoriesData.categories.length > 0) {
        const categories = categoriesData.categories.map(category => {
          return {
            ...category,
            count: categoriesData[category.id]
          };
        });
        actions.setCategories(categories);
      }
      actions.setFetchCategoriesState({
        loading: false,
        error: "",
        success: true
      });
    } catch (e) {
      console.log(e.message);
      actions.setFetchCategoriesState({
        loading: false,
        error: e.code,
        success: false
      });
    }
  }),
  //Actions
  setComplementsList: action((state, payload) => {
    state.complements = payload;
  }),
  setCategories: action((state, payload) => {
    state.categories = payload;
  }),
  setFetchCategoriesState: action((state, payload) => {
    state.fetchCategoriesState = payload;
  }),
  setLoadingComplements: action((state, payload) => {
    state.loadingComplements = payload;
  }),
  setCreateComplementState: action((state, payload) => {
    state.createComplementState = payload;
  })
};
