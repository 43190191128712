import React, { useState, useEffect } from "react";
import { Tag, Checkbox, Row, Col, Icon, message } from "antd";
import { useStoreState, useStoreActions } from "easy-peasy";

const BrandTags = ({ setBrandTags, limit, initialTags }) => {
  const [tags, setTags] = useState(initialTags);
  const [disableCheckbox, setDisableCheckbox] = useState(false);
  const {
    brandTags,
    fetchBrandTagsState: { loading, error }
  } = useStoreState(state => state.brands);
  const { fetchBrandTags } = useStoreActions(actions => actions.brands);
  const handleTagClose = tag => {
    const newTags = tags.filter(t => t !== tag);
    setTags(newTags);
  };
  useEffect(() => {
    fetchBrandTags();
  }, [fetchBrandTags]);

  useEffect(() => {
    setBrandTags(tags);
  }, [tags, setBrandTags]);

  useEffect(() => {
    if (!disableCheckbox && tags.length >= limit) {
      setDisableCheckbox(true);
    } else if (disableCheckbox && tags.length < limit) {
      setDisableCheckbox(false);
    }
  }, [disableCheckbox, tags, limit]);

  useEffect(() => {
    if (error) message.error("No pudimos cargar los atributos");
  }, [error]);

  return (
    <>
      <div style={{ minHeight: 40 }}>
        {tags.map(tag => (
          <Tag
            value={tag}
            key={tag}
            closable
            onClose={() => {
              handleTagClose(tag);
            }}
          >
            {tag}
          </Tag>
        ))}
      </div>

      <div style={{ display: "flex" }}>
        {loading ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        ) : (
          <>
            {brandTags.length === 0 && (
              <div style={{ margin: "auto" }}>
                <h2>
                  No encontramos atributos <Icon type='frown' />
                </h2>
              </div>
            )}
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={checkedValues => setTags(checkedValues)}
              value={tags}
              disabled={disableCheckbox}
            >
              <Row>
                {brandTags &&
                  brandTags.map(tag => (
                    <Col key={tag} span={4}>
                      <Checkbox value={tag}>{tag}</Checkbox>
                    </Col>
                  ))}
              </Row>
            </Checkbox.Group>
          </>
        )}
      </div>
    </>
  );
};

BrandTags.defaultProps = {
  limit: 10,
  initialTags: []
};

export default BrandTags;
