import React, { useState, useEffect } from "react";
import { TwitterPicker } from "react-color";
import { Form, Input, Select, Button, Row, Col, Divider, message } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import BrandProfileFormSuccess from "./BrandProfileFormSuccess";
import { db } from "../../firebase.config";

const { Option } = Select;

const BrandProfileGeneralInfoForm = ({ brand, setEditMode }) => {
  const [name, setName] = useState(brand.name);
  const [mainColor, setMainColor] = useState(brand.mainColor);
  const [brandType, setBrandType] = useState(brand.brandType);
  const [brandTargetExpo, setBrandTargetExpo] = useState(
    brand.brandTargetExpo || undefined
  );
  const [brandTargetBrandtour, setBrandTargetBrandtour] = useState(
    brand.brandTargetBrandtour || undefined
  );
  const [brandTargetCorporate, setBrandTargetCorporate] = useState(
    brand.brandTargetCorporate || undefined
  );
  const [expoTargets, setExpoTargets] = useState([]);
  const [brandtourTargets, setBrandtourTargets] = useState([]);
  const [corporateTargets, setCorporateTargets] = useState([]);
  const { loading, success } = useStoreState(
    state => state.brands.updateBrandGeneralInfoState
  );
  const {
    updateBrandGeneralInfo,
    setUpdateBrandGeneralInfoState
  } = useStoreActions(actions => actions.brands);

  //get outputTypes and goals for brand targets
  useEffect(() => {
    const getTypes = async () => {
      const typesRef = db.collection("information").doc("outputTypesAndGoals");
      try {
        const typesDoc = await typesRef.get();
        console.log("Called fetch types");
        const typesData = typesDoc.data();
        let expo = [];
        let brandtour = [];
        let corporate = [];
        if (typesData.types && typesData.types.length > 0) {
          typesData.types.forEach(type => {
            if (type.channel === "Expo") expo.push(...type.goals);
            if (type.channel === "Corporate") corporate.push(...type.goals);
            if (type.channel === "Brandtour") brandtour.push(...type.goals);
          });
        }
        setExpoTargets([...new Set(expo)]);
        setBrandtourTargets([...new Set(brandtour)]);
        setCorporateTargets([...new Set(corporate)]);
      } catch (e) {
        console.log(e.message);
      }
    };
    getTypes();
  }, []);
  const handleSubmit = e => {
    e.preventDefault();
    if (!name) return message.error("El nombre es obligatorio");
    if (!brandType) return message.error("El tipo de marca es obligatorio");
    if (!brandTargetExpo)
      return message.error("El objetivo expo es obligatorio");
    if (!brandTargetBrandtour)
      return message.error("El objetivo brandtour es obligatorio");
    if (!brandTargetCorporate)
      return message.error("El objetivo corporate es obligatorio");

    updateBrandGeneralInfo({
      id: brand.id,
      name,
      mainColor,
      brandType,
      brandTargetExpo,
      brandTargetCorporate,
      brandTargetBrandtour,
      companyId: brand.companyId,
      oldName: brand.name
    });
  };
  return (
    <div className='updateBrandProfileGeneralInfoForm'>
      {success ? (
        <BrandProfileFormSuccess
          setEditMode={setEditMode}
          brandId={brand.id}
          resetState={setUpdateBrandGeneralInfoState}
        />
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Item label='Nombre'>
            <Input
              value={name}
              placeholder='Escoge un nombre'
              onChange={e => setName(e.target.value)}
            />
          </Form.Item>
          <Form.Item label='Color principal'>
            <div style={{ display: "flex" }}>
              <div
                className='colorCircleShadow'
                style={{
                  backgroundColor: mainColor,
                  margin: "auto"
                }}
              />
              <TwitterPicker
                triangle='hide'
                width='90%'
                color={mainColor}
                onChangeComplete={color => setMainColor(color.hex)}
              />
            </div>
          </Form.Item>
          <Form.Item label='Tipo de marca'>
            <Select
              showSearch
              value={brandType}
              placeholder='Escoge un tipo de marca'
              optionFilterProp='children'
              onChange={value => setBrandType(value)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value='Superbrand'>Superbrand</Option>
              <Option value='Specialized'>Specialized</Option>
            </Select>
          </Form.Item>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label='Objetivo de Expo'>
                <Select
                  disabled={expoTargets.length === 0}
                  value={brandTargetExpo}
                  onChange={value => setBrandTargetExpo(value)}
                  placeholder='¿Cual objetivo para Expo?'
                >
                  {expoTargets.map(target => (
                    <Option key={target} value={target}>
                      {target}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Objetivo de Brandtour'>
                <Select
                  disabled={brandtourTargets.length === 0}
                  onChange={value => setBrandTargetBrandtour(value)}
                  placeholder='¿Cual objetivo para Brandtour?'
                >
                  {brandtourTargets.map(target => (
                    <Option key={target} value={target}>
                      {target}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Objetivo de Corporate'>
                <Select
                  disabled={corporateTargets.length === 0}
                  onChange={value => setBrandTargetCorporate(value)}
                  placeholder='¿Cual objetivo para Corporate?'
                >
                  {corporateTargets.map(target => (
                    <Option key={target} value={target}>
                      {target}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Form.Item>
            <Button block type='primary' htmlType='submit' loading={loading}>
              Actualizar
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default BrandProfileGeneralInfoForm;
