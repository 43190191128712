import React, { useState } from "react";
import { Divider, Collapse } from "antd";
import AditionalServicesCities from "./AditionalServicesCities";
import CreateAditionalServices from "./CreateAditionalServices";
import AditionalServicesList from "./AditionalServicesList";

const { Panel } = Collapse;
const AditionalServices = () => {
  const [citiesList, setCitiesList] = useState([]);
  return (
    <div className='pageContainer'>
      <AditionalServicesCities setCitiesList={setCitiesList} />
      <div>
        <Divider />
        <Collapse bordered={false} defaultActiveKey={["-1"]}>
          <Panel header='Crear servicio adicional' key='1'>
            <CreateAditionalServices citiesList={citiesList} />
          </Panel>
        </Collapse>
      </div>
      <div>
        <Divider />
        <h1>Servicios adicionales</h1>
        <AditionalServicesList citiesList={citiesList} />
      </div>
    </div>
  );
};

export default AditionalServices;
