import { action, thunk } from "easy-peasy";

export default {
  brands: [],
  brandTags: [],
  brandLogosAssets: [],
  brandFilesAssets: [],
  currentBrand: {},
  loadingBrands: true,
  createBrandState: {
    loading: false,
    error: "",
    success: false,
    brandId: ""
  },
  getBrandByIdState: {
    loading: false,
    error: "",
    success: false
  },
  updateBrandColorsState: {
    loading: false,
    error: "",
    success: false
  },
  updateBrandGeneralInfoState: {
    loading: false,
    error: "",
    success: false
  },
  updateBrandTagsState: {
    loading: false,
    error: "",
    success: false
  },
  fetchBrandLogosAssetsState: {
    loading: true,
    error: "",
    success: false
  },
  fetchBrandFilesAssetsState: {
    loading: true,
    error: "",
    success: false
  },
  fetchBrandTagsState: {
    loading: true,
    error: "",
    success: false
  },

  //thunks
  fetchBrandsList: thunk(async (actions, payload, { injections }) => {
    const { db } = injections;
    const snapshot = await db
      .collection("brands")
      .limit(100)
      .get();
    const brands = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    actions.setBrandsList(brands);
    actions.setLoadingBrands(false);
  }),
  getBrandById: thunk(async (actions, payload, { injections }) => {
    actions.setCurrentBrand({});
    actions.setGetBrandByIdState({
      loading: true,
      error: "",
      success: false
    });
    const { db } = injections;
    try {
      const brandRef = await db
        .collection("brands")
        .doc(payload.brandId)
        .get();
      if (brandRef.exists) {
        actions.setCurrentBrand({ id: brandRef.id, ...brandRef.data() });
        actions.setGetBrandByIdState({
          loading: false,
          error: "",
          success: true
        });
      } else {
        actions.setGetBrandByIdState({
          loading: false,
          error: "No encontramos la marca que buscas",
          success: false
        });
      }
    } catch (e) {
      console.log(e.message);
      actions.setGetBrandByIdState({
        loading: false,
        error: e.code,
        success: false
      });
    }
  }),
  createBrand: thunk(async (actions, payload, { injections }) => {
    if (!payload.companyId)
      return actions.setCreateBrandState({
        loading: false,
        error: "La marca debe tener asignada una empresa",
        success: false
      });
    actions.setCreateBrandState({
      loading: true,
      error: "",
      success: false
    });
    const { db, fieldValue } = injections;
    try {
      const brandsRef = db
        .collection("brands")
        .where("name", "==", payload.name);
      const brandsWithSameName = await brandsRef.get();
      if (brandsWithSameName.size > 0) {
        return actions.setCreateBrandState({
          loading: false,
          error: "El nombre de esta marca ya existe",
          success: false
        });
      }
      const batch = db.batch();
      const newBrandRef = db.collection("brands").doc();
      const companyRef = db.collection("companies").doc(payload.companyId);

      //create new brand
      batch.set(newBrandRef, {
        ...payload,
        notified: false,
        createdAt: Date.now()
      });

      //update company
      batch.update(companyRef, {
        brands: fieldValue.arrayUnion({
          id: newBrandRef.id,
          name: payload.name,
          mainColor: payload.mainColor || "#f50"
        })
      });

      await batch.commit();
      console.log("Added brand with ID: ", newBrandRef.id);
      actions.setCreateBrandState({
        loading: false,
        error: "",
        success: true,
        brandId: newBrandRef.id
      });
    } catch (e) {
      console.log(e.code);
      actions.setCreateBrandState({
        loading: false,
        error: e.code,
        success: false,
        brandId: ""
      });
    }
  }),

  updateBrandGeneralInfo: thunk(async (actions, payload, { injections }) => {
    actions.setUpdateBrandGeneralInfoState({
      loading: true,
      error: "",
      success: false
    });
    const { db, fieldValue } = injections;
    try {
      if (payload.oldName !== payload.name) {
        const brandsRef = db
          .collection("brands")
          .where("name", "==", payload.name);
        const brandsWithSameName = await brandsRef.get();
        if (brandsWithSameName.size > 0) {
          return actions.setUpdateBrandGeneralInfoState({
            loading: false,
            error: "El nombre de esta marca ya existe",
            success: false
          });
        }
      }

      const batch = db.batch();
      const usersRef = db
        .collection("users")
        .where("brandsNames", "array-contains", payload.oldName);
      const companyRef = db.collection("companies").doc(payload.companyId);
      const brandRef = db.collection("brands").doc(payload.id);
      const seriesRef = db
        .collection("brands")
        .doc(payload.id)
        .collection("series");
      //new brand onject for users and companies
      const newBrand = {
        id: payload.id,
        mainColor: payload.mainColor,
        name: payload.name
      };

      const company = await companyRef.get();
      const companyBrandsArray = company.data().brands;
      const indexOfBrand = companyBrandsArray.findIndex(
        brand => brand.id === payload.id
      );

      companyBrandsArray[indexOfBrand] = newBrand;

      //update the brands array with all new info
      batch.update(companyRef, { brands: companyBrandsArray });

      //check users with same brand name, added update action in batch operation and check if name has changed

      const usersToUpdate = await usersRef.get();
      const usersToUpdateSize = usersToUpdate.size;
      if (usersToUpdateSize > 0) {
        usersToUpdate.docs.forEach(doc => {
          const userBrandsArray = doc.data().brands;
          const indexOfBrand = userBrandsArray.findIndex(
            brand => brand.id === payload.id
          );
          userBrandsArray[indexOfBrand] = newBrand;

          //update brand in brands array
          batch.update(doc.ref, {
            brands: userBrandsArray
          });
          if (payload.oldName !== payload.name) {
            //update brandsNames array
            batch.update(doc.ref, {
              brandsNames: fieldValue.arrayUnion(payload.name)
            });
            batch.update(doc.ref, {
              brandsNames: fieldValue.arrayRemove(payload.oldName)
            });
          }
        });
      }

      //check series in the brand and update
      const seriesToUpdate = await seriesRef.get();
      const seriesToUpdateSize = seriesToUpdate.size;
      if (seriesToUpdateSize > 0) {
        seriesToUpdate.docs.forEach(doc => {
          batch.update(doc.ref, {
            brandName: payload.name,
            brandColor: payload.mainColor
          });
        });
      }

      //update new brand
      batch.update(brandRef, { ...payload, updatedAt: Date.now() });

      //commit the whole thing for batch writing, even all get modify or none get modify
      await batch.commit();
      console.log("updated brand");
      actions.setUpdateBrandGeneralInfoState({
        loading: false,
        error: "",
        success: true
      });
    } catch (e) {
      console.log(e);
      actions.setUpdateBrandGeneralInfoState({
        loading: false,
        error: e.code,
        success: false
      });
    }
  }),

  updateBrandTags: thunk(async (actions, payload, { injections }) => {
    actions.setUpdateBrandTagsState({
      loading: true,
      error: "",
      success: false
    });
    const { db } = injections;
    try {
      const brandRef = db.collection("brands").doc(payload.id);
      await brandRef.update({
        audience: payload.audience,
        brandTags: payload.brandTags,
        updatedAt: Date.now()
      });
      actions.setUpdateBrandTagsState({
        loading: false,
        error: "",
        success: true
      });
    } catch (e) {
      console.log(e.message);
      actions.setUpdateBrandTagsState({
        loading: false,
        error: e.code || e.message || "internal",
        success: false
      });
    }
  }),

  updateBrandColors: thunk(async (actions, payload, { injections }) => {
    actions.setUpdateBrandColorsState({
      loading: true,
      error: "",
      success: false
    });
    const { db } = injections;
    try {
      const brandRef = db.collection("brands").doc(payload.id);
      await brandRef.update({
        brandColors: payload.brandColors,
        updatedAt: Date.now()
      });
      actions.setUpdateBrandColorsState({
        loading: false,
        error: "",
        success: true
      });
    } catch (e) {
      console.log(e.message);
      actions.setUpdateBrandColorsState({
        loading: false,
        error: e.code || e.message || "internal",
        success: false
      });
    }
  }),

  fetchBrandLogosAssets: thunk(async (actions, payload, { injections }) => {
    actions.setBrandLogosAssets([]);
    actions.setFetchBrandLogosAssetsState({
      loading: true,
      error: "",
      success: false
    });
    const { storage } = injections;
    const storageRef = storage.ref();
    const brandLogosRef = storageRef.child(`${payload.id}/brandLogos`);

    try {
      const res = await brandLogosRef.list({ maxResults: 100 });
      let brandLogosPromises = res.items.map(async item => {
        const metadata = await item.getMetadata();
        const downloadUrl = await item.getDownloadURL();
        return {
          name: metadata.name,
          displayName: metadata.customMetadata
            ? metadata.customMetadata.originalFileName
            : metadata.name,
          creationDate: Date.parse(metadata.timeCreated),
          size: metadata.size,
          downloadUrl,
          fullPath: metadata.fullPath
        };
      });
      const brandLogosValues = await Promise.all(brandLogosPromises);
      actions.setBrandLogosAssets(brandLogosValues);
      actions.setFetchBrandLogosAssetsState({
        loading: false,
        error: "",
        success: true
      });
    } catch (e) {
      console.log(e.message);
      actions.setFetchBrandLogosAssetsState({
        loading: false,
        error: e.code,
        success: false
      });
    }
  }),

  fetchBrandFilesAssets: thunk(async (actions, payload, { injections }) => {
    actions.setBrandFilesAssets([]);
    actions.setFetchBrandFilesAssetsState({
      loading: true,
      error: "",
      success: false
    });
    const { storage } = injections;
    const storageRef = storage.ref();
    const brandFilesRef = storageRef.child(`${payload.id}/brandFiles`);

    try {
      const res = await brandFilesRef.list({ maxResults: 100 });
      let brandFilesPromises = res.items.map(async item => {
        const metadata = await item.getMetadata();
        const downloadUrl = await item.getDownloadURL();
        return {
          name: metadata.name,
          creationDate: Date.parse(metadata.timeCreated),
          size: metadata.size,
          downloadUrl,
          fullPath: metadata.fullPath
        };
      });
      const brandFilesValues = await Promise.all(brandFilesPromises);
      actions.setBrandFilesAssets(brandFilesValues);
      actions.setFetchBrandFilesAssetsState({
        loading: false,
        error: "",
        success: true
      });
    } catch (e) {
      console.log(e.message);
      actions.setFetchBrandFilesAssetsState({
        loading: false,
        error: e.code,
        success: false
      });
    }
  }),

  fetchBrandTags: thunk(async (actions, payload, { injections }) => {
    actions.setFetchBrandTagsState({
      loading: true,
      error: "",
      success: false
    });
    const { db } = injections;
    const tagsDocumentRef = db.collection("atributes").doc("tags");
    try {
      const tagsDocument = await tagsDocumentRef.get();
      if (tagsDocument.exists) {
        const tagsDocumentData = tagsDocument.data();
        if (tagsDocumentData.tags) {
          actions.setBrandTags(tagsDocumentData.tags);
        }
      }
      actions.setFetchBrandTagsState({
        loading: false,
        error: "",
        success: true
      });
    } catch (e) {
      console.log(e.message);
      actions.setFetchBrandTagsState({
        loading: false,
        error: e.code || "No pudimos cargar los atributos",
        success: false
      });
    }
  }),
  updateBrandDate: thunk(async (actions, payload, { injections }) => {
    const { db } = injections;
    try {
      if (payload) {
        const brandRef = db.collection("brands").doc(payload);
        await brandRef.update({ updatedAt: Date.now() });
        console.log("updated brand date");
      }
    } catch (e) {
      console.log(e.message);
    }
  }),
  //Actions
  setBrandsList: action((state, payload) => {
    state.brands = payload;
  }),
  setLoadingBrands: action((state, payload) => {
    state.loadingBrands = payload;
  }),
  setCreateBrandState: action((state, payload) => {
    state.createBrandState = payload;
  }),
  setGetBrandByIdState: action((state, payload) => {
    state.getBrandByIdState = payload;
  }),
  setCurrentBrand: action((state, payload) => {
    state.currentBrand = payload;
  }),
  setUpdateBrandColorsState: action((state, payload) => {
    state.updateBrandColorsState = payload;
  }),
  setUpdateBrandTagsState: action((state, payload) => {
    state.updateBrandTagsState = payload;
  }),
  setUpdateBrandGeneralInfoState: action((state, payload) => {
    state.updateBrandGeneralInfoState = payload;
  }),
  setBrandLogosAssets: action((state, payload) => {
    state.brandLogosAssets = payload;
  }),
  setFetchBrandLogosAssetsState: action((state, payload) => {
    state.fetchBrandLogosAssetsState = payload;
  }),
  setBrandFilesAssets: action((state, payload) => {
    state.brandFilesAssets = payload;
  }),
  setFetchBrandFilesAssetsState: action((state, payload) => {
    state.fetchBrandFilesAssetsState = payload;
  }),
  setFetchBrandTagsState: action((state, payload) => {
    state.fetchBrandTagsState = payload;
  }),
  setBrandTags: action((state, payload) => {
    state.brandTags = payload;
  })
};
