import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { db, functions, fieldValue } from "../../firebase.config";
import { Icon, Button, message, Collapse } from "antd";
import { useStoreState } from "easy-peasy";
import FinishOutputElement from "./FinishOutputElement";
import FinishOutputExtraElements from "./FinishOuputExtraElements";
import FinishOutputExtraElementCard from "./FinishOutputExtraElementCard";
const { Panel } = Collapse;

const FinishOutput = ({ match, history }) => {
  const [currentOutput, setCurrentOutput] = useState({});
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [extraElements, setExtraElements] = useState([]);
  const [elementsToUpdate, setElementsToUpdate] = useState([]);
  const [inventoryElementsToUpdate, setInventoryElementsToUpdate] = useState(
    []
  );
  const [complementsToUpdate, setComplementsToUpdate] = useState([]);
  const { documentId } = useStoreState(state => state.user);

  useEffect(() => {
    const outputRef = db
      .collection("brands")
      .doc(match.params.brandId)
      .collection("outputs")
      .doc(match.params.outputId);
    const unsubscribeToElement = outputRef.onSnapshot(doc => {
      if (doc.exists) {
        setLoading(true);
        setCurrentOutput({
          id: doc.id,
          ...doc.data()
        });
      } else {
        setCurrentOutput({});
      }

      setLoading(false);
    });
    return () => unsubscribeToElement();
  }, [match.params]);

  useEffect(() => {
    if (currentOutput.outputState === "approved") {
      let newItems = [];
      if (currentOutput.outputElements) {
        currentOutput.outputElements.forEach(element => {
          const elementIndex = currentOutput.elements.findIndex(
            ele => ele.id === element.id
          );
          newItems.push({
            ...element,
            selectedUnits: currentOutput.elements[elementIndex].selectedUnits,
            store: element.serieName,
            storeType: "elements"
          });
        });
      }
      if (currentOutput.outputInventoryElements) {
        currentOutput.outputInventoryElements.forEach(element => {
          const elementIndex = currentOutput.inventoryElements.findIndex(
            ele => ele.id === element.id
          );
          newItems.push({
            ...element,
            selectedUnits:
              currentOutput.inventoryElements[elementIndex].selectedUnits,
            store: `Inventario - ${element.brandName}`,
            storeType: "inventory"
          });
        });
      }
      if (currentOutput.outputComplements) {
        currentOutput.outputComplements.forEach(complement => {
          const complementIndex = currentOutput.complements.findIndex(
            ele => ele.id === complement.id
          );
          newItems.push({
            ...complement,
            selectedUnits:
              currentOutput.complements[complementIndex].selectedUnits,
            store: "Complementos",
            storeType: "complements"
          });
        });
      }
      setItems(newItems);
      if (currentOutput.outputExtraElements)
        setExtraElements(currentOutput.outputExtraElements);
    }
  }, [currentOutput]);

  const handleFinishOutput = async () => {
    console.log(
      elementsToUpdate,
      inventoryElementsToUpdate,
      complementsToUpdate
    );
    if (
      elementsToUpdate.length +
        inventoryElementsToUpdate.length +
        complementsToUpdate.length !==
      items.length
    )
      return message.error("Debes confirmar la info de todos los elementos");
    try {
      setLoadingFinish(true);
      const finishOutput = functions.httpsCallable("finishOutput");
      const response = await finishOutput({
        elementsToUpdate,
        inventoryElementsToUpdate,
        complementsToUpdate,
        outputId: currentOutput.id,
        brandId: currentOutput.brandId,
        userId: documentId
      });

      if (response.data.status === "error") {
        console.log(response.data.message);
        setLoadingFinish(false);
        message.error("No pudimos completar la salida");
      } else {
        // message.success("Salida finalizada exitosamente");
        history.push(`/salida/${currentOutput.brandId}/${currentOutput.id}`);
      }
    } catch (e) {
      console.log(e.message);
      setLoadingFinish(false);
      message.error("No pudimos completar la salida");
    }

    console.log("Finished output handled");
  };

  const handleRemoveExtraElement = async element => {
    try {
      const outputRef = db
        .collection("brands")
        .doc(currentOutput.brandId)
        .collection("outputs")
        .doc(currentOutput.id);
      await outputRef.update({
        outputExtraElements: fieldValue.arrayRemove(element)
      });
      message.success(`Elemento eliminado`);
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos quitar el elemento");
    }
  };
  return (
    <div className='pageContainer'>
      {loading ? (
        <div style={{ display: "flex" }}>
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        </div>
      ) : (
        <>
          {!currentOutput.id ? (
            <div style={{ margin: "auto" }}>
              <h2>
                No encontramos la salida <Icon type='frown' />
              </h2>
            </div>
          ) : (
            <>
              <div className='outputProfileContainer'>
                <h1 style={{ fontSize: 44 }}>{currentOutput.name}</h1>
                <div>
                  <Collapse bordered={false} defaultActiveKey={["0"]}>
                    <Panel header='Agregar elementos extra a la salida' key='1'>
                      {currentOutput.id && (
                        <FinishOutputExtraElements
                          outputId={currentOutput.id}
                          brandId={currentOutput.brandId}
                        />
                      )}
                    </Panel>
                  </Collapse>
                </div>

                {items.map(item => (
                  <FinishOutputElement
                    key={item.id}
                    item={item}
                    elementsToUpdate={elementsToUpdate}
                    setElementsToUpdate={setElementsToUpdate}
                    inventoryElementsToUpdate={inventoryElementsToUpdate}
                    setInventoryElementsToUpdate={setInventoryElementsToUpdate}
                    setComplementsToUpdate={setComplementsToUpdate}
                    complementsToUpdate={complementsToUpdate}
                  />
                ))}
                {extraElements.map(element => (
                  <FinishOutputExtraElementCard
                    key={element.id}
                    element={element}
                    removeElement={handleRemoveExtraElement}
                  />
                ))}
                <Button
                  loading={loadingFinish}
                  type='primary'
                  onClick={() => handleFinishOutput()}
                >
                  Completar Salida
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default withRouter(FinishOutput);
