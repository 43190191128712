import React from "react";

const BrandLogoPreview = ({ title, imageUrl }) => {
  return (
    <div style={{ margin: 10 }}>
      <h3>{title}</h3>
      <div
        className='brandLogoPreviewImage'
        style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: "cover" }}
      />
      <small>Recomenacion, imagenes de 300x300</small>
    </div>
  );
};

export default BrandLogoPreview;
