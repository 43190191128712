import React from "react";
import CreateCompany from "./CreateCompany";
import CompaniesList from "./CompaniesList";
import "./companies.css";

const Companies = () => {
  return (
    <div className='pageContainer'>
      <h3>Crear empresa</h3>
      <CreateCompany />
      <h3>Todas las empresas</h3>
      <CompaniesList />
    </div>
  );
};

export default Companies;
