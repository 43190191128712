import convert from "color-convert";

export default hex => {
  const CMYKArray = convert.hex.cmyk(hex);
  return {
    c: CMYKArray[0],
    m: CMYKArray[1],
    y: CMYKArray[2],
    k: CMYKArray[3]
  };
};
