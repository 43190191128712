import React, { useState, useEffect } from "react";
import { db } from "../../firebase.config";
import { Divider, Icon, Modal, Popconfirm, message } from "antd";
import CreateAditionalServices from "./CreateAditionalServices";

const priceTypesNames = {
  days: "Dias",
  event: "Evento"
};
const AditionalServicesList = ({ citiesList }) => {
  const [servicesList, setServicesList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [currentService, setCurrentService] = useState({});
  useEffect(() => {
    const unsubscribeToServicesList = db
      .collection("aditionalServices")
      .orderBy("name")
      .onSnapshot(query => {
        if (query.docs.length > 0) {
          let services = [];
          query.docs.forEach(doc => {
            services.push({ id: doc.id, ...doc.data() });
          });
          setServicesList(services);
        }
      });
    return () => unsubscribeToServicesList();
  }, []);

  const handleEditService = service => {
    setCurrentService(service);
    setEditMode(true);
    console.log(service);
  };

  const handleDeleteService = async service => {
    try {
      await db
        .collection("aditionalServices")
        .doc(service.id)
        .delete();
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos eliminar el servicio");
    }
  };
  return (
    <>
      <div>
        <p>Lista de servicios adicionales actuales</p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "colum"
          }}
        >
          {servicesList.map(service => (
            <div key={service.id} className='outputCard'>
              <div style={{ display: "flex" }}>
                <Popconfirm
                  title={`¿Seguro que quieres borrar ${service.name}?`}
                  onConfirm={() => handleDeleteService(service)}
                  // onCancel={cancel}
                  okText='Si'
                  cancelText='No gracias'
                >
                  <Icon type='close' shape='circle' style={{ fontSize: 12 }} />
                </Popconfirm>
                <Icon
                  type='edit'
                  shape='circle'
                  style={{ fontSize: 14, marginLeft: "auto" }}
                  onClick={() => handleEditService(service)}
                />
              </div>
              <h3>{service.name}</h3>
              <small>Ciudades: {service.cities.length}</small>
              <div>
                <small>
                  Tipo de precio: {priceTypesNames[service.priceType]}
                </small>
              </div>
              <Divider />
              <div>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        title='Editar servicio adicional'
        visible={editMode}
        onCancel={() => setEditMode(false)}
        footer={null}
        centered
        destroyOnClose
        width={"90%"}
      >
        <CreateAditionalServices
          citiesList={citiesList}
          currentService={currentService}
          setEditMode={setEditMode}
        />
      </Modal>
    </>
  );
};

export default AditionalServicesList;
