import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Icon, Row, Col, Button, Modal, message } from 'antd';
import BrandProfileGeneralInfo from './BrandProfileGeneralInfo';
import BrandProfileTags from './BrandProfileTags';
import BrandProfileColors from './BrandProfileColors';
import BrandLogos from './BrandLogos';
import BrandFonts from './BrandFonts';
import BrandFiles from './BrandFiles';
import BrandExecutive from './BrandExecutive';
import { functions } from '../../firebase.config';
const { confirm } = Modal;

const BrandProfile = ({ match, history }) => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { getBrandById, setCurrentBrand } = useStoreActions(
    actions => actions.brands
  );
  const {
    getBrandByIdState: { loading, error },
    brands,
    currentBrand
  } = useStoreState(state => state.brands);

  const handleDelete = async () => {
    confirm({
      title: `¿Estas seguro que quieres borrar a ${currentBrand.name}`,
      content:
        'Borra marcas con precaucion, toda la informacion asignada a la marca sera eliminada.',
      okText: 'Si, borrar',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await deleteBrand(`/brands/${currentBrand.id}`);
      },
      onCancel() {
        console.log('Cancel');
      }
    });
  };

  const deleteBrand = async path => {
    setLoadingDelete(true);
    const deleteFn = functions.httpsCallable('recursiveDelete');
    try {
      const response = await deleteFn({ path });
      if (response.data.status === 'success') {
        history.push('/marcas');
      } else {
        message.error('Ocurrió un inconveniente al borrar la marca');
      }
      console.log(response);
    } catch (e) {
      console.log(e.message);
      message.error('No pudimos borar la marca');
    }
  };

  useEffect(() => {
    if (match.params.brandId) {
      getBrandById({ brandId: match.params.brandId });
    }
    return () => setCurrentBrand({});
  }, [getBrandById, match.params.brandId, setCurrentBrand, brands]);

  return (
    <div className='pageContainer'>
      {error ? (
        <div style={{ textAlign: 'center' }}>
          <h2>
            No encontramos la marca que buscas <Icon type='frown' />
          </h2>
        </div>
      ) : (
        <>
          {loading ? (
            <div style={{ display: 'flex' }}>
              <Icon style={{ margin: 'auto', fontSize: 80 }} type='loading' />
            </div>
          ) : (
            <>
              <div className='brandProfileContainer'>
                <Row>
                  <Col span={6}>
                    <BrandProfileGeneralInfo currentBrand={currentBrand} />
                  </Col>
                  <Col span={4}>
                    <BrandProfileTags currentBrand={currentBrand} />
                  </Col>
                  <Col span={14}>
                    <BrandProfileColors currentBrand={currentBrand} />
                  </Col>
                </Row>
              </div>
              <div className='brandProfileContainer'>
                <BrandExecutive currentBrand={currentBrand} />
              </div>
              <div className='brandProfileContainer'>
                <BrandLogos currentBrand={currentBrand} />
              </div>
              <div className='brandProfileContainer'>
                <BrandFonts currentBrand={currentBrand} />
              </div>
              <div className='brandProfileContainer'>
                <BrandFiles currentBrand={currentBrand} />
              </div>
              <div>
                <Button
                  loading={loadingDelete}
                  type='danger'
                  onClick={handleDelete}>
                  Borrar Marca
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default withRouter(BrandProfile);
