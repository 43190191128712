import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Input,
  Row,
  Col,
  InputNumber,
  Typography,
  Switch,
  Select,
  message
} from 'antd';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withRouter } from 'react-router-dom';
import { db } from '../../firebase.config';
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const CreateComplementElement = ({ history }) => {
  const [name, setName] = useState('Nuevo elemento Plus');
  const [units, setUnits] = useState(0);
  const [price, setPrice] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [long, setLong] = useState(0);
  const [diameter, setDiameter] = useState(0);
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [isPhysical, setIsPhysical] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [measureUnits, setMeasureUnits] = useState('mts');
  const [types, setTypes] = useState([]);
  const [forType, setForType] = useState([]);
  const [reference, setReference] = useState('');
  const {
    createComplementState: { loading, error, success, newComplementId },
    categories
  } = useStoreState(state => state.complements);

  const {
    createComplement,
    fetchCategories,
    setCreateComplementState
  } = useStoreActions(actions => actions.complements);
  useEffect(() => {
    if (error) message.error(error, 3);
  }, [error]);
  useEffect(() => {
    if (success && newComplementId) {
      console.log('Creado nuevo complmento con id: ', newComplementId);
      history.push(`/complementos/elementos/${newComplementId}`);
    }
    return () =>
      setCreateComplementState({
        error: '',
        loading: false,
        success: false
      });
  }, [success, newComplementId, history, setCreateComplementState]);

  useEffect(() => {
    const unsubscribeToTypes = db
      .collection('information')
      .doc('outputTypesAndGoals')
      .onSnapshot(doc => {
        const typesData = doc.data();
        setTypes(typesData.types);
      });
    fetchCategories();
    return () => unsubscribeToTypes();
  }, [fetchCategories]);

  useEffect(() => {
    if (forType.length >= 3) {
      const newForType = forType.slice(0, 2);
      setForType(newForType);
      message.warning('Solo puedes escoger 2 tipos de salida');
    }
  }, [forType]);
  const handleSubmit = e => {
    e.preventDefault();

    //some validation of fields
    if (!name) return message.error('El elemento debe tener nombre');
    if (!units)
      return message.error('El elemento debe tener cantidades disponibles');
    if (!price) return message.error('El elemento debe tener precio');
    if (!category)
      return message.error('El elemento debe tener asignada una categoria');
    if (forType.length === 0)
      return message.error(
        'El elemento debe tener al menos un tipo de salida recomendada'
      );
    if (!reference) return message.error('La referencia es obligatoria');

    const categoryId = categories.filter(cat => cat.name === category)[0].id;
    createComplement({
      name,
      units,
      price,
      isPhysical,
      measureUnits,
      width,
      height,
      long,
      diameter,
      description,
      category,
      categoryId,
      isNew,
      isPublic: false,
      isFeatured,
      forType,
      reservedDates: [],
      reference,
      createdAt: Date.now()
    });
  };

  return (
    <div className='pageContainer'>
      <h1>Crear complemento para la tienda Plus</h1>
      <Title>{name}</Title>
      <Form onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Nombre'>
              <Input
                placeholder='Nombre del elemento'
                onChange={e => setName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Cantidades disponibles'>
              <InputNumber
                onChange={value => setUnits(value)}
                placeholder='Unidades'
                style={{ width: '100%' }}
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Precio'>
              <InputNumber
                onChange={value => setPrice(value)}
                formatter={value =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                placeholder='$$$$'
                style={{ width: '100%' }}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='¿Tiene medidas?'>
              <small>¿Agregar dimensiones?</small>{' '}
              <Switch
                checked={isPhysical}
                onChange={checked => setIsPhysical(checked)}
              />{' '}
              <small>Unidades: </small>
              <Select
                onChange={value => setMeasureUnits(value)}
                value={measureUnits}
                style={{ width: 100 }}
                disabled={!isPhysical}>
                <Option value='cms'>cms</Option>
                <Option value='mts'>mts</Option>
              </Select>
            </Form.Item>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label='Alto'>
                  <InputNumber
                    onChange={value => setHeight(value)}
                    disabled={!isPhysical}
                    placeholder={measureUnits}
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='Ancho'>
                  <InputNumber
                    onChange={value => setWidth(value)}
                    disabled={!isPhysical}
                    placeholder={measureUnits}
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='Profundo'>
                  <InputNumber
                    onChange={value => setLong(value)}
                    disabled={!isPhysical}
                    placeholder={measureUnits}
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='Diametro'>
                  <InputNumber
                    onChange={value => setDiameter(value)}
                    disabled={!isPhysical}
                    placeholder={measureUnits}
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Form.Item label='Descripcion'>
              <TextArea
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder='Agrega una breve descripcion de no mas de 140 caracteres, como un Tweet'
                autosize={{ minRows: 6, maxRows: 10 }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Categoria'>
              <Select
                placeholder='Escoge una categoria'
                onChange={value => setCategory(value)}>
                {categories &&
                  categories.map(category => (
                    <Option key={category.id} value={category.name}>
                      {category.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label='¿Recomendado para cual tipo de salida?'>
              <Select
                mode='multiple'
                placeholder='Puedes escoger hasta 2 tipos de salida'
                value={forType}
                onChange={value => setForType(value)}
                style={{ width: '100%' }}>
                {types.map(type => (
                  <Option key={type.type} value={type.type}>
                    {type.type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={4}>
            <Form.Item label='¿Es nuevo?'>
              <Switch checked={isNew} onChange={checked => setIsNew(checked)} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='¿Es destacado?'>
              <Switch
                checked={isFeatured}
                onChange={checked => setIsFeatured(checked)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Referencia'>
              <Input
                value={reference}
                onChange={e => setReference(e.target.value)}
                placeholder='Referencia del elemento'
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={loading}>
            Crear elemento
          </Button>
          <div>
            <small>Luego de que lo crees, puedes agregarle imagenes</small>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default withRouter(CreateComplementElement);
