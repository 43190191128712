import React, { useEffect, useState } from "react";
import { db } from "../../firebase.config";
import { Divider, Icon, Modal, message } from "antd";
import EventCard from "./EventCard";
import CreateEventForm from "./CreateEventForm";
const Events = () => {
  const [availableEvents, setAvailableEventsLits] = useState([]);
  const [oldEvents, setOldEventsLits] = useState([]);
  const [loadingAvailableEvents, setLoadingAvailableEvents] = useState(true);
  const [loadingOldEvents, setLoadingOldEvents] = useState(true);
  const [currentEvent, setCurrentEvent] = useState({});
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const unsubscribeToAvailableEvents = db
      .collection("events")
      .where("startDate", ">=", Date.now())
      .onSnapshot(querySnapshot => {
        let events = [];
        querySnapshot.docs.forEach(doc => {
          events.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setAvailableEventsLits(events);
        setLoadingAvailableEvents(false);
      });

    const unsubscribeToOldEvents = db
      .collection("events")
      .where("startDate", "<=", Date.now())
      .onSnapshot(querySnapshot => {
        let events = [];
        querySnapshot.docs.forEach(doc => {
          events.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setOldEventsLits(events);
        setLoadingOldEvents(false);
      });

    return () => {
      unsubscribeToAvailableEvents();
      unsubscribeToOldEvents();
    };
  }, []);

  const handleEditEvent = event => {
    setCurrentEvent(event);
    setEditMode(true);
  };

  const handleDeleteEvent = async event => {
    try {
      await db
        .collection("events")
        .doc(event.id)
        .delete();
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos eliminar el evento");
    }
  };
  return (
    <>
      <div className='pageContainer'>
        <h2>Eventos disponibles:</h2>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "colum"
          }}
        >
          {loadingAvailableEvents ? (
            <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
          ) : (
            <>
              {availableEvents.length === 0 && (
                <div style={{ margin: "auto" }}>
                  <h2>
                    No encontramos eventos <Icon type='frown' />
                  </h2>
                </div>
              )}
              {availableEvents.map(event => (
                <EventCard
                  editEvent={handleEditEvent}
                  key={event.id}
                  event={event}
                  deleteEvent={handleDeleteEvent}
                />
              ))}
            </>
          )}
        </div>
        <Divider />
        <h2>Eventos pasados:</h2>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "colum"
          }}
        >
          {loadingOldEvents ? (
            <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
          ) : (
            <>
              {oldEvents.length === 0 && (
                <div style={{ margin: "auto" }}>
                  <h2>
                    No encontramos eventos <Icon type='frown' />
                  </h2>
                </div>
              )}
              {oldEvents.map(event => (
                <EventCard
                  editEvent={handleEditEvent}
                  key={event.id}
                  event={event}
                  deleteEvent={handleDeleteEvent}
                />
              ))}
            </>
          )}
        </div>
      </div>
      <Modal
        title='Editar servicio adicional'
        visible={editMode}
        onCancel={() => setEditMode(false)}
        footer={null}
        centered
        destroyOnClose
        width={"90%"}
      >
        <CreateEventForm
          currentEvent={currentEvent}
          setEditMode={setEditMode}
        />
      </Modal>
    </>
  );
};

export default Events;
