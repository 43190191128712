export default error => {
  switch (error) {
    case "not-authorized":
      return "No tienes permiso para realizar esta accion";
    case "duplicate-user":
      return "Este email ya esta registrado";
    case "auth/invalid-email":
      return "¡Upss! ese email no es valido";
    case "auth/user-disabled":
      return "Usuario deshabilitado";
    case "auth/user-not-found":
      return "No exite usuario con ese email";
    case "auth/wrong-password":
      return "Contraseña incorrecta";
    case "auth/missing-continue-uri":
      return "Falta la uri de continuacion en el request";
    case "auth/invalid-continue-uri":
      return "La uri de continuacion es invalida";
    case "auth/unauthorized-continue-uri":
      return "No estas autorizado para la uri de continuacion";
    case "auth/expired-action-code":
      return "Este link ha expirado, por favor genera uno nuevo";
    case "auth/invalid-action-code":
      return "Este link es invalido, puede que ya haya sido usado";
    case "auth/weak-password":
      return "La contraseña es muy debil, intenta con otra";
    case "auth/email-already-exists":
      return "Este usuario ya existe";
    case "auth/network-request-failed":
      return "¡Upss! asegurate que tengas internet";
    case "auth/web-storage-unsupported":
      return "Tu navegador no permite el inicio de sesion, usa otro porfa";
    default:
      return error;
  }
};
