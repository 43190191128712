export default function(oldObject, newObject) {
  const oldObjectKeys = Object.keys(oldObject);
  const newObjectKeys = Object.keys(newObject);
  const keyAdds = newObjectKeys.filter(key => !oldObject.hasOwnProperty(key));
  const keyRemoves = oldObjectKeys.filter(
    key => !newObject.hasOwnProperty(key)
  );
  let keyChanges = [];
  newObjectKeys.forEach(key => {
    if (oldObject[key] !== undefined) {
      if (JSON.stringify(newObject[key]) !== JSON.stringify(oldObject[key])) {
        keyChanges.push(key);
      }
    }
  });

  const add = keyAdds.map(key => {
    return {
      [key]: newObject[key]
    };
  });
  const remove = keyRemoves.map(key => {
    return {
      [key]: oldObject[key]
    };
  });

  const change = keyChanges.map(key => {
    return {
      [key]: {
        old: oldObject[key],
        new: newObject[key]
      }
    };
  });
  return {
    add,
    remove,
    change
  };
}
