import React, { useState, useEffect } from "react";
import { Icon, Tag, Modal, message } from "antd";
import { useStoreState, useStoreActions } from "easy-peasy";
import BrandProfileTagsForm from "./BrandProfileTagsForm";

import dbErrorMessages from "../../utils/dbErrorMessages";

const BrandProfileTags = ({ currentBrand }) => {
  const [editMode, setEditMode] = useState(false);
  const { error } = useStoreState(state => state.brands.updateBrandTagsState);
  const setUpdateBrandTagsState = useStoreActions(
    actions => actions.brands.setUpdateBrandTagsState
  );

  //if error show error message
  useEffect(() => {
    if (error)
      message.error(dbErrorMessages(error), 3, () =>
        setUpdateBrandTagsState({
          loading: false,
          error: "",
          success: false
        })
      );
  }, [error, setUpdateBrandTagsState]);
  return (
    <>
      <div>
        <Icon
          type="edit"
          style={{ fontSize: 14 }}
          onClick={() => setEditMode(true)}
        />
        <div>
          <h2>Atributos: </h2>
          {currentBrand.brandTags &&
            currentBrand.brandTags.map(tag => <Tag key={tag}>{tag}</Tag>)}
        </div>
        <div style={{ marginTop: 20 }}>
          <h2>Audiencia: </h2>
          {currentBrand.audience &&
            currentBrand.audience.map(tag => <Tag key={tag}>{tag}</Tag>)}
        </div>
      </div>
      <Modal
        title="Editar Brand Tags de la marca"
        visible={editMode}
        onCancel={() => setEditMode(false)}
        footer={null}
        width="90%"
        afterClose={() => {
          setUpdateBrandTagsState({
            loading: false,
            error: "",
            success: false
          });
        }}
      >
        <BrandProfileTagsForm brand={currentBrand} setEditMode={setEditMode} />
      </Modal>
    </>
  );
};

export default BrandProfileTags;
