import React, { useState } from "react";
import UploadInventoryElementGallery from "./UploadInventoryElementGallery";
import { message, Icon } from "antd";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { db, storage, fieldValue } from "../../firebase.config";
import arrayMove from "array-move";

const DeleteIcon = ({ url, element }) => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteImage = async () => {
    setLoadingDelete(true);
    const imageRef = storage.refFromURL(url);
    const elementRef = db
      .collection("brands")
      .doc(element.brandId)
      .collection("inventory")
      .doc(element.id);
    try {
      await elementRef.update({
        gallery: fieldValue.arrayRemove(url)
      });
      await imageRef.delete();
    } catch (e) {
      message.error("No pudimos eliminar la imagen");
      console.log(e.message);
      setLoadingDelete(false);
    }
  };
  return (
    <Icon
      type={loadingDelete ? "loading" : "close"}
      style={{ fontSize: 14 }}
      onClick={() => deleteImage()}
    />
  );
};

const SortableElement = sortableElement(({ value, element }) => (
  <div
    style={{ width: 100, height: 120, margin: 5, backgroundColor: "#f4f4f4" }}
  >
    <DeleteIcon url={value} element={element} />
    <img src={value} alt='gallery' height={100} width={100} />
  </div>
));

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {children}
    </div>
  );
});

const InventoryElementGallery = ({ element }) => {
  const [gallery, setGallery] = useState([...(element.gallery || [])]);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const oldGalleryArray = gallery;
    const newGalleryArray = arrayMove(gallery, oldIndex, newIndex);
    setGallery(newGalleryArray);

    try {
      const elementRef = db
        .collection("brands")
        .doc(element.brandId)
        .collection("inventory")
        .doc(element.id);
      await elementRef.update({ gallery: newGalleryArray });
    } catch (e) {
      console.log(e.message);
      setGallery(oldGalleryArray);
      message.error("No pudimos actualizar el orden de la galeria");
    }
  };
  return (
    <>
      <div>
        <h2>Galeria: </h2>
        <UploadInventoryElementGallery
          element={element}
          title='Agregar a galeria'
        />
        <small>Recomenacion, imagenes de 300x300</small>
      </div>
      <div>
        <SortableContainer distance={5} axis='xy' onSortEnd={onSortEnd}>
          {gallery.map((value, index) => (
            <SortableElement
              key={`item-${index}`}
              index={index}
              value={value}
              element={element}
            />
          ))}
        </SortableContainer>
      </div>
    </>
  );
};

export default InventoryElementGallery;
