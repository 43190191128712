import React from "react";
import { Icon, Tag, message } from "antd";
import { db, fieldValue } from "../../firebase.config";

const KeepmePointsCategoryCard = ({ category }) => {
  const handlecategoryDelete = async () => {
    try {
      const categoryRef = db.collection("categories").doc("keepmepoints");
      await categoryRef.update({
        categories: fieldValue.arrayRemove({
          id: category.id,
          name: category.name,
          createdAt: category.createdAt
        }),
        names: fieldValue.arrayRemove(category.name),
        [category.id]: fieldValue.delete()
      });
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos eliminar la categoria");
    }
  };
  return (
    <div className='keepmepointsCategoryCard'>
      <div style={{ display: "flex" }}>
        {(!category.count || category.count.length === 0) && (
          <Icon
            type='close'
            shape='circle'
            style={{ fontSize: 12 }}
            onClick={() => handlecategoryDelete()}
          />
        )}
      </div>

      <h3>{category.name}</h3>
      <div>
        <Tag color='#222'>{category.count || 0}</Tag>
      </div>
    </div>
  );
};

export default KeepmePointsCategoryCard;
