import React, { useState, useEffect } from "react";
import { Timeline, Divider } from "antd";
import formatTimestamp from "../../utils/formatTimestamp";
import { db } from "../../firebase.config";
const InventoryElementLogs = ({ element }) => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const unSubscribeToLogs = db
      .collection("brands")
      .doc(element.brandId)
      .collection("inventory")
      .doc(element.id)
      .collection("logs")
      .doc(element.id)
      .onSnapshot(doc => {
        if (doc.exists) {
          const logsData = doc.data();
          setLogs(logsData.logs.reverse());
        }
      });
    return () => unSubscribeToLogs();
  }, [element.id, element.brandId]);
  return (
    <div className='complmenetProfileContainer' style={{ marginTop: 20 }}>
      <h2>Registro de cambios</h2>
      <Divider />
      <div>
        <Timeline>
          {logs.map(log => (
            <Timeline.Item key={log.date + log.user}>
              El usuario <strong>{log.user}</strong> actualizo el elemento en{" "}
              {formatTimestamp(log.date)}:
              {log.description &&
                log.description.length > 0 &&
                log.description.map(item => <p key={item}>{item}</p>)}{" "}
            </Timeline.Item>
          ))}
          <Timeline.Item>
            El elemento ingreso a Keepme: {formatTimestamp(element.createdAt)}
          </Timeline.Item>
        </Timeline>
      </div>
    </div>
  );
};

export default InventoryElementLogs;
