import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { Icon, Row, Col, Typography, Tag, Modal, Button } from "antd";
import { db } from "../../firebase.config";
import DeleteSerie from "./DeleteSerie";
import formatTimestamp from "../../utils/formatTimestamp";
import EditSerieForm from "./EditSerieForm";
import SerieProfileElements from "./SerieProfileElements";

const { Title } = Typography;
const SerieProfile = ({ match }) => {
  const [currentSerie, setCurrentSerie] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    const serieRef = db
      .collection("brands")
      .doc(match.params.brandId)
      .collection("series")
      .doc(match.params.serieId);
    const unsubscribeToSerie = serieRef.onSnapshot(doc => {
      setLoading(true);
      setCurrentSerie({
        id: doc.id,
        ...doc.data()
      });
      setLoading(false);
    });
    return () => unsubscribeToSerie();
  }, [match.params]);
  return (
    <div className="pageContainer">
      {loading ? (
        <div style={{ display: "flex" }}>
          <Icon style={{ margin: "auto", fontSize: 80 }} type="loading" />
        </div>
      ) : (
        <>
          <div className="serieProfileContainer">
            <Row gutter={16}>
              <Col span={10}>
                <Icon
                  type="edit"
                  style={{ fontSize: 14 }}
                  onClick={() => setEditMode(true)}
                />
                <Title>{currentSerie && currentSerie.name}</Title>
                <div>
                  <small>
                    Creada en: {formatTimestamp(currentSerie.createdAt)}{" "}
                  </small>
                </div>
                <div>
                  <small>Marca: </small>{" "}
                  <Tag color={currentSerie.brandColor}>
                    {currentSerie.brandName}
                  </Tag>
                </div>
                <div>
                  <small>Cantidad de elementos: </small>{" "}
                  <Tag color="#222">{currentSerie.elementsCount}</Tag>
                </div>
              </Col>
              <Col span={10}>
                <div>
                  <h2>Imagen Principal: </h2>
                  <div
                    className="elementPreviewImage"
                    style={{
                      backgroundImage: `url(${currentSerie.mainImage || null})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center"
                    }}
                  >
                    {currentSerie && !currentSerie.mainImage && (
                      <Icon
                        type="picture"
                        style={{ margin: "auto", fontSize: 20 }}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ marginBottom: 20 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Link to={`/series`}>
                  <Button type="primary"> Regresar a las series</Button>
                </Link>
              </div>
              <div>
                <DeleteSerie serie={currentSerie} />
              </div>
            </div>
          </div>
          <SerieProfileElements serie={currentSerie} />
        </>
      )}
      <Modal
        title="Editar informacion de la serie"
        visible={editMode}
        onCancel={() => setEditMode(false)}
        footer={null}
        centered
        destroyOnClose
      >
        <div>
          <EditSerieForm serie={currentSerie} setEditMode={setEditMode} />
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(SerieProfile);
