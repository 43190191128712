import React, { useState, useEffect } from "react";
import { Upload, Button, Icon, message, Progress } from "antd";
import Img from "react-image";
const UploadSerieImage = ({
  title,
  fileList,
  setFileList,
  uploadProgress,
  progressVisible,
  initialImage
}) => {
  const [previewImage, setPreviewImage] = useState(initialImage);
  const beforeUpload = file => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setFileList([]);
      message.error("Solo puedes subir imagenes en PNG o JPG");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      setFileList([]);
      message.error("La imagen no puede ser mayor a un 1MB");
    }
    setFileList([file]);
    // return isJpgOrPng && isLt2M;
    return false;
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  useEffect(() => {
    const previewUrl = async () => {
      if (fileList.length > 0) {
        const url = await getBase64(fileList[0]);
        setPreviewImage(url);
      } else {
        setPreviewImage(null);
      }
    };
    previewUrl();
  }, [fileList]);
  return (
    <>
      <div>
        <h2>Imagen Principal: </h2>
        <Img
          src={previewImage || initialImage}
          loader={
            <div style={{ display: "flex", height: 300 }}>
              <Icon style={{ margin: "auto" }} type='loading' />
            </div>
          }
          unloader={
            <div className='seriePreviewImage'>
              <Icon type='picture' style={{ margin: "auto" }} />
            </div>
          }
          alt='Serie'
          className='seriePreviewImage'
        />
        <small>
          Recomenacion, imagenes de 300x300 o con un ratio cuadrado.
        </small>
      </div>
      <div style={{ width: "80%" }}>
        <Upload
          beforeUpload={beforeUpload}
          multiple={false}
          fileList={fileList}
          onRemove={() => setFileList([])}
        >
          <Button type='primary' size='small'>
            <Icon type='upload' /> {title}
          </Button>
          {progressVisible && (
            <Progress percent={uploadProgress} size='small' />
          )}
        </Upload>
      </div>
    </>
  );
};

UploadSerieImage.defaultProps = {
  initialImage: ""
};

export default UploadSerieImage;
