import React, { useState, useEffect } from "react";
import { db } from "../../firebase.config";
import { Icon } from "antd";
import OutputCard from "./OutputCard";
const getOutputState = output => {
  if (output.outputState === "approved") {
    if (output.startDate > Date.now()) return "Activa";
    if (output.startDate <= Date.now()) return "En curso";
  } else if (output.outputState === "pending") {
    return "Pendiente";
  } else if (output.outputState === "cancel") {
    return "Cancelada";
  } else if (output.outputState === "finish") return "Efectiva";
  else return output.outputState;
};

const OutputsListByState = ({ state }) => {
  const [loading, setLoading] = useState(true);
  const [outputsList, setOutputsList] = useState([]);

  useEffect(() => {
    let unsubscribeToOutputs;
    if (state === "active") {
      unsubscribeToOutputs = db
        .collectionGroup("outputs")
        .where("outputState", "==", "approved")
        .where("startDate", ">", Date.now())
        .onSnapshot(querySnapshot => {
          let outputs = [];
          querySnapshot.forEach(doc => {
            outputs.push({
              id: doc.id,
              ...doc.data()
            });
          });
          setOutputsList(outputs);
          setLoading(false);
        });
    } else if (state === "onGoing") {
      unsubscribeToOutputs = db
        .collectionGroup("outputs")
        .where("outputState", "==", "approved")
        .where("startDate", "<=", Date.now())
        .onSnapshot(querySnapshot => {
          let outputs = [];
          querySnapshot.forEach(doc => {
            outputs.push({
              id: doc.id,
              ...doc.data()
            });
          });
          setOutputsList(outputs);
          setLoading(false);
        });
    } else {
      unsubscribeToOutputs = db
        .collectionGroup("outputs")
        .where("outputState", "==", state)
        .onSnapshot(querySnapshot => {
          let outputs = [];
          querySnapshot.forEach(doc => {
            outputs.push({
              id: doc.id,
              ...doc.data()
            });
          });
          setOutputsList(outputs);
          setLoading(false);
        });
    }

    return () => unsubscribeToOutputs();
  }, [state]);
  return (
    <div className='outputsListContainer'>
      {loading ? (
        <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
      ) : (
        <>
          {outputsList.length === 0 && (
            <div style={{ margin: "auto" }}>
              <h2>
                No encontramos salidas <Icon type='frown' />
              </h2>
            </div>
          )}
          {outputsList.map(output => (
            <OutputCard
              key={output.id}
              output={output}
              stateText={getOutputState(output)}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default OutputsListByState;
