import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { db } from "../../firebase.config";
import {
  Icon,
  Typography,
  Row,
  Col,
  Tag,
  Switch,
  Divider,
  message,
  Modal,
  Button
} from "antd";
import SerieElementGallery from "./SerieElementGallery";
import UploadSerieElementImage from "./UploadSerieElementImage";
import DeleteSerieElementImage from "./DeleteSerieElementImage";
import EditSerieElementForm from "./EditSerieElementForm";
import DeleteSerieElement from "./DeleteSerieElement";
import formatTimestamp from "../../utils/formatTimestamp";
const { Title } = Typography;

const SerieElementProfile = ({ match }) => {
  const [currentElement, setCurrentElement] = useState({});
  const [loading, setLoading] = useState(true);
  const [isPublicLoading, setIsPublicLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleSwitchChange = async checked => {
    setIsPublicLoading(true);
    const elementRef = db
      .collection("brands")
      .doc(match.params.brandId)
      .collection("series")
      .doc(match.params.serieId)
      .collection("elements")
      .doc(match.params.elementId);
    try {
      await elementRef.update({ isPublic: checked });
      setIsPublicLoading(false);
    } catch (e) {
      message.error("No pudimos actualizar el estado del elemento");
      setIsPublicLoading(false);
    }
  };

  useEffect(() => {
    const elementRef = db
      .collection("brands")
      .doc(match.params.brandId)
      .collection("series")
      .doc(match.params.serieId)
      .collection("elements")
      .doc(match.params.elementId);
    const unsubscribeToElement = elementRef.onSnapshot(doc => {
      setLoading(true);
      setCurrentElement({
        id: doc.id,
        ...doc.data()
      });
      setLoading(false);
    });
    return () => unsubscribeToElement();
  }, [match.params]);

  return (
    <div className='pageContainer'>
      {loading ? (
        <div style={{ display: "flex" }}>
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        </div>
      ) : (
        <>
          <div className='complmenetProfileContainer'>
            <Row gutter={16}>
              <Col span={10}>
                <Icon
                  type='edit'
                  style={{ fontSize: 14 }}
                  onClick={() => setEditMode(true)}
                />
                <Title>{currentElement.name}</Title>
                <div>
                  Publicado:{" "}
                  <Switch
                    loading={isPublicLoading}
                    checked={currentElement.isPublic}
                    onChange={checked =>
                      handleSwitchChange(checked, "isPublic")
                    }
                  />
                </div>
                <div>
                  <small>
                    Creado en: {formatTimestamp(currentElement.createdAt)}
                  </small>
                </div>
                <Divider />
                <Row>
                  <Col span={12}>
                    <div>
                      <h2>Caracteristicas</h2>
                      <span>
                        <strong>Marca: </strong>
                      </span>
                      <Tag color={currentElement.brandColor}>
                        {currentElement.brandName}
                      </Tag>
                      <p>
                        <strong>Serie: </strong>
                        {currentElement.serieName}
                      </p>
                      <p>
                        <strong>Unidades disponibles: </strong>
                        {currentElement.units}
                      </p>
                      <p>
                        <strong>Ultima salida: </strong>
                        {currentElement.lastOutput || "No tiene salidas aún"}
                      </p>
                      <p>
                        <strong>Estado: </strong>
                        {currentElement.state}
                      </p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <>
                        <h2>Medidas</h2>
                        <p>
                          {currentElement.height !== 0 && (
                            <>
                              <strong>Alto: </strong>
                              {currentElement.height}
                              {currentElement.measureUnits}
                            </>
                          )}
                        </p>
                        <p>
                          {currentElement.width !== 0 && (
                            <>
                              <strong>Ancho: </strong>
                              {currentElement.width}
                              {currentElement.measureUnits}
                            </>
                          )}
                        </p>
                        <p>
                          {currentElement.long !== 0 && (
                            <>
                              <strong>Largo: </strong>
                              {currentElement.long}
                              {currentElement.measureUnits}
                            </>
                          )}
                        </p>

                        <p>
                          {currentElement.diameter !== 0 && (
                            <>
                              <strong>Diametro: </strong>
                              {currentElement.diameter}
                              {currentElement.measureUnits}
                            </>
                          )}
                        </p>
                      </>
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={12}>
                    <h2>Observaciones:</h2>
                    <p>{currentElement.observations}</p>
                  </Col>
                </Row>
              </Col>
              <Col span={7}>
                <div>
                  <h2>Imagen Principal: </h2>
                  <div
                    className='elementPreviewImage'
                    style={{
                      backgroundImage: `url(${currentElement.mainImage ||
                        null})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center"
                    }}
                  >
                    {currentElement && !currentElement.mainImage && (
                      <Icon
                        type='picture'
                        style={{ margin: "auto", fontSize: 20 }}
                      />
                    )}
                  </div>
                  <small>Recomenacion, imagenes cuadradas</small>
                  <div style={{ display: "flex" }}>
                    <div style={{ margin: 5 }}>
                      <UploadSerieElementImage
                        title='Subir imagen'
                        element={currentElement}
                        fileName='mainImage'
                      />
                    </div>
                    <div style={{ margin: 5 }}>
                      <DeleteSerieElementImage
                        element={currentElement}
                        path={`series/${currentElement.serieId}/${currentElement.id}/mainImage`}
                        disabled={!currentElement.mainImage}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={7}>
                <SerieElementGallery element={currentElement} />
              </Col>
            </Row>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Link
                to={`/serie/${match.params.brandId}/${match.params.serieId}`}
              >
                <Button type='primary'> Regresar a la serie</Button>
              </Link>
            </div>
            <div>
              <DeleteSerieElement element={currentElement} />
            </div>
          </div>
        </>
      )}
      <Modal
        title={`Editar informacion general del elemento ${currentElement.name}`}
        visible={editMode}
        onCancel={() => setEditMode(false)}
        footer={null}
        centered
        destroyOnClose
        width={800}
      >
        <div>
          <EditSerieElementForm
            element={currentElement}
            setEditMode={setEditMode}
          />
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(SerieElementProfile);
