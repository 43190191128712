import React, { useState, useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Form, Input, Icon, Button, message } from "antd";
import EditCompanySuccess from "./EditCompanySuccess";
import dbErrorMessages from "../../utils/dbErrorMessages";
const EditCompanyForm = ({ company, setEditMode }) => {
  const [name, setName] = useState(company.name);
  const { updateCompany, setUpdateCompanyState } = useStoreActions(
    actions => actions.companies
  );
  const { loading, error, success } = useStoreState(
    state => state.companies.updateCompanyState
  );

  //if error show error message
  useEffect(() => {
    if (error)
      message.error(dbErrorMessages(error), 3, () =>
        setUpdateCompanyState({
          loading: false,
          error: "",
          success: false
        })
      );
  }, [error, setUpdateCompanyState]);

  const handleUpdateCompany = e => {
    e.preventDefault();
    if (name && company.name !== name) {
      updateCompany({ companyId: company.id, name });
    } else {
      setUpdateCompanyState({
        loading: false,
        error: "Escoge un nuevo nombre valido",
        success: false
      });
    }
  };
  return (
    <div className='editCompanyForm'>
      {success ? (
        <EditCompanySuccess setEditMode={setEditMode} />
      ) : (
        <Form onSubmit={handleUpdateCompany}>
          <Form.Item label='Nuevo nombre de la empresa'>
            <Input
              prefix={<Icon type='shop' style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder='Nuevo nombre de la empresa'
              type='text'
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <Button block type='primary' loading={loading} htmlType='submit'>
              Modificar empresa
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EditCompanyForm;
