import React from "react";
import { Button, Avatar, Tag, Divider } from "antd";
import { Link } from "react-router-dom";
// import { getDateValues } from "../../utils/formatTimestamp";

const UnfinishedOutputCard = ({ output, stateText }) => {
  const stateColor = {
    Activa: "green",
    Efectiva: "geekblue",
    Pendiente: "orange",
    Cancelada: "red"
  };
  return (
    <div className='outputCard'>
      <Avatar style={{ margin: "auto" }} size={64} icon='schedule' />
      <h2>{output.name}</h2>
      <div style={{ margin: 5 }}>
        <Tag>{output.brand}</Tag>
        <Tag
          color={stateText === "En curso" ? "purple" : stateColor[stateText]}
        >
          {stateText}
        </Tag>
      </div>
      <Divider />
      <Link to={`/completar-salida/${output.brandId}/${output.id}`}>
        <Button type='primary' block>
          Completar salida
        </Button>
      </Link>
    </div>
  );
};

export default UnfinishedOutputCard;
