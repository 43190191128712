import React, { useState } from "react";
import { Button } from "antd";
import { storage, db } from "../../firebase.config";

const DeleteInventoryElementImage = ({ path, element, disabled }) => {
  const [loading, setLoading] = useState(false);
  const deleteLogo = async () => {
    setLoading(true);
    const storageRef = storage.ref();
    try {
      const fileRef = storageRef.child(path);
      const elementRef = db
        .collection("brands")
        .doc(element.brandId)
        .collection("inventory")
        .doc(element.id);
      await fileRef.delete();
      await elementRef.update({ mainImage: "" });
    } catch (e) {
      console.log(e.message);
    }
  };
  return (
    <Button
      loading={loading}
      type='danger'
      size='small'
      disabled={disabled}
      onClick={() => deleteLogo()}
    >
      Borrar
    </Button>
  );
};

export default DeleteInventoryElementImage;
