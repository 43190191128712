import React, { useState, useEffect } from "react";
import { Form, Button, Divider } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import BrandProfileFormSuccess from "./BrandProfileFormSuccess";
import BrandTags from "./BrandTags";

const BrandProfileTagsForm = ({ brand, setEditMode }) => {
  const [tags, setTags] = useState(brand.brandTags || []);
  const [audience, setAudience] = useState(brand.audience || []);
  const [showSuccess, setShowSuccess] = useState(false);
  const { updateBrandTags, setUpdateBrandTagsState } = useStoreActions(
    actions => actions.brands
  );
  const { loading, success } = useStoreState(
    state => state.brands.updateBrandTagsState
  );

  const handleUpdateBrandTags = e => {
    e.preventDefault();
    updateBrandTags({ id: brand.id, brandTags: tags, audience });
  };

  useEffect(() => {
    if (success) setShowSuccess(true);
  }, [success]);

  return (
    <>
      {showSuccess ? (
        <BrandProfileFormSuccess
          setEditMode={setEditMode}
          brandId={brand.id}
          resetState={setUpdateBrandTagsState}
        />
      ) : (
        <>
          <Form onSubmit={handleUpdateBrandTags}>
            <h3>Atributos de la marca: </h3>
            <BrandTags setBrandTags={setTags} limit={10} initialTags={tags} />
            <Divider />
            <h3>Audiencia de la marca: </h3>
            <BrandTags
              setBrandTags={setAudience}
              limit={10}
              initialTags={audience}
            />
            <Divider />
            <div style={{ display: "flex", marginTop: 10 }}>
              <Form.Item style={{ margin: "auto" }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Actualizar Brand Tags
                </Button>
              </Form.Item>
            </div>
          </Form>
        </>
      )}
    </>
  );
};

export default BrandProfileTagsForm;
