import React from "react";
import { Redirect } from "react-router-dom";
import LoginForm from "./LoginForm";
import LogOutForm from "./LogOutForm";
import keepmeLogo from "../../assets/images/LOGO-KEEPME-white-contrast .svg";
import "./login.css";

const Login = ({ isAuthenticated, location, isAdmin }) => {
  const { from } = location.state || { from: { pathname: "/" } };

  if (isAuthenticated && isAdmin) return <Redirect to={from.pathname} />;

  return (
    <div className="loginContainer">
      <div className="loginFormCard">
        <div style={{ display: "flex", marginBottom: 20 }}>
          <img
            className="logo"
            src={keepmeLogo}
            style={{ width: 130, margin: "auto" }}
            alt="Keepme Logo"
          />
        </div>
        {isAuthenticated && !isAdmin ? <LogOutForm /> : <LoginForm />}
        <div style={{ textAlign: "center" }}>
          <small>Admin panel</small>
        </div>
      </div>
    </div>
  );
};
export default Login;
