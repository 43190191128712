import React from "react";
import CreateEventForm from "./CreateEventForm";

const CreateEvent = () => {
  return (
    <div className='pageContainer'>
      <h1>Crear evento</h1>
      <CreateEventForm />
    </div>
  );
};

export default CreateEvent;
