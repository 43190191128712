import React, { useState } from "react";
import { Button, message, Modal } from "antd";
import { withRouter } from "react-router-dom";
import { db, fieldValue } from "../../firebase.config";
const { confirm } = Modal;
const DeleteInventoryElement = ({ element, history }) => {
  const [loading, setLoading] = useState(false);
  const confirmDelete = () => {
    confirm({
      title: "¿Seguro que deseas borrar este elemento?",
      content:
        "Si lo borras ya nadie podra usarlo, aun asi, quedara registrado en las salidas que lo usaron",
      okText: "Si, con toda",
      okType: "danger",
      cancelText: "No gracias",
      async onOk() {
        await handleDeleteInventoryElement();
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  const handleDeleteInventoryElement = async () => {
    setLoading(true);
    const elementRef = db
      .collection("brands")
      .doc(element.brandId)
      .collection("inventory")
      .doc(element.id);
    const brandRef = db.collection("brands").doc(element.brandId);
    try {
      await db.runTransaction(async transaction => {
        const elementToDelete = await transaction.get(elementRef);
        const elementToDeleteData = elementToDelete.data();
        if (
          elementToDeleteData.reservedDates &&
          elementToDeleteData.reservedDates.length > 0
        ) {
          let errorMessage = {
            code: "element-in-outputs",
            message: "El elemento esta presente en salidas"
          };
          throw errorMessage;
        }
        transaction.delete(elementRef);
        transaction.update(brandRef, {
          [element.categoryId]: fieldValue.increment(-1)
        });
      });
      history.push(`/inventario/${element.brandId}`);
    } catch (e) {
      console.log(e.message);
      if (e.code === "element-in-outputs") {
        message.error("El elemento esta presente en salidas sin completar");
      } else {
        message.error("No pudimos borrar el elemento");
      }
      setLoading(false);
    }
  };
  return (
    <div>
      <Button type="danger" ghost onClick={confirmDelete} loading={loading}>
        Borrar elemento
      </Button>
    </div>
  );
};

export default withRouter(DeleteInventoryElement);
