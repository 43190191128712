import React, { useState } from "react";
import { Form, Input, Button, message, Divider } from "antd";
import { db, storage } from "../../firebase.config";
import UploadSerieImage from "./UploadSerieImage";

const EditSerie = ({ serie, setEditMode }) => {
  const [name, setName] = useState(serie.name);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [progressVisible, setProgressVisible] = useState(false);
  const handleSubmit = async e => {
    e.preventDefault();
    if (!name) return message.error("El nombre es obligatorio");

    setLoading(true);
    const serieRef = db
      .collection("brands")
      .doc(serie.brandId)
      .collection("series")
      .doc(serie.id);
    const elementsRef = db
      .collection("brands")
      .doc(serie.brandId)
      .collection("series")
      .doc(serie.id)
      .collection("elements");
    const batch = db.batch();
    try {
      if (name !== serie.name) {
        const elementsDocs = await elementsRef.get();
        if (elementsDocs.size > 0) {
          elementsDocs.forEach(element => {
            batch.update(element.ref, { serieName: name });
          });
        }
      }

      batch.update(serieRef, { name, updatedAt: Date.now() });
      await batch.commit();
      if (fileList.length > 0) {
        try {
          await handleUpload(fileList[0], serie.id, serie.brandId);
        } catch (e) {
          console.log(e.message);
          setLoading(false);
          return message.error(
            "No pudimos cargar la imagen, pero se actualizó la serie"
          );
        }
      } else {
        setEditMode(false);
      }
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos actualizar la serie");
      setLoading(false);
    }
  };

  const handleUpload = async (file, id, brandId) => {
    setProgressVisible(true);
    const storageRef = storage.ref();
    const fileRef = storageRef.child(`series/${id}/mainImage`);
    const uploadTask = fileRef.put(file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(Math.trunc(progress));
        console.log("Upload is " + progress + "% done");
      },
      error => {
        message.error(error, 3);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(async downloadUrl => {
          if (downloadUrl) {
            const serieRef = db
              .collection("brands")
              .doc(brandId)
              .collection("series")
              .doc(id);
            await serieRef.update({ mainImage: downloadUrl });
          }
          setEditMode(false);
        });
      }
    );
  };
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Item>
          <Input
            placeholder='Nombre de la serie'
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Form.Item>

        <UploadSerieImage
          title='Cambiar imagen'
          fileList={fileList}
          setFileList={setFileList}
          uploadProgress={uploadProgress}
          progressVisible={progressVisible}
          initialImage={serie.mainImage}
        />

        <Divider />
        <Form.Item>
          <Button block htmlType='submit' type='primary' loading={loading}>
            Actualizar serie
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditSerie;
