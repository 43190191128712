import React, { useState } from "react";
import { Icon, Button, Upload, message } from "antd";
import { storage } from "../../firebase.config";
import { useStoreActions } from "easy-peasy";
import formatBytes from "../../utils/formatBytes";
import formatTimestamp from "../../utils/formatTimestamp";
const UploadToLogoBrand = ({
  title,
  id,
  fileName,
  directory,
  setUploadState,
  extension
}) => {
  const [fileList, setFileList] = useState([]);
  const updateBrandDate = useStoreActions(
    actions => actions.brands.updateBrandDate
  );
  const handleChange = info => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);
    setFileList(newFileList);
  };

  const beforeUpload = file => {
    const isImage = file.type.indexOf(extension) === 0;
    if (!isImage) {
      message.error(`Solo puedes subir archivos con extension ${extension}`);
    }
    return isImage;
  };
  const handleUpload = async ({ file, onSuccess }) => {
    const storageRef = storage.ref();
    const fileRef = storageRef.child(`${id}/${directory}/${fileName}`);
    const fileMetadata = {
      customMetadata: {
        originalFileName: file.name
      }
    };
    try {
      const snapshot = await fileRef.put(file, fileMetadata);
      const downloadUrl = await snapshot.ref.getDownloadURL();
      const metadata = snapshot.metadata;
      setUploadState({
        displayName: metadata.customMetadata.originalFileName,
        name: metadata.name,
        creationDate: formatTimestamp(Date.parse(metadata.timeCreated)),
        size: formatBytes(metadata.size),
        downloadUrl: downloadUrl,
        fullPath: metadata.fullPath
      });
      setFileList([]);
      updateBrandDate(id);
      onSuccess();
    } catch (e) {
      message.error(e.message);
      console.log(e.message);
    }
  };
  return (
    <Upload
      beforeUpload={beforeUpload}
      multiple={false}
      customRequest={handleUpload}
      onChange={handleChange}
      fileList={fileList}
      onRemove={e => console.log(e)}
    >
      <Button type='primary' size='small'>
        <Icon type='upload' /> {title}
      </Button>
    </Upload>
  );
};

export default UploadToLogoBrand;
