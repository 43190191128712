import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Input,
  Row,
  Col,
  InputNumber,
  Select,
  message,
  Switch,
  DatePicker
} from "antd";
import { db, fieldValue } from "../../firebase.config";
import { useStoreState } from "easy-peasy";
import getObjectsDiff from "../../utils/getObjectsDiff";
import locale from "antd/lib/date-picker/locale/es_ES";
import formatTimeStamp from "../../utils/formatTimestamp";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;
const dateFormat = "DD-MM-YYYY";

const getDescription = changes => {
  let description = [];
  changes.forEach(change => {
    if (change.hasOwnProperty("name"))
      description.push(
        `Cambio el nombre de ${change.name.old} a ${change.name.new}`
      );
    if (change.hasOwnProperty("units"))
      description.push(
        `Cambiaron las unidades de ${change.units.old} a ${change.units.new}`
      );
    if (change.hasOwnProperty("minUnits"))
      description.push(
        `Cambiaron las unidades minimas de ${change.minUnits.old} a ${change.minUnits.new}`
      );

    if (change.hasOwnProperty("hasMinUnits")) {
      description.push(
        change.hasMinUnits.new
          ? "Se activaron las unidades minimas"
          : "Se desactivaron las unidades minimas"
      );
    }
    if (change.hasOwnProperty("hasExpirationDate")) {
      description.push(
        change.hasExpirationDate.new
          ? "Se activo la fecha de vencimiento"
          : "Se desactivo la fecha de vencimiento"
      );
    }
    if (change.hasOwnProperty("expirationDate")) {
      if (
        Math.abs(change.expirationDate.new - change.expirationDate.old) >=
        86400000
      ) {
        description.push(
          `Cambio la fecha de vencimiento, paso de ${formatTimeStamp(
            change.expirationDate.old
          )} a ${formatTimeStamp(change.expirationDate.new)}`
        );
      }
    }

    if (change.hasOwnProperty("unitsType"))
      description.push(
        `Cambio el tipo de unidades de ${change.unitsType.old} a ${change.unitsType.new}`
      );

    if (change.hasOwnProperty("isPhysical")) {
      description.push(
        change.isPhysical
          ? "Se activaron las medidas del elemento"
          : "Se desactivaron las medeidas del elemento"
      );
    }

    if (change.hasOwnProperty("height"))
      description.push(
        `Cambio la altura de ${change.height.old} a ${change.height.new}`
      );

    if (change.hasOwnProperty("width"))
      description.push(
        `Cambio la altura de ${change.width.old} a ${change.width.new}`
      );

    if (change.hasOwnProperty("long"))
      description.push(
        `Cambio la altura de ${change.long.old} a ${change.long.new}`
      );

    if (change.hasOwnProperty("diameter"))
      description.push(
        `Cambio el diametro de ${change.diameter.old} a ${change.diameter.new}`
      );

    if (change.hasOwnProperty("category"))
      description.push(
        `Cambio la categoria de ${change.category.old} a ${change.category.new}`
      );

    if (change.hasOwnProperty("mesureUnits"))
      description.push(
        `Cambio la altura de ${change.mesureUnits.old} a ${change.mesureUnits.new}`
      );

    if (change.hasOwnProperty("observations"))
      description.push(
        `Cambio la altura de ${change.observations.old} a ${change.observations.new}`
      );
  });

  return description;
};
const EditInventoryElementForm = ({ element, setEditMode }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(element.name);
  const [units, setUnits] = useState(element.units);
  const [isPhysical, setIsPhysical] = useState(element.isPhysical);
  const [height, setHeight] = useState(element.height || 0);
  const [width, setWidth] = useState(element.width || 0);
  const [long, setLong] = useState(element.long || 0);
  const [diameter, setDiameter] = useState(element.diameter || 0);
  const [observations, setObservations] = useState(element.observations || "");
  const [measureUnits, setMeasureUnits] = useState(element.measureUnits);
  const [unitsType, setUnitsType] = useState(element.unitsType);
  const [category, setCategory] = useState(element.category);
  const [categories, setCategories] = useState([]);
  const [minUnits, setMinUnits] = useState(element.minUnits);
  const [hasMinUnits, setHasMinUnits] = useState(element.hasMinUnits || false);
  const [hasExpirationDate, setHasExpirationDate] = useState(
    element.hasExpirationDate
  );
  const [expirationDate, setExpirationDate] = useState(
    moment(element.expirationDate)
  );

  const currentUser = useStoreState(state => state.user.currentUser);

  const handleSubmit = async e => {
    e.preventDefault();
    //some validation of fields
    if (!name) return message.error("El nombre es obligatorio");
    if (!units) return message.error("La cantidad disponible es obligatoria");
    if (!measureUnits) return message.error("El tipo de medida es obligatorio");
    if (!unitsType) return message.error("El tipo de unidades es obligatorio");
    if (minUnits >= units)
      return message.error(
        "Las unidades minimas no pueden ser iguales o mayores a las unidades disponibles"
      );
    if (hasExpirationDate && !expirationDate)
      return message.error(
        "Si tiene fecha de vencimiento, debes ponerla, Dahh!!"
      );

    setLoading(true);
    const batch = db.batch();
    const elementRef = db
      .collection("brands")
      .doc(element.brandId)
      .collection("inventory")
      .doc(element.id);
    const logsRef = db
      .collection("brands")
      .doc(element.brandId)
      .collection("inventory")
      .doc(element.id)
      .collection("logs")
      .doc(element.id);
    if (category !== element.category) {
      const brandRef = db.collection("brands").doc(element.brandId);
      const oldCategory = categories.filter(
        cat => cat.name === element.category
      )[0];
      const newCategory = categories.filter(cat => cat.name === category)[0];

      try {
        const newElement = {
          name,
          units,
          minUnits,
          hasMinUnits,
          unitsType,
          isPhysical,
          height,
          width,
          long,
          diameter,
          category,
          categoryId: newCategory.id,
          measureUnits,
          observations,
          hasExpirationDate,
          expirationDate: hasExpirationDate
            ? expirationDate.valueOf()
            : Date.now(),
          notified: hasExpirationDate
            ? !(expirationDate.valueOf() > Date.now())
            : false,
          updatedAt: Date.now()
        };
        batch.update(brandRef, {
          [oldCategory.id]: fieldValue.increment(-1),
          [newCategory.id]: fieldValue.increment(1),
          inventoryCategories: fieldValue.arrayUnion(newCategory)
        });
        batch.update(elementRef, newElement);
        batch.set(
          logsRef,
          {
            logs: fieldValue.arrayUnion({
              date: Date.now(),
              user: currentUser.email,
              description: getDescription(
                getObjectsDiff(element, newElement).change
              )
            })
          },
          { merge: true }
        );
        await batch.commit();
        message.success("Elemento actualizado");
        setEditMode(false);
      } catch (e) {
        console.log(e.message);
        message.error("No pudimos actualizar el elemento");
        setLoading(false);
      }
    } else {
      try {
        const newElement = {
          name,
          units,
          minUnits,
          hasMinUnits,
          unitsType,
          isPhysical,
          height,
          width,
          long,
          diameter,
          measureUnits,
          observations,
          hasExpirationDate,
          expirationDate: hasExpirationDate
            ? expirationDate.valueOf()
            : Date.now(),
          updatedAt: Date.now(),
          notified: hasExpirationDate
            ? !(expirationDate.valueOf() > Date.now())
            : false
        };
        batch.update(elementRef, newElement);
        batch.set(
          logsRef,
          {
            logs: fieldValue.arrayUnion({
              date: Date.now(),
              user: currentUser.email,
              description: getDescription(
                getObjectsDiff(element, newElement).change
              )
            })
          },
          { merge: true }
        );
        await batch.commit();
        setEditMode(false);
      } catch (e) {
        console.log(e.message);
        message.error("No pudimos actualizar el complemento");
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const categoriesRef = db.collection("categories").doc("inventory");
    const unsubscribeToCategories = categoriesRef.onSnapshot(doc => {
      const categoriesDoc = doc.data();
      setCategories(categoriesDoc.categories);
    });
    return () => {
      unsubscribeToCategories();
    };
  }, []);

  useEffect(() => {
    if (!hasMinUnits && minUnits !== 0) setMinUnits(0);
  }, [hasMinUnits, minUnits]);
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label='Nombre'>
            <Input
              value={name}
              placeholder='Nombre del elemento'
              onChange={e => setName(e.target.value)}
            />
          </Form.Item>
          <Form.Item label='Categoria'>
            <Select
              showSearch
              value={category}
              placeholder='Escoge una categoria'
              onChange={value => setCategory(value)}
              style={{ width: "100%" }}
              disabled={categories.length === 0}
            >
              {categories.map(category => (
                <Option key={category.id} value={category.name}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Cantidade disponible'>
            <InputNumber
              value={units}
              onChange={value => setUnits(value)}
              placeholder={unitsType}
              style={{ width: 150 }}
              min={0}
            />
            <span style={{ marginLeft: 5 }}>
              <small>Tipo de unidades: </small>

              <Select
                disabled={
                  element.reservedDates && element.reservedDates.length > 0
                }
                onChange={value => setUnitsType(value)}
                value={unitsType}
                style={{ width: 100 }}
              >
                <Option value='unds'>unds</Option>
                <Option value='mts'>mts</Option>
                <Option value='cajas'>cajas</Option>
              </Select>
            </span>
          </Form.Item>
          <Form.Item label='Cantidad minima de aviso'>
            <small>Activar </small>
            <Switch
              checked={hasMinUnits}
              onChange={checked => setHasMinUnits(checked)}
            />
            <small> Cantidad minima: </small>
            <InputNumber
              onChange={value => setMinUnits(value)}
              placeholder='Unidades minimas'
              style={{ width: 160 }}
              min={0}
              value={minUnits}
              disabled={!hasMinUnits}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label='Medidas'>
            <small>¿Agregar dimensiones?</small>{" "}
            <Switch
              checked={isPhysical}
              onChange={checked => setIsPhysical(checked)}
            />
            <small> Unidades: </small>
            <Select
              onChange={value => setMeasureUnits(value)}
              value={measureUnits}
              style={{ width: 100 }}
              disabled={!isPhysical}
            >
              <Option value='cms'>cms</Option>
              <Option value='mts'>mts</Option>
            </Select>
          </Form.Item>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label='Alto'>
                <InputNumber
                  value={height}
                  onChange={value => setHeight(value)}
                  placeholder={measureUnits}
                  style={{ width: "100%" }}
                  min={0}
                  disabled={!isPhysical}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Ancho'>
                <InputNumber
                  value={width}
                  onChange={value => setWidth(value)}
                  placeholder={measureUnits}
                  style={{ width: "100%" }}
                  min={0}
                  disabled={!isPhysical}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Profundo'>
                <InputNumber
                  value={long}
                  onChange={value => setLong(value)}
                  placeholder={measureUnits}
                  style={{ width: "100%" }}
                  min={0}
                  disabled={!isPhysical}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Diametro'>
                <InputNumber
                  value={diameter}
                  onChange={value => setDiameter(value)}
                  placeholder={measureUnits}
                  style={{ width: "100%" }}
                  min={0}
                  disabled={!isPhysical}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Form.Item label='Fecha de vencimiento'>
            <small>Activar </small>
            <Switch
              checked={hasExpirationDate}
              onChange={checked => setHasExpirationDate(checked)}
            />{" "}
            <DatePicker
              disabled={!hasExpirationDate}
              format={dateFormat}
              onChange={date => setExpirationDate(date)}
              value={expirationDate}
              locale={locale}
            />
          </Form.Item>
          <Form.Item label='Observaciones'>
            <TextArea
              value={observations}
              onChange={e => setObservations(e.target.value)}
              placeholder='Agrega una breve descripcion de no mas de 140 caracteres, como un Tweet'
              autosize={{ minRows: 4, maxRows: 10 }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type='primary' htmlType='submit' loading={loading}>
          Actualizar elemento
        </Button>
        <div>
          <small>Luego de que lo crees, puedes agregarle imagenes</small>
        </div>
      </Form.Item>
    </Form>
  );
};

export default EditInventoryElementForm;
