import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Icon, Divider, Tag, Select, Button, Modal, message } from "antd";
import OutputProfileElements from "./OutputProfileElements";
import EditOutputForm from "./EditOutputForm";
import OutputProfileAditionalServices from "./OutputProfileAditionalServices";
import OutputProfileExtraElements from "./OutputProfileExtraElements";
import OutputProfileGallery from "./OutputProfileGallery";
import { db, fieldValue } from "../../firebase.config";
import { getDateValues } from "../../utils/formatTimestamp";
const { Option } = Select;
const { confirm } = Modal;

const getOutputState = output => {
  if (output.outputState === "approved") {
    if (output.startDate > Date.now()) return "Activa";
    if (output.startDate < Date.now()) return "En curso";
  } else if (output.outputState === "pending") {
    return "Pendiente";
  } else if (output.outputState === "cancel") {
    return "Cancelada";
  } else if (output.outputState === "finish") return "Efectiva";
  else return output.outputState;
};

const OutputProfile = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [changeStateLoading, setChangeStateLoading] = useState(false);
  const [currentOutput, setCurrentOutput] = useState({});
  const [target, setTarget] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [targetsList, setTargetList] = useState([]);
  const [stateText, setStateText] = useState(currentOutput.outputState);
  const startDate = getDateValues(currentOutput.startDate);
  const finishDate = getDateValues(currentOutput.finishDate);
  const mountingDate = getDateValues(currentOutput.mountingDate);
  const unmountingDate = getDateValues(currentOutput.unmountingDate);
  const createdAt = getDateValues(currentOutput.createdAt);

  const stateColor = {
    Activa: "green",
    Efectiva: "geekblue",
    Pendiente: "orange",
    Cancelada: "red"
  };

  useEffect(() => {
    if (currentOutput.id) {
      setStateText(getOutputState(currentOutput));
    }
  }, [currentOutput]);

  useEffect(() => {
    const outputRef = db
      .collection("brands")
      .doc(match.params.brandId)
      .collection("outputs")
      .doc(match.params.outputId);
    const unsubscribeToElement = outputRef.onSnapshot(doc => {
      setLoading(true);
      if (doc.exists) {
        setCurrentOutput({
          id: doc.id,
          ...doc.data()
        });
      }
      setLoading(false);
    });
    return () => unsubscribeToElement();
  }, [match.params]);

  useEffect(() => {
    const fetchGoals = async type => {
      console.log("fetch goals");
      try {
        const typesRef = db
          .collection("information")
          .doc("outputTypesAndGoals");
        const typesDoc = await typesRef.get();
        const typesData = typesDoc.data();
        if (typesData.types) {
          const targetsIndex = typesData.types.findIndex(
            target => target.type === type
          );
          setTargetList(typesData.types[targetsIndex].goals);
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    if (currentOutput.type) fetchGoals(currentOutput.type);
  }, [currentOutput.type]);

  const changeOutputState = async () => {
    if (!target)
      return message.error(
        "Debes escoger un objetivo para poder aprobar la salida"
      );
    setChangeStateLoading(true);
    const outputRef = db
      .collection("brands")
      .doc(currentOutput.brandId)
      .collection("outputs")
      .doc(currentOutput.id);

    try {
      await outputRef.update({
        target: target || "",
        outputState: "approved"
      });
      message.success("Excelente, se ha aprobado la salida");
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos aprobar la salida");
    }
    setChangeStateLoading(false);
  };

  const cancelOutput = async () => {
    const outputRef = db
      .collection("brands")
      .doc(currentOutput.brandId)
      .collection("outputs")
      .doc(currentOutput.id);
    try {
      const batch = db.batch();
      currentOutput.elements.forEach(element => {
        const elementRef = db
          .collection("brands")
          .doc(element.brandId)
          .collection("series")
          .doc(element.serieId)
          .collection("elements")
          .doc(element.id);
        batch.update(elementRef, {
          reservedDates: fieldValue.arrayRemove({
            outputId: currentOutput.id,
            finishDate: currentOutput.finishDate,
            selectedUnits: element.selectedUnits,
            startDate: currentOutput.startDate
          })
        });
      });
      currentOutput.inventoryElements.forEach(element => {
        const inventoryElementRef = db
          .collection("brands")
          .doc(element.brandId)
          .collection("inventory")
          .doc(element.id);
        batch.update(inventoryElementRef, {
          reservedDates: fieldValue.arrayRemove({
            outputId: currentOutput.id,
            finishDate: currentOutput.finishDate,
            selectedUnits: element.selectedUnits,
            startDate: currentOutput.startDate
          })
        });
      });
      currentOutput.complements.forEach(complement => {
        const complementRef = db.collection("complements").doc(complement.id);
        batch.update(complementRef, {
          reservedDates: fieldValue.arrayRemove({
            outputId: currentOutput.id,
            finishDate: currentOutput.finishDate,
            selectedUnits: complement.selectedUnits,
            startDate: currentOutput.startDate
          })
        });
      });

      batch.update(outputRef, { outputState: "cancel" });
      await batch.commit();
      message.success(`Se ha cancelado la salida ${currentOutput.name}`);
    } catch (e) {
      message.success("No hemos podido cancelar la salida");
      console.log(e.message);
    }
  };

  function showDeleteConfirm() {
    confirm({
      title: "¿Estas seguro que quieres cancelar esta salida?",
      content:
        "Si cancelas esta salida, le llegará una notificacion al usuario y se liberará el inventario. Esta accion no puede deshacerse",
      okText: "Dale duro",
      okType: "danger",
      cancelText: "No, me arrepentí",
      async onOk() {
        await cancelOutput();
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  }

  return (
    <div className="pageContainer">
      {loading ? (
        <div style={{ display: "flex" }}>
          <Icon style={{ margin: "auto", fontSize: 80 }} type="loading" />
        </div>
      ) : (
        <>
          {!currentOutput.id && (
            <div style={{ margin: "auto" }}>
              <h2>
                No encontramos la salida <Icon type="frown" />
              </h2>
            </div>
          )}
          <div className="outputProfileContainer">
            {(currentOutput.outputState === "pending" ||
              currentOutput.outputState === "approved") && (
              <Icon
                type="edit"
                style={{ fontSize: 14 }}
                onClick={() => setEditMode(true)}
              />
            )}

            <h1 style={{ fontSize: 44 }}>{currentOutput.name}</h1>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 10,
                  margin: 10
                }}
              >
                <div style={{ padding: 5, textAlign: "center" }}>
                  <h2 style={{ fontSize: 44 }}>{startDate && startDate.day}</h2>
                  <p>{startDate && startDate.monthName}</p>
                  <p>{startDate && startDate.year}</p>
                </div>
                <div style={{ margin: 10 }}>
                  <Icon type="minus" />
                </div>
                <div style={{ padding: 5, textAlign: "center" }}>
                  <h2 style={{ fontSize: 44 }}>
                    {finishDate && finishDate.day}
                  </h2>
                  <p>{finishDate && finishDate.monthName}</p>
                  <p>{finishDate && finishDate.year}</p>
                </div>
              </div>
              <div style={{ padding: 10, margin: 10 }}>
                <p>
                  <strong>Fecha de montaje:</strong>{" "}
                  {mountingDate && mountingDate.day}{" "}
                  {mountingDate && mountingDate.monthName}{" "}
                  {mountingDate && mountingDate.year}{" "}
                </p>
                <p>
                  <strong>Fecha de desmontaje:</strong>{" "}
                  {unmountingDate && unmountingDate.day}{" "}
                  {unmountingDate && unmountingDate.monthName}{" "}
                  {unmountingDate && unmountingDate.year}{" "}
                </p>
                <p>
                  <strong>Tipo de salida: </strong> {currentOutput.type}
                </p>
                <p>
                  <strong>Lugar: </strong> {currentOutput.place}
                </p>
                <p>
                  <strong>Ciudad: </strong> {currentOutput.city}
                </p>
                <p>
                  <strong>Canal: </strong> {currentOutput.channel}
                </p>
              </div>

              <div style={{ padding: 10, margin: 10 }}>
                <p>
                  <strong>Creada por:</strong> {currentOutput.userName}
                </p>
                <p>
                  <strong>Correo contacto:</strong> {currentOutput.userEmail}
                </p>
                <p>
                  <strong>Creada en:</strong> {createdAt && createdAt.day}{" "}
                  {createdAt && createdAt.monthName}{" "}
                  {createdAt && createdAt.year}{" "}
                </p>
                <div>
                  <strong>Marca:</strong> <Tag>{currentOutput.brand}</Tag>
                </div>
                <div>
                  <strong>Estado:</strong>{" "}
                  <Tag
                    color={
                      stateText === "En curso"
                        ? "purple"
                        : stateColor[stateText]
                    }
                  >
                    {stateText}
                  </Tag>
                </div>
              </div>
              <div style={{ padding: 10, margin: 10 }}>
                <h3>Observaciones:</h3>
                <p>{currentOutput.observations}</p>
              </div>
            </div>
            <Divider />
            <div>
              <h2>Informacion complementaria:</h2>
              <p>
                Segun las caracteristicas de la salida, este es el objetivo que
                cumple para la marca:
              </p>
              <div style={{ marginTop: 20 }}>
                {currentOutput.target && (
                  <p>
                    <strong>Objetivo de la salida: </strong>{" "}
                    {currentOutput.target}
                  </p>
                )}
                {currentOutput.outputState === "pending" && (
                  <div style={{ display: "flex" }}>
                    <Select
                      value={target}
                      style={{ minWidth: 200 }}
                      onChange={value => setTarget(value)}
                      placeholder="Escoje un objetivo"
                    >
                      {targetsList.map(target => (
                        <Option key={target} value={target}>
                          {target}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              {currentOutput.outputState === "pending" && (
                <Button
                  style={{ margin: 5 }}
                  loading={changeStateLoading}
                  type="primary"
                  onClick={() => changeOutputState()}
                >
                  <Icon type="check" /> Aprobar salida
                </Button>
              )}
              {(currentOutput.outputState === "pending" ||
                currentOutput.outputState === "approved") && (
                <Button
                  ghost
                  style={{ margin: 5 }}
                  type="danger"
                  onClick={() => showDeleteConfirm()}
                >
                  Cancelar salida
                </Button>
              )}
            </div>
          </div>
          <div>
            <OutputProfileAditionalServices output={currentOutput} />
          </div>
          <div>
            <OutputProfileElements output={currentOutput} />
          </div>
          <div>
            <OutputProfileExtraElements output={currentOutput} />
          </div>
          <div>
            <OutputProfileGallery output={currentOutput} />
          </div>
        </>
      )}
      <Modal
        title={`Editar informacion general de la salida ${currentOutput.name}`}
        visible={editMode}
        onCancel={() => setEditMode(false)}
        footer={null}
        centered
        destroyOnClose
        width="70%"
      >
        <div>
          <EditOutputForm output={currentOutput} setEditMode={setEditMode} />
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(OutputProfile);
