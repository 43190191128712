import React, { useState, useEffect } from 'react';
import { db } from '../../firebase.config';
import { Icon, Switch, Tag } from 'antd';
import ComplementCard from './ComplementCard';
import ComplementsTable from './ComplementsTable';
import { useStoreState, useStoreActions } from 'easy-peasy';

const ComplementsElements = () => {
  const [complementsList, setCompelementsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableView, setTableView] = useState(false);
  const [currentQuery, setCurrentQuery] = useState('');
  const { fetchCategories } = useStoreActions(actions => actions.complements);
  const { categories } = useStoreState(state => state.complements);

  const fetchComplements = async category => {
    setLoading(true);
    let complementsRef;
    if (category) {
      complementsRef = db
        .collection('complements')
        .where('category', '==', category)
        .orderBy('createdAt', 'desc');
    } else {
      complementsRef = db
        .collection('complements')
        .orderBy('createdAt', 'desc');
    }

    try {
      const snapshot = await complementsRef.get();
      const complements = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLoading(false);
      setCompelementsList(complements);
    } catch (e) {
      console.log(e.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComplements(currentQuery);
  }, [currentQuery]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <div className='pageContainer'>
      <h1>Elementos de Complementos (Plus)</h1>

      <span>
        <Icon type='table' /> Ver como tabla{' '}
      </span>

      <Switch checked={tableView} onChange={checked => setTableView(checked)} />
      <div style={{ marginTop: 10 }}>
        <small>Categorias: </small>{' '}
        <Tag
          onClick={() => setCurrentQuery('')}
          color={currentQuery === '' ? '#222' : null}>
          Todas
        </Tag>
        {categories &&
          categories.map(category => (
            <Tag
              color={currentQuery === category.name ? '#222' : null}
              onClick={() => setCurrentQuery(category.name)}
              key={category.id}>
              {category.name} ({category.count})
            </Tag>
          ))}
      </div>
      <div className='complementsList'>
        {loading ? (
          <Icon style={{ margin: 'auto', fontSize: 80 }} type='loading' />
        ) : (
          <>
            {complementsList.length === 0 && (
              <div style={{ margin: 'auto' }}>
                <h2>
                  No encontramos elementos <Icon type='frown' />
                </h2>
              </div>
            )}
            {tableView ? (
              <ComplementsTable complements={complementsList} />
            ) : (
              <>
                {complementsList &&
                  complementsList.map(complement => (
                    <ComplementCard
                      key={complement.id}
                      complement={complement}
                    />
                  ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ComplementsElements;
