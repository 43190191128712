import React, { useState, useEffect } from "react";
import { Icon } from "antd";
import { db } from "../../firebase.config";
import InventoryCard from "./InventoryCard";
import "./inventories.css";
const Inventories = () => {
  const [loading, setLoading] = useState(true);
  const [brandsLists, setBrandsList] = useState([]);

  useEffect(() => {
    const unsuscribeToBrands = db
      .collection("brands")
      .limit(50)
      .onSnapshot(querySnapshot => {
        let brandsFromSnapshot = [];
        querySnapshot.forEach(doc => {
          brandsFromSnapshot.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setLoading(false);
        setBrandsList(brandsFromSnapshot);
      });
    return () => unsuscribeToBrands();
  }, []);
  return (
    <div className='pageContainer'>
      <h1>Inventarios</h1>
      <div className='inventoriesList'>
        {loading ? (
          <Icon style={{ margin: "auto", fontSize: 80 }} type='loading' />
        ) : (
          <>
            {brandsLists.length === 0 && (
              <div style={{ margin: "auto" }}>
                <h2>
                  No encontramos inventarios <Icon type='frown' />
                </h2>
              </div>
            )}
            {brandsLists.map(brand => (
              <InventoryCard brand={brand} key={brand.id} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Inventories;
