import React, { useState } from "react";
import { Button, message, Modal } from "antd";
import { withRouter } from "react-router-dom";
import { functions, db } from "../../firebase.config";
const { confirm } = Modal;
const DeleteSerie = ({ serie, history }) => {
  const [loading, setLoading] = useState(false);

  const confirmDelete = () => {
    confirm({
      title: "¿Seguro que deseas borrar esta serie?",
      content:
        "Si la borras ya nadie podra usarla, aun asi, quedara registrado en las salidas que lo usarion",
      okText: "Si, con toda",
      okType: "danger",
      cancelText: "No gracias",
      async onOk() {
        await deleteSerie(`/brands/${serie.brandId}/series/${serie.id}`);
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  const deleteSerie = async path => {
    setLoading(true);
    const deleteFn = functions.httpsCallable("recursiveDelete");
    const elementsRef = db
      .collection("brands")
      .doc(serie.brandId)
      .collection("series")
      .doc(serie.id)
      .collection("elements");
    try {
      const elementsDocs = await elementsRef.get();
      const elementsData = elementsDocs.docs.map(element => element.data());
      let reservedDatesCount = 0;
      elementsData.forEach(ele => {
        if (ele.reservedDates) {
          reservedDatesCount += ele.reservedDates.length;
        }
      });
      if (reservedDatesCount === 0) {
        const response = await deleteFn({ path });
        if (response.data.status === "success") {
          history.push("/series");
        } else {
          message.error("Ocurrió un inconveniente al borrar la serie");
        }
        console.log(response);
      } else {
        message.error(
          "Elementos de esta serie estan inlcuidos en salidas sin completar"
        );
        setLoading(false);
      }
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos borar la serie");
      setLoading(false);
    }
  };
  return (
    <div>
      <Button ghost type="danger" onClick={confirmDelete} loading={loading}>
        Borrar serie
      </Button>
    </div>
  );
};

export default withRouter(DeleteSerie);
