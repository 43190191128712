import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  InputNumber,
  Tag,
  Select,
  Divider,
  message
} from "antd";
import { withRouter } from "react-router-dom";
import { db, fieldValue } from "../../firebase.config";
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const CreateSerieElement = ({ match, history }) => {
  const [currentSerie, setCurrentSerie] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState("Nombre del elemento");
  const [units, setUnits] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [long, setLong] = useState(0);
  const [diameter, setDiameter] = useState(0);
  const [observations, setObservations] = useState("");
  const [measureUnits, setMeasureUnits] = useState("mts");

  const handleSubmit = async e => {
    e.preventDefault();
    if (!name) return message.error("El nombre es obligatorio");
    if (!units)
      return message.error("La cantidad minima es obligatoria es obligatorio");
    if (!measureUnits)
      return message.error("Las unidades de medida son obligatorias");
    setLoading(true);
    const elementRef = db
      .collection("brands")
      .doc(currentSerie.brandId)
      .collection("series")
      .doc(currentSerie.id)
      .collection("elements")
      .doc();
    const serieRef = db
      .collection("brands")
      .doc(currentSerie.brandId)
      .collection("series")
      .doc(currentSerie.id);
    const batch = db.batch();
    try {
      batch.update(serieRef, { elementsCount: fieldValue.increment(1) });
      batch.set(elementRef, {
        name,
        measureUnits,
        height,
        width,
        long,
        diameter,
        units,
        observations,
        brandName: currentSerie.brandName,
        brandId: currentSerie.brandId,
        brandColor: currentSerie.brandColor,
        serieName: currentSerie.name,
        serieId: currentSerie.id,
        createdAt: Date.now(),
        mainImage: "",
        state: "Bueno",
        isPublic: false,
        reservedDates: []
      });
      await batch.commit();
      history.push(
        `/serie/elemento/${currentSerie.brandId}/${currentSerie.id}/${elementRef.id}`
      );
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos crear el elemento");
    }
  };

  useEffect(() => {
    const complementRef = db
      .collection("brands")
      .doc(match.params.brandId)
      .collection("series")
      .doc(match.params.serieId);
    const unsubscribeToSerie = complementRef.onSnapshot(doc => {
      if (doc.exists) setDisabled(false);
      setCurrentSerie({
        id: doc.id,
        ...doc.data()
      });
    });
    return () => unsubscribeToSerie();
  }, [match.params]);
  return (
    <div className='pageContainer'>
      <div className='serieProfileContainer'>
        <h2>
          Crear elemento para la serie <strong>{currentSerie.name}</strong>
        </h2>
        <small>Marca: </small>
        <Tag color={currentSerie.brandColor}>{currentSerie.brandName}</Tag>
      </div>
      <Divider />
      <Title>{name}</Title>
      <Form onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Nombre'>
              <Input
                placeholder='Nombre del elemento'
                onChange={e => setName(e.target.value)}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Cantidades disponibles'>
              <InputNumber
                onChange={value => setUnits(value)}
                placeholder='Unidades'
                style={{ width: "100%" }}
                min={0}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Medidas'>
              <small>Unidades: </small>
              <Select
                onChange={value => setMeasureUnits(value)}
                value={measureUnits}
                style={{ width: 100 }}
                disabled={disabled}
              >
                <Option value='cms'>cms</Option>
                <Option value='mts'>mts</Option>
              </Select>
            </Form.Item>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label='Alto'>
                  <InputNumber
                    onChange={value => setHeight(value)}
                    placeholder={measureUnits}
                    style={{ width: "100%" }}
                    min={0}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='Ancho'>
                  <InputNumber
                    onChange={value => setWidth(value)}
                    placeholder={measureUnits}
                    style={{ width: "100%" }}
                    min={0}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='Profundo'>
                  <InputNumber
                    onChange={value => setLong(value)}
                    placeholder={measureUnits}
                    style={{ width: "100%" }}
                    min={0}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='Diametro'>
                  <InputNumber
                    onChange={value => setDiameter(value)}
                    placeholder={measureUnits}
                    style={{ width: "100%" }}
                    min={0}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Form.Item label='Observaciones'>
              <TextArea
                value={observations}
                onChange={e => setObservations(e.target.value)}
                placeholder='Agrega una breve descripcion de no mas de 140 caracteres, como un Tweet'
                autosize={{ minRows: 4, maxRows: 10 }}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            disabled={disabled}
            type='primary'
            htmlType='submit'
            loading={loading}
          >
            Crear elemento
          </Button>
          <div>
            <small>Luego de que lo crees, puedes agregarle imagenes</small>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default withRouter(CreateSerieElement);
