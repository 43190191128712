import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { StoreProvider } from "easy-peasy";
import { createStore } from "easy-peasy";
import { auth, db, functions, storage, fieldValue } from "./firebase.config";
import userModel from "./store/userModel";
import usersModel from "./store/usersModel";
import companiesModel from "./store/companiesModel";
import brandsModel from "./store/brandsModel";
import complementsModel from "./store/complementsModel";
import notificationsModel from "./store/notificationsModel";
import "./index.css";
import App from "./components/app/App";
import * as serviceWorker from "./serviceWorker";

//Create redux store with all necesary info about user
const store = createStore(
  {
    user: userModel,
    users: usersModel,
    companies: companiesModel,
    brands: brandsModel,
    complements: complementsModel,
    notifications: notificationsModel
  },
  {
    injections: {
      auth,
      db,
      functions,
      fieldValue,
      storage
    }
  }
);

//Depending on user info, render an app with it
const renderApp = (isAuthenticated, isAdmin, documentId) => {
  ReactDOM.render(
    <StoreProvider store={store}>
      <Router>
        <App
          auth={isAuthenticated}
          admin={isAdmin}
          documentId={documentId || null}
        />
      </Router>
    </StoreProvider>,
    document.getElementById("root")
  );
};

//check last login of user. If is not recent, ask for login again.
const checkLastLogin = async lastLogin => {
  const today = new Date();
  const lastLoginDate = new Date(lastLogin);
  console.log("Should re-login ", today - lastLoginDate > 3600000);
  if (today - lastLoginDate > 3600000) return await auth().signOut();
};

auth().onAuthStateChanged(async user => {
  if (user) {
    const idTokenResult = await user.getIdTokenResult();
    const isAdmin = idTokenResult.claims.admin;
    if (!!isAdmin) {
      const lastLogin = Date.parse(user.metadata.lastSignInTime);
      const documentId = idTokenResult.claims.documentId;
      console.log("last login", user.metadata.lastSignInTime);
      checkLastLogin(lastLogin);
      renderApp(true, true, documentId); //Is authenticated and is admin, can view app
    } else {
      renderApp(true, false); //Is authenticated but not an admin, can't view app
    }
  } else {
    renderApp(false, false); // Is not authenticated
  }
});
serviceWorker.unregister();
