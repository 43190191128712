import React, { useState } from "react";
import { Button } from "antd";
import { storage } from "../../firebase.config";

const DeleteBrandLogo = ({
  path,
  setDeleteState,
  isPreview,
  changePreview
}) => {
  const [loading, setLoading] = useState(false);
  const deleteLogo = async () => {
    setLoading(true);
    const storageRef = storage.ref();
    const fileRef = storageRef.child(path);
    try {
      await fileRef.delete();
      if (isPreview) await changePreview(null);
      setDeleteState({
        displayName: "No hay",
        name: "",
        creationDate: "No hay",
        size: "No hay",
        downloadUrl: "",
        fullPath: ""
      });
    } catch (e) {
      console.log(e.message);
    }
    setLoading(false);
  };
  return (
    <Button
      loading={loading}
      type="danger"
      size="small"
      disabled={!path}
      onClick={() => deleteLogo()}
    >
      Borrar
    </Button>
  );
};

export default DeleteBrandLogo;
