import React from "react";
import { Alert, Button } from "antd";
import { useStoreActions } from "easy-peasy";
const LogOutForm = () => {
  const logOutUser = useStoreActions(actions => actions.user.logOutUser);

  return (
    <div>
      <Alert
        message="¡Upps!"
        description="Estas logeado como un usuario no autorizado, cierra sesion y vuelve a ingresar como un admin"
        type="warning"
        showIcon
        style={{ marginBottom: 20 }}
      />
      <Button block onClick={() => logOutUser()} type="primary">
        Cerrar sesion
      </Button>
    </div>
  );
};

export default LogOutForm;
