import React from "react";
import { withRouter } from "react-router-dom";
import { useStoreActions } from "easy-peasy";
import { Icon, Button } from "antd";

const EditBrandSuccess = ({ brandId, brandName, history }) => {
  const { setCreateBrandState } = useStoreActions(actions => actions.brands);

  const resetBrandState = () => {
    setCreateBrandState({
      loading: false,
      error: "",
      success: false
    });

    history.push(`/marca/${brandId}`);
  };
  return (
    <div style={{ display: "flex" }}>
      <div style={{ margin: "auto", textAlign: "center" }}>
        <Icon
          style={{ fontSize: 60 }}
          theme="twoTone"
          twoToneColor="#52c41a"
          type="smile"
        />
        <h3 style={{ marginTop: 10 }}>
          ¡Bien hecho! Has creado la marca {brandName}
        </h3>
        <p>
          Haz click en el siguiente boton para ver el perfil y agregarle
          archivos
        </p>
        <Button type="primary" onClick={() => resetBrandState()}>
          Gracias, soy lo mejor
        </Button>
      </div>
    </div>
  );
};

export default withRouter(EditBrandSuccess);
