import React from "react";
import { Table, Button, Icon } from "antd";
import { Link } from "react-router-dom";
const ComplementsTable = ({ complements }) => {
  const data = complements.map(complement => ({
    key: complement.id,
    nombre: <h3>{complement.name}</h3>,
    categoria: complement.category,
    cantidad: complement.units,
    precio: `$ ${complement && complement.price}`.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    ),
    publicado: complement.isPublic ? (
      <Icon type='eye' />
    ) : (
      <Icon type='eye-invisible' />
    ),
    descripcion: complement.description,
    ver: (
      <Link to={`/complementos/elementos/${complement.id}`}>
        <Button type='primary' block>
          Ver elemento de +Plus
        </Button>
      </Link>
    )
  }));
  return (
    <Table
      columns={columns}
      dataSource={data}
      size='middle'
      style={{
        backgroundColor: "#fff",
        borderRadius: 10,
        marginTop: 20
      }}
      pagination={{ hideOnSinglePage: true }}
    />
  );
};

const columns = [
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre"
  },
  {
    title: "Categoria",
    dataIndex: "categoria",
    key: "categoria"
  },
  {
    title: "Cantidad disponible",
    dataIndex: "cantidad",
    key: "cantidad"
  },
  {
    title: "Precio",
    dataIndex: "precio",
    key: "precio"
  },

  {
    title: "Publicado",
    dataIndex: "publicado",
    key: "publicado"
  },

  {
    title: "Descripcion",
    dataIndex: "descripcion",
    key: "descripcion"
  },
  {
    title: "Ver elemento",
    dataIndex: "ver",
    key: "ver"
  }
];

export default ComplementsTable;
